<template>
  <div>
    <text-input
      v-model.trim="form.totp" autofocus :loading="loading"
      :placeholder="t('Code à 6 chiffres')"
      :input-label="t('Code d\'authentification')" hide-details="auto"
      :error-messages="errorMessage ? errorMessage : null"
      @input="submit"
      @keyup.enter="submit"
    />

    <div class="text-center mt-4 text-body-2">
      <span class="basics-80">
        {{ t('Utilisez un') }}
      </span>
      <router-link :to="{name: 'OTPStatic'}" class="font-demi-bold primary-main">
        {{ t('code de récupération') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';

export default {
  name: 'OtpVerification',
  mixins: [i18n],

  props: {
    form: {
      type: Object,
      required: true
    },

    loading: {
      type: Boolean,
      required: true
    },

    errorMessage: {
      type: String,
      required: false
    }
  },

  methods: {
    submit () {
      this.$emit('submit');
    }
  }
};
</script>
import TokenService from '@/services/tokens';

export default function authHeader() {
    let accessToken = TokenService.getAccessToken();

    if (accessToken) {
        return { Authorization: 'Bearer ' + accessToken};
    } else {
        return {};
    }
}


export function identityHeader() {
    let idToken = TokenService.getIdToken();

    if (idToken) {
        return { Authorization: 'Bearer ' + idToken};
    } else {
        return {};
    }
}

export function verifyHeader() {
    let verifyToken = TokenService.getVerifyToken();

    if (verifyToken) {
        return { Authorization: 'Bearer ' + verifyToken};
    } else {
        return {};
    }
}

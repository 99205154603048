<template>
  <div class="row m-0">
    <div class="col col-6 login-container h-100">
      <div class="d-flex flex-column h-100 justify-content-center">
        <div class="w-100 d-flex justify-center">
          <v-img :src="logo_codoc" contain max-width="200" max-height="60" min-width="65" min-height="12" />
        </div>
        <div
          v-for="(suite, i) in SUITE[currentSuite].layout"
          :key="i" class="mt-14 d-flex flex-column"
        >
          <v-img
            :src="require(`@/assets/img/logo/${suite.logo}`)"
            class="mb-4"
            :width="suite.logoWidth"
            contain
            alt="application logo"
          />
          <div class="d-flex flex-wrap align-center justify-content-between mb-4">
            <span class="basics-100 font-demi-bold text-h5 mr-2" v-html="suite.title" />
            <v-btn v-if="suite.module" class="secondary" @click="goToTransparencyModule">
              <icon name="external-link" outline color="primary-main" class="mr-1" />
              {{ t('Module patient') }}
            </v-btn>
          </div>
          <select-application
            v-for="(app, i) in suite.apps"
            :key="i"
            :app-type="app"
            class="mb-4"
          />
        </div>
      </div>
      <div class="row mt-2">
        <custom-footer />
      </div>
    </div>
    <div class="col col-6 p-6 bg-primary-pressed background h-100 layout">
      <div class="d-flex align-center">
        <icon name="dr-care" color="basics-10" solid />
        <span class="text-h5 basics-10 font-demi-bold">{{ t('Comment utiliser l’application codoc Research Hub ?') }}</span>
      </div>
      <v-img max-width="500" class="mx-auto" :src="img" />
      <span class="text-body-2 basics-70">{{ t(codocIntro) }}</span>
    </div>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import CustomFooter from '@/components/footer/CustomFooter';
import SelectApplication from '@/components/auth/SelectApplication';
import {MODE, SUITE} from "@/config";
import {mapGetters} from "vuex";

export default {
  name: 'LoginGeneral',
  components: { SelectApplication, CustomFooter },
  mixins: [i18n],

  data () {
    return {
      SUITE,
      img: require('@/assets/img/TimelineLogin.svg'),
      logo_codoc: require('@/assets/img/logo/Codoc.svg'),
      codocIntro: 'Le saviez-vous ? L’histoire de codoc a débuté sur le campus de l’Hôpital Necker-Enfants malades, au coeur de l’Institut Imagine, premier centre mondial de recherche sur les maladies génétiques. C’est après plus de 10 ans de recherche et développement menés par Nicolas Garcelon au sein de la plateforme de Data Science de l’IHU que la suite d’applications codoc a vu le jour. Pour en savoir plus, rendez-vous sur www.codoc.co.'
    };
  },

  computed: {
    ...mapGetters({
      customSettings: 'customApp/getCustomSettings'
    }),

    currentSuite() {
      return this.customSettings.login_page.toUpperCase();
    },

    isResearchMode() {
      return process.env.VUE_APP_MODE === MODE.RESEARCH;
    }
  },

  mounted () {
    if (this.isResearchMode) {
      this.$router.push({ name: 'Login' });
    }
  },


  methods: {
    goToTransparencyModule() {
      window.location.href = process.env.VUE_APP_TRANSPARENCY_REDIRECT_URL;
    }
  }
};
</script>

<style scoped>
.background {
  background-image: url('@/assets/img/BgAppChoice.jpg');
  background-size: cover;
}
.layout {
  display: grid;
  grid-template-rows: auto min-content auto;
  gap: 40px;
  align-items: center;
}
</style>

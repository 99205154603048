// initial state
import {Settings, Version} from "@/services/api";

const state = () => ({
    all: {
        document_types: [],
        document_origins: [],
        stay_entry_mode: [],
        stay_out_mode: [],
        version: 'dev'
    },
    loadingSettings: false
});

// getters
const getters = {
    types: state => state.all.document_types,
    sources: state => state.all.document_origins,
    versionAPI: state => state.all.version,
    entryMode: state => state.all.movement_entry_mode,
    outMode: state => state.all.movement_out_mode,
    stay_entry_mode: state => state.all.stay_entry_mode,
    stay_out_mode: state => state.all.stay_out_mode,
    loadingSettings: state => state.loadingSettings
};

// actions
const actions = {
    getSettings ({ commit }) {
        commit('setSettingsLoading', true);
        return Settings.list().then(response => {
            commit('setSettings', response.data);
        }).finally(() => {
            commit('setSettingsLoading', false);
        });
    },

    getVersion({ commit }) {
        Version.list().then(response => {
            commit('setVersion', response.data.version);
        });
    }
};

// mutations
const mutations = {
    setSettings (state, settings) {
        state.all = settings;
    },

    setOrigins (state, origins) {
        state.all.document_origins = origins;
    },

    setTypes (state, types) {
        state.all.document_types = types;
    },
    
    setVersion (state, version) {
        state.all.version = version;
    },

    setSettingsLoading (state, loading) {
        state.loadingSettings = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

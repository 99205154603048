import i18n from "./i18n";

export default {
    mixins: [i18n],
    methods: {
        /**
         * Return the gender of patient
         * @param gender character -> ['M' / 'F']
         * @returns {string}
         */
        genderWord(gender) {
            return gender === 'M' ? this.t('Homme') : gender === 'F' ? this.t('Femme') : this.t('Inconnu');
        },

        /**
         * Return the gender of patient
         * @param gender character -> ['M' / 'F']
         * @returns {string}
         */
        genderLetter(gender) {
            return gender === 'M' ? 'H' : gender === 'F' ? ('F') : this.t('O');
        }
    },
};

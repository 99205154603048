import {MODE} from '@/config';
import AuthCare from '@/services/auth/auth.care';
import AuthEDS from '@/services/auth/auth.eds';
import axios from "axios";
import TokenService from "@/services/tokens";
import authHeader from "@/services/auth/auth.header";

const API_URL = process.env.VUE_APP_DWH_AUTH_URL;

class AuthInterface {
    login(params = {}) {
        switch (process.env.VUE_APP_MODE) {
            case MODE.CARE:
                if (process.env.VUE_APP_2FA_ENABLED) {
                    return AuthEDS.authentication(params);
                }
                return AuthCare.login(params);
            case MODE.EDS:
            case MODE.GDDI:
            case MODE.RESEARCH:
                return AuthEDS.authentication(params);
        }
    }

    logout() {
        return axios.post(API_URL + '/logout/', {
            refresh: TokenService.getRefreshToken()
        }, {headers: authHeader()});
    }

    refresh() {
        return axios.post(API_URL + '/refresh/', {
            refresh: TokenService.getRefreshToken()
        });
    }
}

export default new AuthInterface();

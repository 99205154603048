<template>
  <div>
    <v-form ref="form">
      <text-input
        id="username" v-model.trim="form.username" name="username"
        placeholder="Entrez votre identifiant" input-label="Identifiant"
        :rules="requiredRule"
        hide-details="auto"
        @keyup.enter="submit"
      />

      <text-input
        id="password" v-model.trim="form.password" name="password"
        placeholder="Entrez votre mot de passe" input-label="Mot de passe"
        :rules="requiredRule"
        :error-messages="errorMessage ? errorMessage : null"
        :type="showConfirmPassword ? 'text' : 'password'"
        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
        class="mt-4"
        hide-details="auto"
        @click:append="showConfirmPassword = !showConfirmPassword"
        @keyup.enter="submit"
      />
    </v-form>
    <v-btn id="button-login" class="w-100 primary mt-6" :loading="loading" @click="submit">
      {{ t('Connexion') }}
    </v-btn>
    <div class="text-center mt-4 text-body-2">
      <span class="basics-80">
        {{ t('Des difficultés à vous connecter ?') }}
      </span>
      <a :href="'mailto: ' + customSettings.redirect_email" class="font-demi-bold primary-main">
        {{ t('Nous contacter') }}
      </a>
    </div>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import formRules from '@/mixins/rules/formRules';
import { mapGetters } from 'vuex';

export default {
  name: 'LoginForm',
  mixins: [i18n, formRules],

  props: {
    form: {
      type: Object,
      required: true
    },

    loading: {
      type: Boolean,
      required: true
    },

    errorMessage: {
      type: String,
      required: false
    }
  },

  data () {
    return {
      showConfirmPassword: false
    };
  },

  computed: {
    ...mapGetters({
      customSettings: 'customApp/getCustomSettings'
    })
  },

  methods: {
    submit () {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit('submit');
    }
  }
};
</script>
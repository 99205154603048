import { Thesauri } from "@/services/api";
import { DefaultParameterType } from "@/store/utils";


const state = () => ({
  thesauri: [],
  thesauriLabel: [],
  thesauriBiology: [],
  thesauriAct: [],
  thesauriDiagnostic: [],
  thesauriPrescription: [],
});

// getters
const getters = {
  thesauri: state => state.thesauri,
  thesauriLabel: state => state.thesauriLabel,
  thesauriDiagnostic: state => state.thesauriDiagnostic,
  /*thesauriBiology: state => state.thesauriBiology,
  thesauriAct: state => state.thesauriAct,
  thesauriPmsi: state => state.thesauriAct.concat(state.thesauriDiagnostic),
  thesauriPrescription: state => state.thesauriPrescription,*/
};

// mutations
const mutations = {
  setThesauri(state, thesauri) {
    state.thesauri = thesauri;
  },
  setThesauriLabel(state, thesauri) {
    state.thesauriLabel = thesauri;
  },
  setThesauriDiagnostic(state, thesauri) {
    state.thesauriDiagnostic = thesauri;
  },
  /*setThesauriBiology(state, thesauri) {
    state.thesauriBiology = thesauri;
  },
  setThesauriAct(state, thesauri) {
    state.thesauriAct = thesauri;
  },
  setThesauriPrescription(state, thesauri) {
    state.thesauriPrescription = thesauri;
  }*/
};

// actions
const actions = {
  getThesauri({ commit }) {
    Thesauri.list().then(response => {
      const thesauri = response.data.results.map(t => {
        return { value: t.type.name, text: t.type.name };
      });
      thesauri.unshift({ value: null, text: 'Tous' });
      commit('setThesauri', thesauri);
    });
  },
  getThesauriLabel ({ commit }, showAllFilter=true) {
    Thesauri.list().then(response => {
      const thesauriLabel = response.data.results.map(t => {return {value: t.code, text: t.type.name + ' - ' + t.label};});
      if(showAllFilter){
        thesauriLabel.unshift({value: null, text: 'Tous'});
      }
      commit('setThesauriLabel', thesauriLabel);
    });
  },
  getThesauriDiagnostic({ commit }) {
    Thesauri.list({ type__name: DefaultParameterType.DIAGNOSTIC.value }).then(response => {
      const thesauriDiagnostic = response.data.results;
      commit('setThesauriDiagnostic', thesauriDiagnostic);
    });
  },
  /*getThesauriBiology({ commit }) {
    Thesauri.list({ type__name: DefaultParameterType.BIOLOGY.value }).then(response => {
      const thesauri = response.data.results.map(t => {
        return { value: t.type.name, text: t.type.name };
      });
      commit('setThesauriBiology', thesauri);
    });
  },
  getThesauriAct({ commit }) {
    Thesauri.list({ type__name: DefaultParameterType.ACT.value }).then(response => {
      const thesauri = response.data.results.map(t => {
        return { value: t.type.name, text: t.type.name };
      });
      commit('setThesauriAct', thesauri);
    });
  },
  getThesauriDiagnostic({ commit }) {
    Thesauri.list({ type__name: DefaultParameterType.DIAGNOSTIC.value }).then(response => {
      const thesauri = response.data.results.map(t => {
        return { value: t.type.name, text: t.type.name };
      });
      commit('setThesauriDiagnostic', thesauri);
    });
  },
  getThesauriPrescription({ commit }) {
    Thesauri.list({ type__name: DefaultParameterType.PRESCRIPTION.value }).then(response => {
      const thesauri = response.data.results.map(t => {
        return { value: t.type.name, text: t.type.name };
      });
      commit('setThesauriPrescription', thesauri);
    });
  }*/
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

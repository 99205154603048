import i18n from "@/mixins/i18n";
import {TextValueEnum} from "@/utils/enums";


let t = i18n.methods.t;

class OperatorEnum extends TextValueEnum {
    constructor(value, text, op) {
        super(value, text);
        this.op = op;
    }
}

export function getEnumTextFromValue(enumeration, value) {
    let text = '';
    Object.values(enumeration).forEach(item => {
        if (item.value === value) {
            text = item.text;
        }
    });
    return text;
}


/**
 * List the logical operator used in the search.
 *
 * @type {Object.<string,OperatorEnum>}
 */
export const LogicalOperator = {
    NONE: new OperatorEnum({type: 'AndOperator'}, t("ET"), "AND"),
    AND: new OperatorEnum({type: 'AndOperator'}, t("ET"), "AND"),
    OR: new OperatorEnum({type: 'OrOperator'}, t("OU"), "OR"),
    AND_NOT: new OperatorEnum({type: 'AndNotOperator'}, t("ET NON"), "AND NOT"),
    AND_AMONG: new OperatorEnum({type: 'AmongOperator', minimum_should_match: 2}, t("ET PARMI"), "AND AMONG"),
    AND_TEMP_CONSTRAINT: new OperatorEnum({type: 'AndConstraintOperator'}, t("ET CONTRAINTE TEMPORELLE"), "AND TEMPORAL CONSTRAINT"),
};


/**
 * List the Criterion used in the search.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const CriterionType = {
    GROUP: new TextValueEnum("GroupCriterion", t("Groupe")),
    TEMPORAL_CONSTRAINT: new TextValueEnum("TemporalConstraintCriterion", t("Contrainte temporelle")),
    STRUCTURED: new TextValueEnum("StructuredCriterion", t("Critère Structuré")),
    EXPERT: new TextValueEnum("ExpertCriterion", t("Critère Textuel")),
    MOVEMENT: new TextValueEnum("MovementCriterion", t("Critère Mouvement")),
    STAY: new TextValueEnum("StayCriterion", t("Critère Séjour")),
    COHORT: new TextValueEnum("CohortCriterion", t("Critère Cohorte")),
    PATIENT: new TextValueEnum("PatientCriterion", t("Critère Patient")),
    SDEEXPERT: new TextValueEnum("PatientRecordTextCriterion", t("Critère Textuel"), "ExpertCriterion"),
    SDESTRUCTURED: new TextValueEnum("PatientRecordDataCriterion", t("Critère Structuré"), "StructuredCriterion")
};


/**
 *  List the allowed context in a search
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const TextContext = {
    PATIENT: new TextValueEnum("patient_text", t("Sur le patient")),
    FAMILY: new TextValueEnum("family_text", t("Sur la famille du patient")),
    TEXT: new TextValueEnum("text", t("Sur tous les contextes")),
};


/**
 * List the status of a Criterion.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const Status = {
    NONE: 1,
    SUCCESS: 2,
    PENDING: 3,
    ERROR: 4
};


/**
 *  List the choices when choosing the age range of a document inside filters.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const DateMagnitude = {
    YEARS: new TextValueEnum("years", t("Ans")),
    MONTHS: new TextValueEnum("months", t("Mois")),
};


/**
 *  List the choices when choosing the age range of a document inside filters.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const ConstraintType = {
    SIMULTANEITY_CONSTRAINT: new TextValueEnum("SimultaneityConstraint", "SimultaneityConstraint"),
    RELATIVE_DATE_CONSTRAINT: new TextValueEnum("RelativeDateConstraint", "RelativeDateConstraint"),
};

/**
 *  List the choices when choosing the age range of a document inside filters.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const PeriodLimitType = {
    MINIMUM: new TextValueEnum("minimum", "Minimum"),
    MAXIMUM: new TextValueEnum("maximum", "Maximum"),
};


/**
 *  List the choices when choosing the age range of a document inside filters.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const TimeConstraintType = {
    SAME_STAY: new TextValueEnum("same_stay", t("Durant le même séjour ")),
    SAME_DAY: new TextValueEnum("same_day", t("Le même jour")),
    ANY: new TextValueEnum("any", t("Autour")),
    BEFORE: new TextValueEnum("before", t("Avant")),
    AFTER: new TextValueEnum("after", t("Après")),
};

/**
 *  List the different value type a concept can accept.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const DataValueType = {
    NUMERIC: new TextValueEnum("numeric", t("Numérique"), 'NumericConcept'),
    TEXT: new TextValueEnum("text", t("Textuelle"), 'TextConcept'),
    LIST: new TextValueEnum("liste", t("Énumération"), 'ListConcept'),
    PARENT: new TextValueEnum("parent", t("Parent")),
    PRESENT: new TextValueEnum("present", t("Présente")),
};

/**
 *  List the different comparison allowed inside a concept using a numeric
 *  value.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const DataNumericComparison = {
    GREATER: new TextValueEnum("gt", ">"),
    GREATER_EQUAL: new TextValueEnum("gte", "≥"),
    LESSER: new TextValueEnum("lt", "<"),
    LESSER_EQUAL: new TextValueEnum("lte", "≤"),
    EQUAL: new TextValueEnum("eq", "="),
    NOT_EQUAL: new TextValueEnum("neq", "≠"),
};

/**
 *  List the different test allowed inside a concept using a numeric value.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const DataNumericTest = {
    OUTBOUND: new TextValueEnum("outbound", t("Hors borne")),
    GREATER: new TextValueEnum("above_upper_bound", t("> à la normale")),
    //TODO: TIMESGREATER: new TextValueEnum("times_above_upper_bound", t("... fois > à la normale")),
    LESSER: new TextValueEnum("below_lower_bound", t("< à la normale")),
    //TODO: TIMESLESSER: new TextValueEnum("times_below_upper_bound", t("... fois < à la normale")),
};


/**
 *  List the different movement types.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const MovementType = {
    CONSULTATION: new TextValueEnum("C", t("Consultation")),
    HDJ: new TextValueEnum("J", t("HDJ")),
    URGENCE: new TextValueEnum("U", t("Urgence")),
    HOSPITALISATION: new TextValueEnum("H", t("Hospitalisation")),
    AMBULATOIRE: new TextValueEnum("A", t("Ambulatoire")),
    SEANCE: new TextValueEnum("S", t("Séance")),
    AMBULATOIRE_MCO: new TextValueEnum("AM", t("Ambulatoire MCO")),
    AMBULATOIRE_PSY: new TextValueEnum("AP", t("Ambulatoire PSY")),
};


/**
 *  List the different cohort status.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const CohortStatus = {
    INCLUDE: new TextValueEnum(1, t("Inclus")),
    EXCLUDE: new TextValueEnum(0, t("Exclus")),
    DOUBT: new TextValueEnum(2, t("En doute")),
};

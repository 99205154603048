import axios from "axios";
import TokenService from "@/services/tokens";
import authHeader, {identityHeader, verifyHeader} from "@/services/auth/auth.header";

const API_URL = process.env.VUE_APP_DWH_AUTH_URL;

class TotpService {
    setup() {
        return axios.post(API_URL + '/totp/setup/', {}, {headers: identityHeader()});
    }
    verify(totp) {
        return axios.post(API_URL + '/totp/verify/', {'token': totp}, {headers: identityHeader()}).then(response => {
            TokenService.setVerifyToken(response.data.verify);
            return response;
        });
    }
    retrieve() {
        return axios.get(API_URL + '/totp/', {headers: authHeader()});
    }
    reset(id) {
        return axios.post(API_URL + '/totp/reset/', {'user_id': id}, {headers: authHeader()});
    }
}

class StaticTotpService {
    setup() {
        return axios.post(API_URL + '/totp/static/setup/', {}, {headers: verifyHeader()});
    }
    verify(totp) {
        return axios.post(API_URL + '/totp/static/verify/', {'token': totp}, {headers: identityHeader()}).then(response => {
            TokenService.setVerifyToken(response.data.verify);
            return response;
        });
    }
    retrieve() {
        return axios.get(API_URL + '/totp/static/', {headers: authHeader()});
    }
    reset() {
        return axios.post(API_URL + '/totp/static/reset/', {}, {headers: authHeader()});
    }
}


export const Totp = new TotpService();
export const StaticTotp = new StaticTotpService();

<template>
  <v-container fill-height class="align-content-center justify-content-center">
    <v-progress-circular
      indeterminate
      :size="50"
      :width="5"
      color="primary"
    />
  </v-container>
</template>

<script>
// import TokenService from '@/services/tokens';
// import getEnv from "@/utils/env";

export default {
  name: 'CallbackTokens',

  data() {
    return {
      accessToken: this.$route.query.access_token,
      refreshToken: this.$route.query.refresh_token
    };
  },

  mounted() {
    let tokens = {
      "access": this.accessToken,
      "refresh": this.refreshToken,
    };
    this.$store.commit('auth/setTokens', tokens);
    this.$store.dispatch('auth/initSession');
    this.$store.dispatch('user/getUser').then(
        () => this.$router.push({name: 'Home'}))
        .catch(() => {this.$router.push({name: 'LoginGeneral'});});
  }
};
</script>

import i18n from "@/mixins/i18n";
let t = i18n.methods.t;

export function getMovementsText (name) {
    switch (name) {
        case "C":
            return {value: name, text: t('Consultation')};
        case "J":
            return {value: name, text: t('HDJ')};
        case "H":
            return {value: name, text: t('Hospitalisation')};
        case "U":
            return {value: name, text: t('Urgence')};
        case "A":
            return {value: name, text: t('Ambulatoire')};
        case "S":
            return {value: name, text: t('Séance')};
        case "AM":
            return {value: name, text: t('Ambulatoire MCO')};
        case "AP":
            return {value: name, text: t('Ambulatoire PSY')};
        default:
            return {value: name, text: t('Type indéfini')};
    }
}
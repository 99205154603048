import { SDEQuestionAnswer } from "@/services/api";
import { createCRUDstore } from "@/store/crud";

const state = {};
const getters = {
  answer: (state) => (event_id) => {
    return state.objects.find((answer) => answer.event_id === event_id);
  },
  validated: (state) => {
    return state.objects.filter((answer) => answer.validated_date);
  },
};
const mutations = {};
const actions = {};

export default createCRUDstore(
  SDEQuestionAnswer,
  state,
  getters,
  mutations,
  actions
);

<template>
  <div>
    <dialog-box :dialog="dialog" :loading="loading" width="600px" :is-form-valid="!!file"
                icon="users" type="form" :title="t('Opposer des patients')"
                @close="dialog = false" @submit="submitFile()"
    >
      <template #default>
        <div>
          <div class="text-body-2 basics-80 font-demi-bold my-3">
            <span>{{ t("Chaque ligne du fichier importé doit avoir les colonnes “IPP”, “Opposition” et “Information” non-vides afin que le patient correspondant puisse être opposé.") }}</span>
            <custom-data-table
              :items="itemsGDDIExample"
              :headers="headersGDDIExample"
              class="my-5"
              hide-default-footer
            />
            <span>{{ t('L’extension de votre fichier doit être *.csv, *.xls, *.xlsx ou *.txt. Les colonnes doivent être séparées par des virgules.') }}</span>
          </div>
          <div class="mt-5">
            <span class="text-caption font-demi-bold basics-80">{{ t('Choisir un fichier') }}</span>
            <v-form ref="form">
              <v-card-actions class="pl-0">
                <v-file-input
                  v-model="file"
                  dense
                  accept=".csv,.txt,.xls,.xlsx"
                  :truncate-length="70"
                  outlined
                  :placeholder="t('Rechercher un fichier')"
                  prepend-icon=""
                  append-icon="$file"
                  :rules="[value => !!value || t('Champs requis')]"
                  :error="errorDialog"
                  @change="errorDialog = false"
                />
              </v-card-actions>
            </v-form>
          </div>
        </div>
      </template>
    </dialog-box>

    <dialog-box :dialog="opposedResults" :loading="loadingOpposed" width="1100px" :title="t('Opposer des patients')"
                :text-button="`${t('Opposer')} ${selected.length} ${selected.length > 1 ? t('patients') : t('patient')}`"
                type="form" :is-form-valid="!!selected.length" @close="close()" @submit="updateOpposition()"
    >
      <template #default>
        <div class="w-25 my-2">
          <text-input
            v-model="searchQuery"
            hide-details
            search-input
          />
        </div>
        <span v-if="doubleIppFlag.length > 0" class="warning-main my-2">{{ t('Certaines lignes du fichier importé ont plusieurs patients correspondants. Veuillez sélectionner les patients à importer parmi les patients correspondants.') }}</span>
        <div>
          <custom-data-table
            v-model="selected"
            height="max-content"
            class="w-100 my-2"
            :headers="headers"
            :search="searchQuery"
            :items="opposedPatients"
            :loading="loading"
            file-import
            item-key="id"
            show-select
            selectable-key="isSelectable"
            @toggle-select-all="selectAllToggle()"
          >
            <template #item.normalized_row.ipp="{ item }">
              <span>
                {{ item.normalized_row?.ipp ? item.normalized_row?.ipp : item.row?.ipp }}
              </span>
            </template>
            <template #item.object.last_name="{ item }">
              <span>
                {{ item.object?.patient.last_name | empty }}
              </span>
            </template>
            <template #item.object.first_name="{ item }">
              <span>
                {{ item.object?.patient.first_name | empty }}
              </span>
            </template>
            <template #item.object.birth_date="{ item }">
              <span>
                {{ item.object?.patient.birth_date | datetime }}
              </span>
            </template>
            <template #item.normalized_row.opposition="{ item }">
              <span v-if="item.normalized_row && item.normalized_row.opposition">
                {{ item.normalized_row.opposition ? t('Oui') : t('Non') }}
              </span>
              <span v-else-if="item.row && item.row.opposition">
                {{ item.row.opposition.toUpperCase() === "TRUE" ? t('Oui') : t('Non') }}
              </span>
            </template>
            <template #item.normalized_row.information="{ item }">
              <span v-if="item.normalized_row && item.normalized_row.information">
                {{ item.normalized_row.information ? t('Oui') : t('Non') }}
              </span>
              <span v-else-if="item.row && item.row.information">
                {{ item.row.information.toUpperCase() === "TRUE" ? t('Oui') : t('Non') }}
              </span>
            </template>
            <template #item.match="{ item }">
              <span v-if="!item.fieldset" :class="{'font-demi-bold basics-90': !item.fieldset}">{{ t("Patient non trouvé") }}</span>
            </template>
          </custom-data-table>
        </div>
      </template>
    </dialog-box>
  </div>
</template>

<script>
  import i18n from '@/mixins/i18n';
  import {Opposition} from "@/services/api";
  import {Opposition as OppositionStatus} from "@/enums/opposition";
  import {Information as InformationStatus} from "@/enums/opposition";
  import fileError from "@/mixins/fileError";
  import moment from "moment";
  import patientFileManagement from "@/mixins/patientFileManagement";

  export default {
    name: 'UpdatePatientsModal',
    mixins: [i18n, fileError, patientFileManagement],
    props: {
      dialogImport: Boolean,
    },

    data() {
      return {
        OppositionStatus,
        InformationStatus,
        file: null,
        errorDialog: false,
        dialog: false,
        opposedResults: false,
        loading: false,
        loadingOpposed: false,
        opposedPatients: [],
        selectedPatients: [],
        searchQuery: '',
        selected: [],
        allSelected: false,
        doubleIppFlag : [],

        headers: [
          {text: this.t('IPP'), value: 'normalized_row.ipp', is_visible: true,
            filter: (value, search, item) => {
              if (!this.searchQuery) return true;
              return (!!(item.normalized_row && item.normalized_row.ipp && item.normalized_row.ipp.toLowerCase().includes(this.searchQuery.toLowerCase())
              || item.row && item.row.ipp && item.row.ipp.toLowerCase().includes(this.searchQuery.toLowerCase())));
            }
          },
          {
            text: this.t('Nom'), align: 'start', value: 'object.last_name',
            filter: (value, search, item) => {
              if (!this.searchQuery) return true;
              return (!!(item.object && item.object.patient.last_name && item.object.patient.last_name.toLowerCase().includes(this.searchQuery.toLowerCase())));
            }
          },
          {
            text: this.t('Prénom'), align: 'start', value: 'object.first_name',
            filter: (value, search, item) => {
              if (!this.searchQuery) return true;
              return (!!(item.object && item.object.patient.first_name && item.object.patient.first_name.toLowerCase().includes(this.searchQuery.toLowerCase())));
            }
          },
          {
            text: this.t('Date de naissance'), value: 'object.birth_date',
            filter: (value, search, item) => {
              if (!this.searchQuery) return true;
              return (!!(item.object && item.object.patient.birth_date && moment(item.object.patient.birth_date).format('DD/MM/YYYY').includes(this.searchQuery.toLowerCase())));
            }
          },
          {text: this.t('Opposition'), value: 'normalized_row.opposition'},
          {text: this.t('Information'), value: 'normalized_row.information'},
          {text: this.t('Notes'), value: 'match', align: 'end'},
        ],

        headersGDDIExample : [
          {text: this.t('IPP'), value: 'ipp', sortable: false, align: 'start', width: '40%'},
          {text: this.t('Opposition'), value: 'opposition', sortable: false},
          {text: this.t('Information'), value: 'information', sortable: false, align: 'end' }
        ],

        itemsGDDIExample: [
          {ipp: 12556, opposition: "OUI", information: "OUI"},
          {ipp: 4557, opposition: "NON", information: "NON"}
        ]
      };
    },

    watch: {
      dialogImport() {
        this.dialog = this.dialogImport;
      },

      dialog(isVisible) {
        this.$emit('update:dialog-import', this.dialog);
        if (isVisible && this.$refs.form) {
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
        }
      },
    },

    methods: {
      submitFile() {
        if (this.file) {
          const formData = new FormData();
          formData.append('file', this.file);
          this.loading = true;
          Opposition.from_file(
              formData, {'fieldsets': "patient__ipphistory__ipp", 'fieldmapping': "patient__ipphistory__ipp:IPP,opposition:opposition_status,information:informed_status"}, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
            this.opposedPatients = response.data;
            this.selected = [];
            const unknownPatients = [];
            this.opposedPatients.forEach((patient, index) => {
              patient.id = index;
              patient.isSelectable = this.isSelectable(this.opposedPatients, index, unknownPatients);
              patient.doubleIpp = this.shouldSetDoubleIppFlag(this.opposedPatients, index);
              if (patient.doubleIpp) {
                this.doubleIppFlag.push(patient);
              }
              // Add selectable patient to selected list
              if (patient.isSelectable && patient.isSelectable !== undefined && !patient.doubleIpp) {
                this.selected.push(patient);
              }
            });
            // Fill tab with all patients
            this.opposedPatients = [...this.doubleIppFlag, ...this.selected, ...unknownPatients];
            this.opposedResults = true;
            this.dialog = false;
          }).catch((error) => {
            if (error.response.status === 400) {
              this.displayFileError(error);
            } else {
              this.$store.commit('snackbar/apiErrorSnackbar', error);
            }
          }).finally(() => {
            this.loading = false;
          });
        }
      },

      updateOpposition() {
        this.loadingOpposed = true;
        this.selected.forEach((patient) => {
          this.selectedPatients.push({
            'id': patient.object.id,
            'opposition_status': patient.normalized_row?.opposition ? this.OppositionStatus.OPPOSED.value : this.OppositionStatus.NOT_OPPOSED.value,
            'information_status': patient.normalized_row?.information ? this.InformationStatus.INFORMED.value : this.InformationStatus.NOT_INFORMED.value
          });
        });
        Opposition.bulk(this.selectedPatients).then(() => {
          this.$store.commit('snackbar/successSnackbar', {message: this.t("L'opposition du patient a bien été ajoutée.")});
        }).catch(error => {
          this.$store.commit('snackbar/apiErrorSnackbar', error);
        }).finally(() => {
          this.loadingOpposed = false;
          this.$emit('get-patients');
          this.close();
        });
      },

      selectAllToggle() {
        this.allSelected = !this.allSelected;
        if (this.allSelected) {
          let availableItems = this.opposedPatients.filter(item => item.isSelectable !== undefined);
          if (this.selected.length !== availableItems.length) {
            this.selected = availableItems;
          }
        } else {
          this.selected = [];
        }
      },

      close() {
        this.selected = [];
        this.selectedPatients = [];
        this.doubleIppFlag = [];
        this.opposedResults = false;
      }
    }
  };
</script>

<style>
  .patient-dialog {
    z-index: 9999 !important;
  }
</style>
<template>
  <v-container fill-height class="align-content-center justify-content-center">
    <v-progress-circular
      indeterminate
      :size="50"
      :width="5"
      color="primary"
    />
  </v-container>
</template>

<script>
import {MODE, SUITE} from "@/config";
import {mapGetters} from "vuex";

export default {
  name: 'Callback',

  computed: {
    ...mapGetters({
      user: 'user/me',
      customSettings: "customApp/getCustomSettings"
    }),
  },

 mounted() {
   let app_mode = process.env.VUE_APP_MODE;
   let app_id = app_mode === MODE.RESEARCH ? process.env.VUE_APP_IDENTIFIER : app_mode;
   this.$store.dispatch('auth/login', app_id).then(
       () => {
         return this.$store.dispatch('user/getUser').then(
             () => {
               if (!this.user.is_cgu_valid) {
                 this.$router.push({name: 'Cgu'});
               } else if (!this.user.persona) {
                 this.$router.push({ name: 'Persona' });
               } else {
                 this.$router.push({
                   name: app_mode === MODE.EDS && this.customSettings.login_page === SUITE.TRANSPARENCY_STANDALONE.name ?
                       'Workspaces' : 'Home'
                 });
               }
             });
       }
   ).catch(() => {this.$router.push({name: 'LoginGeneral'});});
 }
};
</script>
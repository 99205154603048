export default {
    methods: {
        // Function to check if the object is selectable
        isSelectable(patients, index, unknomn) {
            const patient = patients[index];
            if (patient.object !== null && patient.fieldset !== null) {
                return true;
            } else {
                unknomn.push(patient);
                return undefined;
            }
        },

        // Function to check if double IPP flag should be set
        shouldSetDoubleIppFlag(patients, index) {
            const prevPatient = patients[index - 1];
            const nextPatient = patients[index + 1];
            const patient = patients[index];
            if (patient.object !== null && patient.fieldset !== null) {
                if (!prevPatient && !nextPatient) {
                    return false;
                }
                if (prevPatient === undefined) {
                    return patient.row_index === nextPatient.row_index;
                } else if (nextPatient === undefined) {
                    return patient.row_index === prevPatient.row_index;
                } else if (patient.row_index === prevPatient.row_index || patient.row_index === nextPatient.row_index) {
                    return patient.row_index === prevPatient.row_index || patient.row_index === nextPatient.row_index;
                }
            }
        },
    }
};
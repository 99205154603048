<template>
  <div
    class="application-container p-3 br-8 d-flex align-items-center parent pr-4 clickable bg-basics-10"
    @click="redirect"
  >
    <div class="ml-2 d-flex flex-column max-content text-truncate">
      <div class="max-content">
        <p class="text-body-1 basics-80 mb-0 text-wrap">
          {{ appType.title }}
        </p>
      </div>
    </div>
    <div class="ml-auto">
      <icon name="arrow-right" outline class="arrow" />
    </div>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";

export default {
  name: "SelectApplication",
  mixins: [i18n],

  props: {
    appType: {
      type: Object,
      required: true,
    },
  },

  methods: {
    redirect() {
      window.location.href = this.appType.url;
    },
  },
};
</script>

<style scoped>
.application-container {
  border: 1px solid var(--basics-50);
  box-shadow: var(--Z1) !important;
}

.arrow {
  transition-duration: 300ms;
}

.parent:hover .arrow {
  transform: translateX(8px);
}
</style>
// initial state
import {Users} from "@/services/api";

function cachedPermissions() {
    let perms = localStorage.getItem('permissions');
    if (perms)
        return perms.split(',');
    return [];
}

const emptyUser = {
    first_name: '',
    last_name: '',
    is_cgu_valid: null,
    origins_permissions: [],
    permissions: cachedPermissions()
};

const state = () => ({
    me: emptyUser,
    all_users: []
});

const getters = {
    getPermissions: state => state.me.permissions,
    userId: state => state.me.id,
    me: state => state.me,
    users: state => state.all_users
};

// actions
const actions = {
    getUser ({ commit }) {
        return Users.me().then(response => {
            localStorage.setItem('permissions', response.data.permissions);
            commit('setUser', response.data);
            return response;
        });
    },
    getAllUsers({commit}) {
        return Users.list().then(response => {
            commit('setAllUsers', response.data.results);
        });
    },
    updateUser ({ state, dispatch }) {
        return Users.update(state.me.id, state.me).then(() => {
            return dispatch("getUser");
        });
    },
    updateProfilePassword ({ state, dispatch }) {
        const data = {
            "password": state.me.password,
            "old_password": state.me.old_password
        };
        return Users.update_profile_password(data).then(() => {
            return dispatch("getUser");
        });
    },
};

// mutations
const mutations = {
    setUser (state, user) {
        state.me = user;
    },

    setPassword(state, password) {
        state.me.password = password;
    },

    setOldPassword(state, old_password) {
        state.me.old_password = old_password;
    },

    signCgu(state) {
        state.me.cgu_date_signed = new Date().toISOString();
    },

    resetUser (state) {
        state.me = emptyUser;
    },

    setAllUsers(state, users) {
        state.all_users = users;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

// initial state
import {Units} from "@/services/api";

const state = () => ({
    all: [],
    loading: false
});

// getters
const getters = {
    loadingUnits: state => state.loading,
    all: state => {
        return state.all;
    }};

// actions
const actions = {
    getUnits ({ commit }, params={}) {
        commit('setLoading', true);
        Units.list(params).then(response => {
            commit('setUnits', response.data.results);
        }).finally(() => {
            commit('setLoading', false);
        });
    }
};

// mutations
const mutations = {
    setUnits (state, units) {
        state.all = units;
    },

    setLoading (state, loading) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

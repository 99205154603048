import {ref}  from "vue";
import {Concept} from "@/services/api";
import cloneDeep from "lodash/cloneDeep";
import {DataValueType} from "@/views/commons/search/utils/enums";
import i18n from '@/mixins/i18n';

const state = () => ({
    searchQuery: null,
    searchCohort: null,
    searchRequest: null,
    startSearch: false,
    structuredSearch: {},
    tabDisplay: false,
    tabConcepts: null
});

// mutations
const mutations = {
    setSearchQuery(state, query) {
        state.searchQuery = query;
    },

    setSearchCohort(state, cohort) {
        state.searchCohort = cohort;
    },

    setSearchRequest(state, request) {
        state.searchRequest = request;
    },

    setStartSearch(state, bool) {
        state.startSearch = bool;
    },

    addConcept(state, data) {
        if (data.related) {
            const relatedParentIndex = state.structuredSearch[data.id].related.value.findIndex(item => item.related_parent === data.related_parent);
            if (relatedParentIndex !== -1) {
                state.structuredSearch[data.id].related.value[relatedParentIndex].concepts.push(data.concept);
            } else {
                state.structuredSearch[data.id].related.value.push({ related_parent: data.related_parent, concepts: [data.concept], relation: data.relation });
            }
        } else {
            state.structuredSearch[data.id].normal.value.push(data.concept);
        }
    },

    removeConcept(state, data) {
        if (data.related) {
            state.structuredSearch[data.id].related.value[data.index].concepts.splice(data.concept_index, 1);
            if(state.structuredSearch[data.id].related.value[data.index].concepts.length === 0){
                state.structuredSearch[data.id].related.value.splice(data.index, 1);
            }
        } else {
            state.structuredSearch[data.id].normal.value.splice(data.index, 1);
        }
    },

    updateConcept(state, data) {
        state.structuredSearch[data.id].normal.value.splice(data.index, 1, data.concept);
    },

    addConceptsArray(state, data) {
        state.structuredSearch[data.id] = {
            normal: ref(data.fields),
            related: ref([]),
        };
      },
    removeConceptsArray(state, id) {
        delete state.structuredSearch[id];
    },

    resetConceptsArray(state, id) {
        if (state.structuredSearch[id]) {
            state.structuredSearch[id].normal.value = [];
            state.structuredSearch[id].related.value = [];
        }    
    },
    
    updateDisplay(state, bool) {
        state.tabDisplay = bool;
    },
    updateTabConcepts(state, concepts) {
        state.tabConcepts = concepts;
    }
};

// getters
const getters = {
    getSearchQuery: state => state.searchQuery,
    getSearchCohort: state => state.searchCohort,
    getSearchRequest: state => state.searchRequest,
    getStartSearch: state => state.startSearch,
    getConcepts: state => id => {
        return id && state.structuredSearch[id] ? state.structuredSearch[id].normal : state.structuredSearch;
    },
    getTabDisplay: state => state.tabDisplay,
    getTabConcepts: state => state.tabConcepts,
    getCriterionConceptsRelated: state => id => {
        return id ? state.structuredSearch[id].related : state.structuredSearch;
    }
};

// actions
const actions = {
    resetSearchData({commit}) {
        commit('setSearchQuery', null);
        commit('setStartSearch', false);
        commit('setSearchCohort', null);
    },

    getConceptHierarchy({commit}, concept) {
        commit('updateDisplay', true);
        Concept.hierarchy({concept: concept.id}).then(response => {
            if (response.data.results.length === 0) {
                commit('updateDisplay', false);
                commit("snackbar/errorSnackbar", {message: i18n.methods.t('Aucun concept associé à un dossier patient')}, { root: true });
            } else {
                commit('updateTabConcepts', response.data.results);
            }
        }).catch(error => {
            commit('updateDisplay', false);
            commit("snackbar/apiErrorSnackbar", error, { root: true });
        });
    },

    selection({dispatch, commit}, data) {
        let concepts = null;
        if (!Array.isArray(data.concept)) {
            concepts = [cloneDeep(data.concept)];
        } else {
            concepts = cloneDeep(data.concept);
        }
        if(data.relation){
            const params = {
              thesaurus_data1_id: data.concept.id,
              thesaurus_code1: data.relation.thesaurus_code1,
              thesaurus_code2: data.relation.thesaurus_code2,
              relation_type: data.relation.relation_type,
              limit: 200
            };
            Concept.related_to(params).then(response => {
              concepts = response.data.results;
            }).catch(error => {
              commit('snackbar/apiErrorSnackbar', error);
            }).finally(() => {
                dispatch('updateSelectedConcepts', { data, concepts });
            });
          } else {
            dispatch('updateSelectedConcepts', { data, concepts });
        }
    },

    updateSelectedConcepts({ state, commit, rootGetters }, data){
        const thesauriDiagnostic = rootGetters["thesauri/thesauriDiagnostic"];
        data.concepts.forEach(c => {
            switch (c.value_type) {
                case DataValueType.NUMERIC.value:
                    c.value = {}; break;
                case DataValueType.LIST.value:
                    if(thesauriDiagnostic.some(obj => obj.code === c.thesaurus_code)){
                        c.value = ["principal"];
                    } else {
                        c.value = []; 
                    }
                    break;
                case DataValueType.TEXT.value:
                    c.value = null; break;
            }
            if(data.data.relation){
                // We need to find parent and then concept index to remove completely
                const value_index = state.structuredSearch[data.data.id].related.value.findIndex(concept => concept.related_parent.id === data.data.concept.id);
                let i = -1;
                if(value_index !== -1){
                    i = state.structuredSearch[data.data.id].related.value[value_index].concepts.findIndex(concept => concept.id === c.id);
                }
                if (i === -1) {
                    commit('addConcept', {concept: c, id: data.data.id, related: true, related_parent: data.data.concept, relation: data.data.relation.label});
                    if (data.data.criterion) {
                        if (!data.data.criterion.fields.includes(c)) {
                            data.data.criterion.fields.push(c);
                        }
                    }
                } else {
                    const i = data.data.criterion.fields.findIndex(concept => concept.id === c.id);
                    if (data.data.criterion && i !== -1) {
                        commit('removeConcept',  {index: value_index, concept_index: i, id: data.data.id, related: true});
                        data.data.criterion.fields.splice(i, 1);
                    }
                }
            } else {
                const i = state.structuredSearch[data.data.id].normal.value.findIndex(field => field.id === c.id);
                if (i === -1) {
                    commit('addConcept', {concept: c, id: data.data.id});
                    if (data.data.criterion) {
                        if (!data.data.criterion.fields.includes(c)) {
                            data.data.criterion.fields.push(c);
                        }
                    }
                } else {
                    commit('removeConcept', {index: i, id: data.data.id});
                    if (data.data.criterion) {
                        data.data.criterion.fields.splice(i, 1);
                    }
                }
            }
        });
    },

    update({state, commit}, data) {
        const index = state.structuredSearch[data.id].normal.value.findIndex(c => c.id === data.concept.id);
        if (data.criterion) {
            data.criterion.fields.splice(index, 1, data.concept);
        }
        commit('updateConcept', {id: data.id, concept: data.concept, index: index});
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

const state = () => ({
    breadCrumbs: [],
    tabs: [],
    selectedTab: null
});

// getters
const getters = {
    getBreadCrumbs: state => state.breadCrumbs,
    getTabs: state => state.tabs,
    getSelectedTab: state => state.selectedTab,
};

// mutations
const mutations = {
    setBreadCrumbs(state, breadCrumbs) {
        state.breadCrumbs = breadCrumbs;
    },

    setTabs(state, tabs) {
        state.tabs = tabs;
    },

    setSelectedTab(state, selectedTab) {
        state.selectedTab = selectedTab;
    }
};

export const actions = {
    setTabIndex ({ commit }, index) {
        commit("setSelectedTab", index);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

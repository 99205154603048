import axios from 'axios';


const API_URL = process.env.VUE_APP_DWH_AUTH_URL;

class AuthCare {
    login(user) {
        return axios
            .post(API_URL + '/login/', {
                username: user.username,
                password: user.password
            }, );
    }
}

export default new AuthCare();

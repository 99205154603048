import SimpleLogin from "@/views/auth/simple/Login";
import TwoFactorLogin from "@/views/auth/twofactor/Login";
import OTPSetup from "@/views/auth/twofactor/OTPSetup";
import OTPVerification from "@/views/auth/twofactor/OTPVerification";
import OTPStaticVerification from "@/views/auth/twofactor/OTPStaticVerification";
import LockOut from "@/views/auth/twofactor/LockOut";
import Callback from "@/views/auth/twofactor/Callback";
import PasswordRecovery from "@/components/auth/PasswordRecovery";
import {accountUrls} from "@/router/routes/account";

export const simpleLoginUrls = [
  {
    path: "/login", // linked to auth.token.js logout methods
    name: "Login",
    component: SimpleLogin,
    meta: { disableNavBar: true, disableFooter: true, authNotRequired: true },
  },
  ...accountUrls
];

export const twoFactorUrls = [
  {
    path: "/login", // linked to auth.token.js logout methods
    name: "Login",
    component: TwoFactorLogin,
    meta: { authNotRequired: true, disableNavBar: true, disableFooter: true },
  },
  {
    path: "/otp/setup",
    name: "OTPSetup",
    component: OTPSetup,
    meta: { authNotRequired: true, disableNavBar: true, disableFooter: true },
  },
  {
    path: "/otp/verify",
    name: "OTPVerify",
    component: OTPVerification,
    meta: { authNotRequired: true, disableNavBar: true, disableFooter: true },
  },
  {
    path: "/otp/static",
    name: "OTPStatic",
    component: OTPStaticVerification,
    meta: { authNotRequired: true, disableNavBar: true, disableFooter: true },
  },
  {
    path: "/lockout",
    name: "LockOut",
    component: LockOut,
    meta: { authNotRequired: true, disableNavBar: true, disableFooter: true },
  },
  {
    path: "/callback",
    name: "Callback",
    component: Callback,
    meta: { disableNavBar: true, authNotRequired: true },
  },
    ...accountUrls,
  {
    path: "/passwordRecovery",
    name: "PasswordRecovery",
    component: PasswordRecovery,
    meta: { disableNavBar: true, authNotRequired: false },
  },
];

<template>
  <v-snackbar :class="getColorSnack(snackBar.color)"
              :value="snackBar.active" :timeout="snackBar.timeout" max-height="48px"
              outlined
  >
    <div class="d-inline-flex my-auto text-body-2 py-3 pl-4">
      <icon v-if="snackBar.icon" :name="snackBar.icon" :color="getColorIcon(snackBar.color)" size="20" class="mr-3 my-auto" />
      <span class="font-demi-bold">{{ snackBar.title }}</span>
      <span class="ml-1">{{ snackBar.text }}</span>
    </div>
    <template #action>
      <v-btn icon small @click="close">
        <icon name="close" outline size="20" :color="getColorIcon(snackBar.color)" />
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'SnackBar',
  computed: {
    ...mapGetters({
      snackBar: 'snackbar/getSnackBar'
    })
  },

  watch: {
    'snackBar.active'() {
      if (this.snackBar.active){
        /**
         * Close Snackbar after timeout when it's not an error
         */
          setTimeout(() => {
            this.$store.commit('snackbar/closeSnackbar');
          }, this.snackBar.timeout);
      }
    }
  },

  methods: {
    close() {
      this.$store.commit('snackbar/closeSnackbar');
    },

    getColorSnack(color) {
      switch (color) {
        case 'var(--danger-surface)':
          return "snack-error";
        case 'var(--warning-surface)':
          return "snack-warning";
        case 'var(--success-main)':
          return "snack-success";
      }
    },

    getColorIcon(color) {
      switch (color) {
        case 'var(--danger-surface)':
          return 'danger-hover';
        case 'var(--warning-surface)':
          return 'warning-pressed';
        case 'var(--success-main)':
          return 'success-pressed';
      }
    }
  }
};
</script>

<style>
.v-snack__wrapper {
  min-width: 500px !important;
  border-radius: 8px !important;
  margin-bottom: 40px;
}

.v-snack__content {
  padding: unset;
  min-height: unset;
}

/*Snackbar error*/
.snack-error > .v-sheet--outlined {
  border: 2px solid var(--danger-border) !important;
  background-color: var(--danger-surface) !important;
  color: var(--danger-hover) !important;
}

/*Snackbar warning*/
.snack-warning > .v-sheet--outlined {
  border: 2px solid var(--warning-border) !important;
  background-color: var(--warning-surface) !important;
  color: var(--warning-pressed) !important;
}

/*Snackbar success*/
.snack-success > .v-sheet--outlined {
  border: 2px solid var(--success-border) !important;
  background-color: var(--success-surface) !important;
  color: var(--success-pressed) !important;
}
</style>

<template>
  <div class="text-body-2 basics-80">
    {{ t('Pour récupérer l\'accès à votre compte, vous pouvez envoyer un mail à') }}
    <a class="font-demi-bold primary-main" :href="'mailto:' + settings.redirect_email">
      {{ settings.redirect_email }}
    </a>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import { mapGetters } from 'vuex';

export default {
  name: 'LockOut',
  mixins: [i18n],

  computed: {
    ...mapGetters({
      settings: 'customApp/getCustomSettings'
    })
  }
};
</script>
<template>
  <login-template
    title="Connexion"
    :logo="care_logo"
    :loading="loading"
    :error-message="errorMsg"
    :form="form"
    :description="description"
    logo-width="92"
    @submit="login"
  />
</template>

<script>
import i18n from '@/mixins/i18n';
import { mapGetters } from 'vuex';
import { Errors } from '@/enums/response_errors';
import {APPS} from "@/config";
import LoginTemplate from '@/views/auth/LoginTemplate';

export default {
  name: 'Login',
  components: { LoginTemplate },
  mixins: [i18n],

  data () {
    return {
      loading: false,
      errorMsg: '',
      care_logo: null,
      form: {
        username: '',
        password: ''
      },
    };
  },

  computed: {
    ...mapGetters({
      user: 'user/me',
      customSettings: 'customApp/getCustomSettings'
    }),

    description() {
      return APPS[process.env.VUE_APP_MODE].description;
    }
  },

  created () {
    this.care_logo = require('@/assets/img/logo/ResearchHub-Care.svg');
  },

  methods: {
    login () {
      this.errorMsg = '';
      this.loading = true;
      this.$store.dispatch('auth/login', this.form).then(
        () => {
          return this.$store.dispatch('user/getUser').then(
            () => {
              if (!this.user.is_cgu_valid) {
                this.$router.push({ name: 'Cgu' });
              } else if (!this.user.persona) {
                this.$router.push({ name: 'Persona' });
              } else {
                this.$router.push({ name: 'Home' });
              }
            }
          );
        },
        error => {
          if (error.response.status === 401 && error.response.data.message !== Errors.NO_ACTIVE_ACCOUNT.text) {
            this.$store.commit('snackbar/errorSnackbar', { message: error.response.data.message });
          } else {
            this.$store.commit('snackbar/apiErrorSnackbar', error.response.data);
          }
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            this.errorMsg = error.response.data.message;
          } else {
            this.errorMsg = this.t('Une erreur est survenue.');
          }
        }
      ).finally(() => {this.loading = false;});
    }
  }
};
</script>

import {MODE} from '@/config';

const CARE_REFRESH_TOKEN = "careRefreshToken" + process.env.VUE_APP_BASE_URL;
const CARE_ACCESS_TOKEN = "careAccessToken" + process.env.VUE_APP_BASE_URL;
const CARE_EXPIRY_TIME = "careExpiryTime" + process.env.VUE_APP_BASE_URL;

const EDS_ID_TOKEN = "edsIdToken" + process.env.VUE_APP_BASE_URL;
const EDS_VERIFY_TOKEN = "edsVerifyToken" + process.env.VUE_APP_BASE_URL;
const EDS_REFRESH_TOKEN = "edsRefreshToken" + process.env.VUE_APP_BASE_URL;
const EDS_ACCESS_TOKEN = "edsAccessToken" + process.env.VUE_APP_BASE_URL;
const EDS_EXPIRY_TIME = "edsExpiryTime" + process.env.VUE_APP_BASE_URL;

const GDDI_REFRESH_TOKEN = "gddiRefreshToken" + process.env.VUE_APP_BASE_URL;
const GDDI_ACCESS_TOKEN = "gddiAccessToken" + process.env.VUE_APP_BASE_URL;
const GDDI_EXPIRY_TIME = "gddiExpiryTime" + process.env.VUE_APP_BASE_URL;


class TokenService {
    constructor() {
        switch (process.env.VUE_APP_MODE) {
            case MODE.CARE:
                this.refreshToken = CARE_REFRESH_TOKEN;
                this.accessToken = CARE_ACCESS_TOKEN;
                this.expiryTime = CARE_EXPIRY_TIME;
                break;
            case MODE.GDDI:
                this.refreshToken = GDDI_REFRESH_TOKEN;
                this.accessToken = GDDI_ACCESS_TOKEN;
                this.expiryTime = GDDI_EXPIRY_TIME;
                break;
            case MODE.EDS:
            case MODE.RESEARCH:
                this.refreshToken = EDS_REFRESH_TOKEN;
                this.accessToken = EDS_ACCESS_TOKEN;
                this.expiryTime = EDS_EXPIRY_TIME;
                break;
        }
    }

    getIdToken() {
        switch (process.env.VUE_APP_MODE) {
            case MODE.EDS:
            case MODE.CARE:
            case MODE.RESEARCH:
            case MODE.GDDI:
                return localStorage.getItem(EDS_ID_TOKEN);
            default:
                return;
        }
    }

    setIdToken(token) {
        switch (process.env.VUE_APP_MODE) {
            case MODE.EDS:
            case MODE.CARE:
            case MODE.RESEARCH:
            case MODE.GDDI:
                localStorage.setItem(EDS_ID_TOKEN, token);
                break;
            default:
                break;
        }
    }

    removeIdToken() {
        switch (process.env.VUE_APP_MODE) {
            case MODE.EDS:
            case MODE.CARE:
            case MODE.RESEARCH:
            case MODE.GDDI:
                localStorage.removeItem(EDS_ID_TOKEN);
                break;
            default:
                break;
        }
    }

    getVerifyToken() {
        switch (process.env.VUE_APP_MODE) {
            case MODE.EDS:
            case MODE.CARE:
            case MODE.GDDI:
            case MODE.RESEARCH:
                return localStorage.getItem(EDS_VERIFY_TOKEN);
            default:
                return;
        }
    }

    setVerifyToken(token) {
        switch (process.env.VUE_APP_MODE) {
            case MODE.EDS:
            case MODE.CARE:
            case MODE.GDDI:
            case MODE.RESEARCH:
                localStorage.setItem(EDS_VERIFY_TOKEN, token);
                break;
            default:
                break;
        }
    }

    getRefreshToken() {
        return localStorage.getItem(this.refreshToken);
    }

    setRefreshToken(token) {
        localStorage.setItem(this.refreshToken, token);
    }

    removeRefreshToken() {
        localStorage.removeItem(this.refreshToken);
    }

    getAccessToken() {
        return localStorage.getItem(this.accessToken);
    }

    setAccessToken(token) {
        localStorage.setItem(this.accessToken, token);
    }

    removeAccessToken() {
        localStorage.removeItem(this.accessToken);
    }

    getExpiryTime() {
        return localStorage.getItem(this.expiryTime);
    }

    setExpiryTime(token) {
        localStorage.setItem(this.expiryTime, token);
    }

    removeExpiryTime() {
        localStorage.removeItem(this.expiryTime);
    }

}

export default new TokenService();

import { Sources } from "@/services/api";
//import { DefaultParameterType } from "@/store/utils";


const state = () => ({
  sources: [],
  sourcesBiology: [],
  sourcesPmsi: [],
  sourcesPrescription: [],
});

// getters
const getters = {
  sources: state => state.sources,
  sourcesBiology: state => state.sourcesBiology,
  sourcesPmsi: state => state.sourcesPmsi,
  sourcesPrescription: state => state.sourcesPrescription,
};

// mutations
const mutations = {
  setSources(state, sources) {
    state.sources = sources;
  },
  /*setSourcesBiology(state, sources) {
    state.sourcesBiology = sources;
  },
  setSourcesPmsi(state, sources) {
    state.sourcesPmsi = sources;
  },
  setSourcesPrescription(state, sources) {
    state.sourcesPrescription = sources;
  }*/
};

// actions
const actions = {
  getSources({ commit }) {
    Sources.list().then(response => {
      const sources = response.data.results.map(t => {
        return { value: t.type.name, text: t.type.name };
      });
      sources.unshift({ value: null, text: 'Tous' });
      commit('setSources', sources);
    });
  },
  /*getSourcesBiology({ commit }) {
    Sources.list({ type__name: DefaultParameterType.BIOLOGY.value }).then(response => {
      const sources = response.data.results.map(t => {
        return { value: t.type.name, text: t.type.name };
      });
      commit('setSourcesBiology', sources);
    });
  },
  getSourcesPmsi({ commit }) {
    Sources.list({ type__name: DefaultParameterType.PMSI.value }).then(response => {
      const sources = response.data.results.map(t => {
        return { value: t.type.name, text: t.type.name };
      });
      commit('setSourcesPmsi', sources);
    });
  },
  getSourcesPrescription({ commit }) {
    Sources.list({ type__name: DefaultParameterType.PRESCRIPTION.value }).then(response => {
      const sources = response.data.results.map(t => {
        return { value: t.type.name, text: t.type.name };
      });
      commit('setSourcesPrescription', sources);
    });
  }*/
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

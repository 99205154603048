import store from '@/store';
import {Perm} from "@/enums/permissions";


export function hasPerm(perm) {
    let permissions = store.state.user.me.permissions;
    return !perm || permissions.includes(perm);
}

export function atLeastOnePerm(perms) {
    let permissions = store.state.user.me.permissions;
    return perms.some(perm => permissions.includes(perm));
}

export function hasPatientLevelAccess() {
    return hasPerm(Perm.DataNominative.value) || hasPerm(Perm.DataPseudonymized.value);
}

export function hasCohortAccess() {
    return hasPatientLevelAccess() && hasPerm(Perm.AppCohort.value);
}

export function hasPatientSearchAccess() {
    return hasPatientLevelAccess() && hasPerm(Perm.AppPatientSearch.value);
}

export function hasPatientFolderAccess() {
    return hasPatientLevelAccess() && hasPerm(Perm.AppPatient.value);
}

export function hasAppCohortExportAccess() {
    return hasPatientLevelAccess() && hasPerm(Perm.AppCohortExport.value);
}

export function hasBiologyAccess() {
    return hasPerm(Perm.AllSources.value) || atLeastOnePerm(store.getters["sources/sourcesBiology"]);
}

export function hasPMSIAccess() {
    return hasPerm(Perm.AllSources.value) || atLeastOnePerm(store.getters["sources/sourcesPmsi"]);
}

export function hasPrescriptionAccess() {
    return hasPerm(Perm.AllSources.value) || atLeastOnePerm(store.getters["sources/sourcesPrescription"]);
}

// initial state
import {LibrarySearchRequest, SavedRequest} from "@/services/api";

const state = () => ({
    savedRequests: [],
    libraryRequests: []
});

// getters
const getters = {
    savedRequests: state => state.savedRequests,
    libraryRequests: state => state.libraryRequests
};

// actions
const actions = {
    getSavedRequests ({ commit }) {
        SavedRequest.list().then(response => {
            commit('setSavedRequests', response.data);
        });
    },

    getLibraryRequests ({ commit }) {
        LibrarySearchRequest.list().then(response => {
            commit('setLibraryRequests', response.data.results);
        });
    }
};

// mutations
const mutations = {
    setSavedRequests (state, requests) {
        state.savedRequests = requests;
    },

    setLibraryRequests (state, requests) {
        state.libraryRequests = requests;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

const Cohorts = () => import('@/views/commons/cohorts/Cohorts.vue');
const CohortPatientHeader = () => import('@/views/commons/cohorts/CohortPatientHeader.vue');
const PatientRecord = () => import('@/views/commons/patient_record/PatientRecord.vue');
const CohortInstance = () => import('@/views/commons/cohorts/CohortInstance.vue');

export const cohortRoutes = {
  path: "/cohorts",
  name: "Cohorts",
  component: Cohorts,
  children: [
    {
      path: ":cohortId",
      name: "Cohort instance",
      component: CohortInstance,
      meta: {alertMessagePosition: true, logoPosition: true},
      props: true,
      children: [
        {
          path: "patients",
          name: "CohortNavigator",
          component: CohortPatientHeader,
          meta: {alertMessagePosition: true, logoPosition: true},
          props: true,
          children: [
            {
              path: ":patientId",
              name: "CohortPatientRecord",
              component: PatientRecord,
              meta: {alertMessagePosition: true, logoPosition: true},
              props: true,
            },
          ],
        },
      ],
    },
  ],
};

const SingleDocument = () => import("@/views/commons/patient_record/SingleDocument");

const PatientRecord = () =>
  import("@/views/commons/patient_record/PatientRecord.vue");

export const patientRoutes = [
  {
    path: "/patients/:patientId",
    name: "PatientRecord",
    component: PatientRecord,
    props: true,
    meta: {
      noNavBarTabs: true,
    },
  },
  {
    path: "/document/:key",
    name: "SingleDocument",
    component: SingleDocument,
    props: true,
    meta: {
      noNavBarTabs: true,
    },
  },
];

import {TextValueEnum} from "@/utils/enums";

/**
 * List of opposition status for a patient.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const Opposition = {
    NOT_OPPOSED: new TextValueEnum("not_opposed", "Non opposé"),
    OPPOSED: new TextValueEnum("opposed", "Opposé")
};

/**
 * List of information status for a patient.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const Information = {
    NOT_INFORMED: new TextValueEnum("not_informed", "Non informé"),
    INFORMED: new TextValueEnum("informed", "Informé")
};
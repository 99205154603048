import { AdminFeatureFlags } from "@/services/api";

const state = () => ({
    featureFlags: {
        list: [],
        count: 0,
    },

    name: "",
    isActive: false,
});

// getters
const getters = {
    getFeatureFlags: (state) => state.featureFlags,
    getName: state => state.name,
    getIsActive: state => state.isActive
};

// mutations
const mutations = {
    setFeatureFlags(state, featureFlags) {
        state.featureFlags.list = featureFlags.results;
        state.featureFlags.count = featureFlags.count;
    },

    setName(state, name) {
        state.name = name;
    },

    setIsActive(state, bool) {
        state.isActive = bool;
    },

    createFeatureFlag(state, featureFlag) {
        state.featureFlags.list.unshift(featureFlag);
        state.featureFlags.count +=1;
    },

    deleteFeatureFlag(state, index) {
        state.featureFlags.list.splice(index, 1);
        state.featureFlags.count -= 1;
    }
};

const actions = {
    updateIsActive({commit}, {id, is_active, message}) {
        return AdminFeatureFlags.partial_update(id, { is_active }).then(() => {
            commit('setIsActive', is_active);
            commit('snackbar/successSnackbar', {message: message}, {root: true});
        }).catch(error => {
            commit('snackbar/apiErrorSnackbar', error, { root: true });
            throw error;
        });
    },

    getFeatureFlagsList({commit}, params) {
        return AdminFeatureFlags.list(params).then((response) => {
            commit("setFeatureFlags", response.data);
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

<template>
  <v-container fill-height>
    <div class="d-flex justify-center px-6 my-10 mx-auto w-100">
      <div class="w-50 bg-basics-10 p-5 br-8 Z-4">
        <div class="px-8 mb-4 text-center">
          <h5 class="pt-4 mb-2 text-h5 text-h5 font-demi-bold">
            {{ t("Mot de passe oublié ?") }}
          </h5>
          <p class="text-decoration-none font-demi-bold text-secondary">
            {{ t("Veuillez envoyer un email à ") }}
            <span class="text-decoration-underline"> {{ customSettings.redirect_email ? customSettings.redirect_email : 'Chargement du mail...' }} </span>
            {{ t("afin de réinitialiser votre mot de passe") }}
          </p>
        </div>
        <div class="px-8 pt-6 pb-8 mb-4 bg-basics-10 rounded mb-6 text-center">
          <button
            class="w-100 px-4 py-2 font-bold basics-10 bg-primary-main rounded-xl" type="button"
            @click="sendMail()"
          >
            {{ t("Envoyer un email") }}
          </button>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "PasswordRecovery",

  computed: {
    ...mapGetters({
      customSettings: 'customApp/getCustomSettings',
    })
  },

  methods: {
    sendMail() {
      window.open("mailto: " + this.customSettings.redirect_email);
    }
  }
};
</script>


import { Errors } from "@/enums/response_errors";

const state = () => ({
  color: "",
  title: "",
  text: "",
  icon: "",
  active: false,
  bottom: false,
});

// getters
const getters = {
  getSnackBar: (state) => state,
};

// mutations
const mutations = {
  /**
   * Success Snackbar with custom message
   */
  successSnackbar(state, payload) {
    state.title = payload.title;
    state.text = payload.message;
    state.icon = "checkmark-circle";
    state.color = "var(--success-main)";
    state.timeout = 3000;
    state.active = true;
  },

  /**
   * Warning Snackbar with custom message
   */
  warningSnackbar(state, payload) {
    state.title = payload.title;
    state.text = payload.message;
    state.icon = "alert";
    state.color = "var(--warning-surface)";
    state.timeout = 5000;
    state.active = true;
  },

  /**
   * Api error snackbar, display error message depending on api error
   */
  apiErrorSnackbar(state, payload) {
    const code = payload.response ? payload.response.data.code : payload.code;
    let message = Object.values(Errors).find((e) => e.value === code);
    if (message && message.text) {
      let placeholders = [];
      message.placeholders?.forEach((placeholder) => {
        placeholders.push(payload?.response?.data[placeholder]);
      });
      state.text = message.text(...placeholders);
    } else if (payload.detail) {
      state.text = payload.detail;
    } else if (payload?.response?.data?.message) {
      state.text = payload.response.data.message;
    } else if (payload?.response?.data?.title) {
      state.text = payload.response.data.title[0];
    } else {
      state.text = `Une erreur est survenue`;
      if (code) {
        state.text += `: (code d'erreur = ${code})`;
      }
    }
    state.icon = "close-circle";
    state.color = "var(--danger-surface)";
    state.timeout = 5000;
    state.active = true;
  },

  /**
   * Error Snackbar, you can use custom message or use the default error message
   */
  errorSnackbar(state, payload) {
    if (!payload) {
      state.text = "Une erreur est survenue.";
    } else {
      if (payload.message) {
        state.text = payload.message;
      }
      if (payload.title) {
        state.title = payload.title;
      }
    }
    state.icon = "close-circle";
    state.color = "var(--danger-surface)";
    state.timeout = 5000;
    state.active = true;
  },

  /**
   * Close Snackbar after timeout
   */
  closeSnackbar(state) {
    state.active = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};

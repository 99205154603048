<template>
  <div>
    <p>
      {{ t('Merci de prendre le temps de nous citer, nous vous en sommes vraiment reconnaissants.') }}
    </p>
    <p class="m-0 mt-6">
      {{ t('Voici un texte, regroupant les principales informations pour nous citer:') }}
    </p>
    <p class="text-body-2 font-regular basics-80 mt-3 m-0">
      We recruited patients from this study using the data warehouse at XXX Hospital and codoc software (codoc 2022  (ref). It allows to search for patients from structured data (biology) and free text (hospital reports). It contains xxxx patients and x millions health reports.
    </p>
    <v-divider class="my-6" />
    <div class="text-subtitle-1 font-demi-bold basics-100 mb-3">
      <span v-html="journalUnicode" />   {{ t(' Article de référence') }}
    </div>
    <p class="text-body-2 font-regular basics-80 mt-0">
      {{ t('Garcelon N, Neuraz A, Salomon R, Faour H, Benoit V, Delapalme A, Munnich A, Burgun A, Rance B.') }}
      <br>
      {{ t(`A clinician friendly data warehouse oriented toward narrative reports: codoc Research Hub. J Biomed Inform. 2018 Apr;80:52-63. doi: 10.1016/j.jbi.2018.02.019. Epub 2018 Mar 1. PubMed PMID: 29501921`) }}
    </p>
    <a class="text-body-2 font-regular primary--text" href="https://pubmed.ncbi.nlm.nih.gov/29501921/" target="_blank">{{ t('Lien') }} PubMed</a>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";

export default {
  name: "MentionModal",
  mixins: [i18n],

  data () {
    return {
      journalUnicode : "&#128240;"
    };
  },
};
</script>

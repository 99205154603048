<template>
  <login-template title="Récupération de compte" :logo="logo"
                  logo-width="159" lock-out
  />
</template>

<script>
import i18n from "@/mixins/i18n";
import LoginTemplate from '@/views/auth/LoginTemplate';
import { MODE } from '@/config';

export default {
  name: 'LockOut',
  components: { LoginTemplate },
  mixins: [i18n],

  data() {
    return {

    };
  },

  computed: {
    logo () {
      switch (process.env.VUE_APP_MODE) {
        case MODE.CARE:
          return require('@/assets/img/logo/ResearchHub-Care.svg');
        case MODE.EDS:
          return require('@/assets/img/logo/ResearchHub.svg');
        case MODE.RESEARCH:
          return require('@/assets/img/logo/ResearchHub-Workspace.svg');
        default:
          return '';
      }
    }
  }
};
</script>

// initial state
import {Sites} from "@/services/api";
import {Instances} from "@/services/api";

const state = () => ({
    all: [],
    loading: false,
    filters: {},
});

// getters
const getters = {
    loadingSites: state => state.loading,
    filters: state => state.filters,
    all: state => {
        return state.all;
    }};

// actions
const actions = {
        async getSites({ commit, dispatch, rootGetters }, params = {})
        {
            let customSettings = rootGetters['customApp/getCustomSettings'];
            if (!customSettings) {
                customSettings = await dispatch('customApp/getCustomSettings', null, {root: true});
            }
            commit('setLoading', true);
            if (customSettings.pole_filter === 'sites') {
                Sites.list(params).then(response => {
                    commit('setSites', response.data.results);
                }).finally(() => {
                    commit('setLoading', false);
                });
            } else if (customSettings.pole_filter === 'instances') {
                params.restricted = true;
                Instances.list(params).then(response => {
                    commit('setSites', response.data.results);
                }).finally(() => {
                    commit('setLoading', false);
                });
            }
        }

};

// mutations
const mutations = {
    setSites (state, sites) {
        state.all = sites;
    },

    setFilters (state, filters) {
        state.filters = filters;
    },

    setLoading (state, loading) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

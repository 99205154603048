import {TextValueEnum} from "@/utils/enums";
/**
 * List of default sources types in the API
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const DocumentTypes = {
    PRESCRIPTION: new TextValueEnum("Prescription", "Type prescription"),
    BIOLOGY: new TextValueEnum("Biologie", "Type biologie"),
    PMSI: new TextValueEnum("PMSI", "Type PMSI"),
    EFR: new TextValueEnum("EFR", "Type EFR")
};
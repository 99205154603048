import { TextValueEnum } from "@/utils/enums";
import i18n from "@/mixins/i18n";
const t = i18n.methods.t;
import store from "@/store";

export const shouldIncludeSitesOrInstances = async () => {
  let customSettings = store.getters['customApp/getCustomSettings'];
  if (!customSettings) {
     await store.dispatch('customApp/getCustomSettings');
     customSettings = store.getters['customApp/getCustomSettings'];
  }
  return customSettings.pole_filter === 'sites'
      ? new TextValueEnum("sites", t("Pôle"), "FilterSelect")
      : customSettings.pole_filter === 'instances' ?
      new TextValueEnum("instances", t("Pôle"), "FilterSelect") : null;
};

/**
 * List of filters for patient record.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const PatientRecordFilters = {
  DOCUMENT_DATE: new TextValueEnum("document_date", t("Date"), "FilterDate"),
  TITLE: new TextValueEnum("title", t("Titre"), "FilterText"),
  ORIGINS: new TextValueEnum("origins", t("Source"), "FilterSelect"),
  ENCOUNTERS: new TextValueEnum("encounters", t("Séjours"), "FilterSelect"),
  DEPARTMENTS: new TextValueEnum("departments", t("Service"), "FilterSelect"),
  UNITS: new TextValueEnum("units", t("Unité"), "FilterSelect"),
  AGE_PATIENT: new TextValueEnum(
    "age_patient",
    t("Âge du patient"),
    "FilterNumber"
  ),
  STAY_DURATION: new TextValueEnum(
    "stay_duration",
    t("Durée de séjour"),
    "FilterNumber"
  ),
};


export const PatientExportFilters = {
    STAY_ENTRY_DATE: new TextValueEnum("stay_entry_date", "Date du début du séjour", "FilterDate"),
    STAY_OUT_DATE: new TextValueEnum("stay_out_date", "Date de fin du séjour", "FilterDate"),
    DEPARTMENTS: new TextValueEnum("departments", "Service", "FilterSelect"),
    UNITS: new TextValueEnum("units", "Unité", "FilterSelect"),
    MOVEMENT: new TextValueEnum("movement_type", t("Type de mouvement"), "FilterSelect"),
};

export const RequestsLogFilters = {
    USER: new TextValueEnum("user", "Nom utilisateur", "FilterSelect"),
    DATE: new TextValueEnum("date", "Date de la requête", "FilterDate")
};

/**
 * List of filters for BarFilter.
 *
 * @type {Object.<string,TextValueEnum>}
 */

export const filtersType = [
    {text: "Numerique", value: "numeric"},
    {text: "Textuelle", value: "text"},
];

export const PathwayStaysFilters = {
    STAYS_STATS_YEARS: new TextValueEnum("stays_stats_years", t("Années"), "FilterSelect"),
};
export const PathwayUnitsFilters = {
    UNITS_STATS_YEARS: new TextValueEnum("units_stats_years", t("Années"), "FilterSelect"),
    UNITS: new TextValueEnum("units", t("Unité"), "FilterSelect"),
};
export const PseudoBiologyFilters = {
    DOCUMENT_DATE: new TextValueEnum("document_date", t("Date"), "FilterDate"),
    ORIGINS: new TextValueEnum("origins", t("Source"), "FilterSelect"),
    DEPARTMENTS: new TextValueEnum("departments", t("Service"), "FilterSelect"),
    UNITS: new TextValueEnum("units", t("Unité"), "FilterSelect"),
    AGE_PATIENT: new TextValueEnum(
        "age_patient",
        t("Age du patient"),
        "FilterNumber"
    ),
    STAY_DURATION: new TextValueEnum(
        "stay_duration",
        t("Durée de séjour"),
        "FilterNumber"
    ),
};
export const BiologyFilters = {
    ...PseudoBiologyFilters,
    ENCOUNTERS: new TextValueEnum("encounters", t("Séjours"), "FilterSelect"),
};

export const SynonymFilters = {
    TRUE: new TextValueEnum(1, t("Oui")),
    FALSE: new TextValueEnum(0, t("Non")),
};

export const CertaintyFilters = {
    TRUE: new TextValueEnum([1, -1], t("Oui")),
    FALSE: new TextValueEnum([1], t("Non")),
};
export const ExportStatusFilters = {
    CANCELLED: new TextValueEnum("cancelled", "Annulé"),
    ERROR: new TextValueEnum("error", "Erreur"),
    SUCCESS: new TextValueEnum("success", "Généré"),
    RUNNING: new TextValueEnum("running", "En cours"),
};

export const StructuredFilters = {
    DOCUMENT_DATE: new TextValueEnum("document_date", t("Date"), "FilterDate"),
    ORIGINS: new TextValueEnum("origins", t("Source"), "FilterSelect"),
    AGE_PATIENT: new TextValueEnum(
        "age_patient",
        t("Âge du patient"),
        "FilterNumber"
    ),
    STAY_DURATION: new TextValueEnum(
        "stay_duration",
        t("Durée de séjour"),
        "FilterNumber"
    ),
};
export const StaysFilters = {
    ENCOUNTERS: new TextValueEnum("encounters", t("Séjours"), "FilterSelect"),
    STAY_ENTRY_DATE: new TextValueEnum(
        "entry_date",
        t("Date d'entrée"),
        "FilterDate"
    ),
    STAY_OUT_DATE: new TextValueEnum(
        "out_date",
        t("Date de sortie"),
        "FilterDate"
    ),
    STAY_DURATION: new TextValueEnum(
        "stay_duration",
        t("Durée de séjour"),
        "FilterNumber"
    ),
    // STAY_ENTRY_MODE: new TextValueEnum("entry_mode", t('Mode d\'entrée'), 'FilterSelect'),
    // STAY_OUT_MODE: new TextValueEnum("out_mode", t('Mode de sortie'), 'FilterSelect')
};
export const ExportTableFilters = {
    CREATED_DATE: new TextValueEnum(
        "created_date",
        t("Date de création"),
        "FilterDate"
    ),
    STATUS: new TextValueEnum("status", t("État de l'export"), "FilterSelect"),
    IS_SHARED: new TextValueEnum("is_shared", t("Partage"), "FilterSelect"),
};
export const initializeFilters = async () => {
    const PseudoDocumentsFilters = {
        DOCUMENT_DATE: new TextValueEnum("document_date", t("Date"), "FilterDate"),
        ORIGINS: new TextValueEnum("origins", t("Source"), "FilterSelect"),
        TYPES: new TextValueEnum("types", t("Type"), "FilterSelect"),
        DEPARTMENTS: new TextValueEnum("departments", t("Service"), "FilterSelect"),
        UNITS: new TextValueEnum("units", t("Unité"), "FilterSelect"),
        ...(await shouldIncludeSitesOrInstances() && {SITES: await shouldIncludeSitesOrInstances()}),
        AGE_PATIENT: new TextValueEnum("age_patient", t("Age du patient"), "FilterNumber"),
        STAY_DURATION: new TextValueEnum("stay_duration", t("Durée de séjour"), "FilterNumber"),
    };

    const DocumentsFilters = {
        ...PseudoDocumentsFilters,
        ENCOUNTERS: new TextValueEnum("encounters", t("Séjours"), "FilterSelect"),
    };

    const PseudoPatientStructuredFilters = {
        DOCUMENT_DATE: new TextValueEnum("document_date", t("Date"), "FilterDate"),
        ORIGINS: new TextValueEnum("origins", t("Source"), "FilterSelect"),
        DEPARTMENTS: new TextValueEnum("departments", t("Service"), "FilterSelect"),
        UNITS: new TextValueEnum("units", t("Unité"), "FilterSelect"),
        ...(await shouldIncludeSitesOrInstances() && {SITES: await shouldIncludeSitesOrInstances()}),
        AGE_PATIENT: new TextValueEnum(
            "age_patient",
            t("Age du patient"),
            "FilterNumber"
        ),
        STAY_DURATION: new TextValueEnum(
            "stay_duration",
            t("Durée de séjour"),
            "FilterNumber"
        ),
    };

    const PatientStructuredFilters = {
        ...PseudoPatientStructuredFilters,
        ENCOUNTERS: new TextValueEnum("encounters", t("Séjours"), "FilterSelect"),
    };

    const PseudoMovementsFilters = {
        ENTRY_DATE: new TextValueEnum("entry_date", t("Date d'entrée"), "FilterDate"),
        OUT_DATE: new TextValueEnum("out_date", t("Date de sortie"), "FilterDate"),
        MOVEMENTS: new TextValueEnum("movement_type", t("Type"), "FilterSelect"),
        DEPARTMENTS: new TextValueEnum("departments", t("Service"), "FilterSelect"),
        UNITS: new TextValueEnum("units", t("Unité"), "FilterSelect"),
        ...(await shouldIncludeSitesOrInstances() && {SITES: await shouldIncludeSitesOrInstances()}),
        ENTRY_MODE: new TextValueEnum(
            "entry_mode",
            t("Mode d'entrée"),
            "FilterSelect"
        ),
        OUT_MODE: new TextValueEnum("out_mode", t("Mode de sortie"), "FilterSelect"),
        AGE_PATIENT: new TextValueEnum(
            "age_patient",
            t("Age du patient"),
            "FilterNumber"
        ),
        STAY_DURATION: new TextValueEnum(
            "stay_duration",
            t("Durée de séjour"),
            "FilterNumber"
        ),
    };

    const MovementsFilters = {
        ...PseudoMovementsFilters,
        ENCOUNTERS: new TextValueEnum("encounters", t("Séjours"), "FilterSelect"),
    };

    const PathwayDptFilters = {
        DEPARTMENTS_STATS_YEARS: new TextValueEnum("departments_stats_years", t("Années"), "FilterSelect"),
        DEPARTMENTS: new TextValueEnum("departments", t("Service"), "FilterSelect"),
        ...(await shouldIncludeSitesOrInstances() && {SITES: await shouldIncludeSitesOrInstances()}),
    };

    const ExpertFilters = {
        TITLE: new TextValueEnum("title", t("Titre"), "FilterText"),
        DOCUMENT_DATE: new TextValueEnum("document_date", t("Date"), "FilterDate"),
        ORIGINS: new TextValueEnum("origins", t("Source"), "FilterSelect"),
        TYPES: new TextValueEnum("types", t("Type"), "FilterSelect"),
        DEPARTMENTS: new TextValueEnum("departments", t("Service"), "FilterSelect"),
        UNITS: new TextValueEnum("units", t("Unité"), "FilterSelect"),
        ...(await shouldIncludeSitesOrInstances() && {SITES: await shouldIncludeSitesOrInstances()}),
        AGE_PATIENT: new TextValueEnum(
            "age_patient",
            t("Âge du patient"),
            "FilterNumber"
        ),
        STAY_DURATION: new TextValueEnum(
            "stay_duration",
            t("Durée de séjour"),
            "FilterNumber"
        ),
        CONTEXT: new TextValueEnum("context", t("Contexte"), "FilterSelect"),
        CERTAINTY: new TextValueEnum(
            "certainty",
            t("Inclure les négations"),
            "FilterSelect"
        ),
        SYNONYM: new TextValueEnum(
            "synonym",
            t("Étendre aux synonymes"),
            "FilterSelect"
        ),
    };

    const RawExportFilters = {
        DATE: new TextValueEnum("date", "Date du document", "FilterDate"),
        DEPARTMENTS: new TextValueEnum("departments", "Service", "FilterSelect"),
        UNITS: new TextValueEnum("units", "Unité", "FilterSelect"),
        ...(await shouldIncludeSitesOrInstances() && {SITES: await shouldIncludeSitesOrInstances()}),
        COHORT_STATUS: new TextValueEnum("cohort_status", "Statut", "FilterSelect"),
    };

    const AggregatedExportFilters = {
        DATE: new TextValueEnum("date", "Date du document", "FilterDate"),
        DEPARTMENTS: new TextValueEnum("departments", "Service", "FilterSelect"),
        UNITS: new TextValueEnum("units", "Unité", "FilterSelect"),
        ...(await shouldIncludeSitesOrInstances() && {SITES: await shouldIncludeSitesOrInstances()}),
        SEX: new TextValueEnum("sex", "Sexe", "FilterSelect"),
        AGE_PATIENT: new TextValueEnum("age_patient", "Âge du patient", "FilterNumber"),
        IS_ALIVE: new TextValueEnum("is_alive", "Statut vital", "FilterSelect"),
        COHORT_STATUS: new TextValueEnum("cohort_status", "Statut", "FilterSelect"),
    };

    return {
        PseudoDocumentsFilters,
        DocumentsFilters,
        PseudoPatientStructuredFilters,
        PatientStructuredFilters,
        PseudoMovementsFilters,
        MovementsFilters,
        PathwayDptFilters,
        ExpertFilters,
        RawExportFilters,
        AggregatedExportFilters
    };
};
import {TextValueEnum} from "@/utils/enums";
import i18n from "@/mixins/i18n";
let t = i18n.methods.t;

/**
 * List of definition status for workspaces used in eds mode.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const DefinitionStatus = {
    DRAFT: new TextValueEnum("draft", "Brouillon"),
    TO_REVIEW: new TextValueEnum("to_review", "A évaluer"),
    TO_VALIDATE: new TextValueEnum("to_validate", "A valider par CSE/DRCI"),
    WAITING_DRCI: new TextValueEnum("waiting_drci", "En attente de validation par la DRCI"),
    WAITING_CSE: new TextValueEnum("waiting_cse", "En attente de validation par le CSE"),
    VALIDATED: new TextValueEnum("validated", "Validé"),
    ARCHIVED: new TextValueEnum("archived", "Archivé")
};

/**
 * List of availability status for workspaces used in eds mode.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const AvailabilityStatus = {
    READY_FOR_SETUP: new TextValueEnum("ready_setup", "En construction"),
    ERROR_SETUP: new TextValueEnum("error_setup", "Erreur"),
    READY_FOR_INTEGRATION: new TextValueEnum("ready_integration", "En construction"),
    ERROR_INTEGRATION: new TextValueEnum("error_integration", "Erreur"),
    READY_FOR_INDEXATION: new TextValueEnum("ready_indexation", "En construction"),
    ERROR_INDEXATION: new TextValueEnum("error_indexation", "Erreur"),
    READY: new TextValueEnum("ready", "Prêt")
};

/**
 * List of session status for workspaces used in eds mode.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const SessionStatus = {
    STARTING: new TextValueEnum("starting", t("Starting")),
    RUNNING: new TextValueEnum("running", t("Running")),
    STOPPING: new TextValueEnum("stopping", t("Stopping")),
    ERROR: new TextValueEnum("error", t("Error")),
    ENDED: new TextValueEnum("ended", t("Ended"))
};

/**
 * List of methodologies in workspace form for workspaces used in eds mode.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const Methodologies = {
    MR003: new TextValueEnum("mr003", t("MR-003")),
    MR004: new TextValueEnum("mr004", t("MR-004")),
    MR005: new TextValueEnum("mr005", t("MR-005")),
    OTHER: new TextValueEnum("other", t("Autre")),
    NOT_APPLICABLE: new TextValueEnum("not_applicable", t("Ne s'applique pas")),
    UNKNOWN: new TextValueEnum("unknown", t("Ne sais pas"))
};

/**
 * List of project framework in workspace form for workspaces used in eds mode.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const ProjectFramework = {
    MR003: new TextValueEnum("thesis", t("Thèse / Memoire")),
    MR004: new TextValueEnum("research", t("Recherche")),
    MR005: new TextValueEnum("indicator", t("Indicateur")),
    OTHER: new TextValueEnum("other", t("Autre"))
};


/**
 * List of duration in workspace form for workspaces used in eds mode.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const Duration = {
    YEAR: new TextValueEnum("year", t("Année")),
    MONTH: new TextValueEnum("month", t("Mois")),
    DAY: new TextValueEnum("day", t("Jour"))
};

/**
 * List of available 3rd party app in workspace used in research mode.
 *
 * @type {Object.<string,string>}
 */
export const IntegratedAppsChoices = {
    JUPYTER: "JUPYTER",
    ANONYMOUS: "ANONYMOUS",
};

/**
 * List of information status for patients
 *
 * @type {Object.<string,string>}
 */
export const InformationStatus = {
    INFORMED: new TextValueEnum("informed", t("Informé de l'existence de la plateforme de transparence")),
    NOT_INFORMED: new TextValueEnum("not_informed", t("Non informé de la plateforme de transparence")),
    NO_CONTACT_INFORMATION: new TextValueEnum("no_contact_information", t("Sans information de contact")),
    DECEASED: new TextValueEnum("deceased", t("Décédé"))
};

/**
 * List of choices for data_analysis_type form value
 *
 * @type {Object.<string,string>}
 */
export const DataAnalysisType = {
    LANGUAGE_TREATMENT: new TextValueEnum("language_treatment", t("Traitement automatique du langage (TAL / NLP)")),
    MUTUAL_ANALYSIS: new TextValueEnum("mutual_analysis", t("Analyse manuelle")),
    NOT_APPLICABLE: new TextValueEnum("not_applicable", t("Ne s'applique pas"))
};

/**
 * List of choices for external_associate_type form value
 *
 * @type {Object.<string,string>}
 */
export const ExternalAssociateType = {
    EPSE: new TextValueEnum("epse", t("EPSE")),
    HEALTH_FACILITY: new TextValueEnum("health_facility", t("Établissement de santé")),
    STARTUP: new TextValueEnum("startup", t("Startup")),
    PHARMACEUTICAL: new TextValueEnum("Startup", t("Industrie pharmaceutique")),
    PUBLIC_PARTNER: new TextValueEnum("other_public_partner", t("Autre partenaire public")),
    PRIVATE_PARTNER: new TextValueEnum("other_private_partner", t("Autre partenaire privé"))
};


/**
 * List of choices for research_objectives form value
 *
 * @type {Object.<string,string>}
 */
export const ResearchObjectives = {
    DIAGNOSTICS: new TextValueEnum("diagnostics", t("Diagnostics")),
    PREVENTION_TREATMENT: new TextValueEnum("prevention_treatment", t("Prévention et traitement")),
    PATIENT_MANAGEMENT: new TextValueEnum("patient_management", t("Prise en charge des patients")),
    PATIENT_SAFETY: new TextValueEnum("patient_safety", t("Sécurité des patients")),
    ORGANIZATION_HEALTH_FACILITIES: new TextValueEnum("organization_health_facilities", t("Organisation des établissements de santé")),
    PUBLIC_HEALTH_POLICIES: new TextValueEnum("public_health_policies", t("Politiques publiques de santé")),
    UNDERSTANDING_DISEASES: new TextValueEnum("understanding_diseases", t("Compréhension des maladies")),
    OTHER: new TextValueEnum("other", t("Autre"))
};

/**
 * List of choices for medical_area form value
 *
 * @type {Object.<string,string>}
 */
export const MedicalArea = {
    CANCERS: new TextValueEnum("cancers", t("Cancers")),
    CARDIOVASCULAR_DISEASES: new TextValueEnum("cardiovascular_diseases", t("Maladies cardio-vascualaires")),
    DIABETES: new TextValueEnum("diabetes", t("Diabète")),
    NEURODEGENERATIVE_DISEASES: new TextValueEnum("neurodegenerative_diseases", t("Maladies neurodégénératives")),
    ORGANIZATION_HEALTH_FACILITIES: new TextValueEnum("organization_health_facilities", t("Santé mentale et Psychiatrie")),
    MENTAL_HEALTH_PSYCHIATRY: new TextValueEnum("mental_health_psychiatry", t("Périnatalité et Santé reproductive")),
    RESPIRATORY_DISEASES: new TextValueEnum("respiratory_diseases", t("Maladies respiratoires")),
    INFECTIOUS_DISEASES: new TextValueEnum("infectious_diseases", t("Maladies infectieuses")),
    CHILDREN_PEDIATRICS: new TextValueEnum("children_pediatrics", t("Enfants et Pédiatrie")),
    ELDERLY_GERIATRICS: new TextValueEnum("elderly_geriatrics", t("Personnes âgées et Gériatrie")),
    OTHER: new TextValueEnum("other", t("Autre"))
};

/**
 * List of questions type for workspaces used in eds mode.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const wksFormQuestionType = {
    UNI_SELECT: new TextValueEnum('uni_select', t('Choix unique'), 'UniSelectField'),
    MULTI_SELECT: new TextValueEnum('multi_select', t('Choix multiple'), 'MultiSelectField'),
    DATE_FIELD: new TextValueEnum('date_field', t('Date'), 'DateField'),
    TEXT_AREA: new TextValueEnum('text_area', t('Champ texte'), null),
};

/**
 * List of project type to know if user need workspace
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const ProjectType = {
    WITH_EDS: new TextValueEnum('with_eds', t('Projet avec espace de travail')),
    WITHOUT_EDS: new TextValueEnum('without_eds', t('Projet sans espace de travail'))
};

/**
 * List of choices to display opposed patients in opposition table
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const WorkspaceChoice = {
    WITH_EDS: new TextValueEnum('with_eds', t('Projet avec espace de travail')),
    WITHOUT_EDS: new TextValueEnum('without_eds', t('Projet sans espace de travail'))
};

export const WorkspaceFormVisibility = [
    {
        value: "research_objective",
        category: "general_information",
        question: "La recherche porte sur le ou les objectif(s) suivant(s)"

    },
    {
        value: "medical_area",
        category: "general_information",
        question: "Domaine médical"
    },
    {
        value: "approach",
        category: "general_information",
        question: "Démarche"
    },
    {
        value: "population",
        category: "general_information",
        question: "Population concernée"
    },
    {
        value: "data_category_used",
        category: "general_information",
        question: "Catégorie des données utilisées"
    },
    {
        value: "project_date",
        category: "study_calendar",
        question: "Date du projet"
    },
    {
        value: "estimated_project_duration",
        category: "study_calendar",
        question: "Durée estimée du projet"
    },
    {
        value: "data_collection_period",
        category: "study_calendar",
        question: "Période de collecte des données"
    },
    {
        value: "publication_date",
        category: "study_calendar",
        question: "Publication"
    },
    {
        value: "data_analysis_date",
        category: "study_calendar",
        question: "Analyse des données"
    },
    {
        value: "use_riph",
        category: "access_eds_information",
        question: "Votre projet est-il une recherche impliquant la personne humaine (RIPH)"
    },
    {
        value: "method_reference",
        category: "access_eds_information",
        question: "Le traitement est-il conforme à une méthodologie de référence ?"
    },
    {
        value: "project_framework",
        category: "access_eds_information",
        question: "Quel est le cadre de ce projet ?"
    },
    {
        value: "keywords",
        category: "description_scientific_information",
        question: "Mots-clés"
    },
    {
        value: "study_context",
        category: "description_scientific_information",
        question: "Contexte de l'étude"
    },
    {
        value: "objectives",
        category: "description_scientific_information",
        question: "Objectif"
    },
    {
        value: "design",
        category: "description_scientific_information",
        question: "Design"
    },
    {
        value: "inclusion_criteria",
        category: "description_scientific_information",
        question: "Critère d'inclusion"
    },
    {
        value: "patients_origin",
        category: "description_scientific_information",
        question: "Provenance des patients"
    },
    {
        value: "judgement_criterion",
        category: "description_scientific_information",
        question: "Critère de jugement"
    },
    {
        value: "analysis_method",
        category: "description_scientific_information",
        question: "Méthode d'analyse"
    },
    {
        value: "justification_subjects_number",
        category: "description_scientific_information",
        question: "Justification du nombre de sujets "
    },
    {
        value: "research_interest",
        category: "description_scientific_information",
        question: "Bénéfice/Intérêt de la recherche"
    },
    {
        value: "bibliography",
        category: "description_scientific_information",
        question: "Bibliographie"
    },
    {
        value: "funding",
        category: "description_scientific_information",
        question: "Financement de l'étude"
    },
    {
        value: "extracted_data_host",
        category: "description_scientific_information",
        question: "Seules des données anonymisées peuvent sortir de l’EDS et la solution d’hébergement externe doit être connue et précisée. Les données seront-elles extraites de l’EDS et de ses espaces de travail?"
    },
    {
        value: "treatment",
        category: "description_scientific_information",
        question: "L’exploitation des données des comptes rendus médicaux peut nécessiter une expertise en informatique médicale."
    },

];
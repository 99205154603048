import {TextValueEnum} from "@/utils/enums";

export const H_ORIENTATION = 'h';
export const TYPE_SCATTER = 'scatter';
export const TYPE_BAR = 'bar';
export const TYPE_PIE = 'pie';
export const TYPE_HISTOGRAM = 'histogram';

export const StackingMode = {
    NONE: new TextValueEnum('group', 'Groupé'),
    STACKED: new TextValueEnum('stack', 'Empilé'),
};


export const ChartTypes = {
    LineChart: {
        value: 'line_chart',
        icon: 'mdi-chart-line',
        type: TYPE_SCATTER,
        mode: 'lines+markers',
        text: 'Courbes',
        label_axis: 'x'
    },
    ScatterChart: {
        value: "scatter_chart",
        icon: 'mdi-chart-scatter-plot',
        type: TYPE_SCATTER,
        mode: 'markers',
        text: 'Nuage de points',
        label_axis: 'x'
    },
    BarChart: {
        value: "bar_chart",
        icon: 'mdi-chart-bar',
        type: TYPE_BAR,
        text: 'Barres',
        label_axis: 'x',
        data_axis: 'y'
    },
    HorizontalBarChart: {
        value: "horizontal_bar_chart",
        icon: 'mdi-chart-bar',
        type: TYPE_BAR,
        text: 'Barres horizontales',
        label_axis: 'y',
        data_axis: 'x',
        orientation: H_ORIENTATION,
    },

    PieChart: {
        value: "pie_chart",
        icon: 'mdi-chart-pie',
        type: TYPE_PIE,
        text: 'Diagramme circulaire',
        label_axis: 'y',
    },

    HistogramChart: {
        value: "histogram_chart",
        icon: 'mdi-chart-histogram',
        type: TYPE_HISTOGRAM,
        text: 'Histogramme',
        label_axis: 'y',
        data_axis: 'x',
    }
};

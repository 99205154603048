<template>
  <div
    class="text-center py-4 mx-6"
    :class="{'notification-border-top': !first, 'clickable': clickableNotification}"
    @mouseover="displayDismiss = true"
    @mouseleave="displayDismiss = false"
    @click="redirection"
  >
    <div class="row g-0">
      <div class="col-2 mt-1">
        <div class="notification-icon d-inline-flex align-center justify-content-center bg-basics-10">
          <icon :name="icon" color="basics-90" :outline="notification.type === 'async_export_cohort'" />
        </div>
      </div>
      <div class="col text-left pl-2">
        <div class="d-flex my-auto w-100 justify-content-between">
          <span class="text-body-2 notification-title two-line-max-content"
                v-html="notification.subject"
          />
          <v-btn :class="{'invisible' : !displayDismiss}" x-small icon :loading="loading"
                 @click.stop="dismissNotification"
          >
            <icon name="close" outline size="14" />
          </v-btn>
        </div>

        <div class="d-flex align-center mt-1">
          <badge :text="badgeContent.text" :type="badgeType" class="mr-2" />
          <span class="text-caption basics-70 one-line-max-content">
            {{ getDiff(notification.update_date) }}
          </span>
        </div>
        <div v-if="isLongTextNotification">
          <span class="text-caption" v-html="badgeContent.info" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'NotificationCard',

  props: {
    notification: {
      type: Object,
      required: true
    },

    first: {
      type: Boolean
    }
  },

  data() {
    return {
      displayDismiss: false,
      loading: false
    };
  },

  computed: {
    badgeType () {
      let type = null;
      switch (this.notification.status) {
        case "pending": case "cancelled":
          type = 'outlined';
          break;
        case "success":
          type = 'success';
          break;
        case "error":
          type = 'danger';
          break;
      }
      return type;
    },

    badgeContent() {
      const content = this.notification.content.trim().split(/ (.+)/);
      if (this.isLongTextNotification) {
        return {text: content[0], info: content[1]};
      } else {
        return {text: this.notification.content};
      }
    },

    icon () {
      let icon = null;
      switch (this.notification.type) {
        case "feed_cohort": case "feed_workspace":
          icon = 'settings-slider';
          break;
        case "app_update" || "app_expected_maintenance":
          icon = 'megaphone';
          break;
        case "update_user_access":
          icon = "users";
          break;
        case "search_result":
          icon = 'search';
          break;
        case "stop_workspace": case "start_workspace":
          icon = 'server-connect';
          break;
        case "cohort_periodic_feed":
          icon = 'calendar';
          break;
        case "async_export_cohort":
          icon = 'upload';
          break;
        case "async_autocomplete_sde":
          icon = 'number-list';
          break;
      }
      return icon;
    },

    clickableNotification() {
      const ignoredNotificationTypes = [
        "app_update", "app_expected_maintenance", "update_user_access", "search_result"
      ];
      return !ignoredNotificationTypes.includes(this.notification.type);
    },

    isLongTextNotification() {
      return (this.notification.type === "cohort_periodic_feed" ||
          this.notification.type === "async_autocomplete_sde") &&
          this.notification.status === "success";
    }
  },

  methods: {
    getDiff (date) {
      return moment(date).fromNow();
    },

    dismissNotification() {
      if (this.notification.dismissed) {
        return;
      }
      this.loading = true;
      this.$emit('dismiss', this.notification.id);
    },

    redirection() {
      if (this.notification.object_id ) {
        switch(this.notification.type) {
          case "feed_cohort": case "cohort_periodic_feed": case "async_export_cohort":
            if (this.$route.name !== "Cohort instance" || this.notification.object_id.toString() !== this.$route.params?.cohortId) {
              this.goToTarget("Cohort instance", "cohortId", this.$route.name === "Cohort instance");
            }
            break;
          case "feed_workspace": case "stop_workspace": case "start_workspace":
            if (this.$route.name !== "WorkspaceDetail" || this.notification.object_id !== this.$route.params?.workspace_id) {
              this.goToTarget("WorkspaceDetail", "workspace_id", this.$route.name === "WorkspaceDetail");
            }
            break;
          case "async_autocomplete_sde":
            if (this.$route.name !== "SdeDetail" || this.notification.object_id !== this.$route.params?.sde_id) {
              this.goToTarget("SdeDetail", "sde_id", this.$route.name === "SdeDetail");
            }
            break;
        }
      }
    },

    goToTarget(name, id, refresh) {
      this.$router.push({
        name: name,
        params: {[id]: id === "cohortId" ? this.notification.object_id.toString() : this.notification.object_id}
      }).then(() => {
        if (refresh) {
          this.$router.go(0);
        }
      });
    }
  }
};
</script>

<style>
.notification-icon {
  width: 44px;
  height: 44px;
  box-shadow: 0 0 1px rgba(19, 34, 57, 0.24), 0 1px 3px rgba(19, 34, 57, 0.16), inset 0 -2px 0 rgba(19, 34, 57, 0.02), inset 0 -1px 3px rgba(19, 34, 57, 0.04);
  border-radius: 4px;
}

.notification-title {
  letter-spacing: -0.2px;
}

.notification-border-top {
  border-top: 1px solid var(--basics-50);
}
</style>

<template>
  <v-container class="d-flex flex-column mt-8">
    <v-row class="flex-grow-0">
      <v-col cols="11" class="p-0 ml-3">
        <patient-search-bar
          class="flex-grow-1 mr-2"
          :placeholder="t('Rechercher un patient (nom, prénom, ipp, date de naissance)')"
          :redirect="false"
          :light-text-color="true"
          @select="setPatients"
        />
      </v-col>
      <div>
        <v-tooltip bottom open-delay="200" content-class="arrow-top">
          <template #activator="{ on, attrs }">
            <v-btn icon-only class="primary" v-bind="attrs" v-on="on" @click="displayModal = true">
              <icon name="import" outline color="basics-10" />
            </v-btn>
          </template>
          {{ t('Modifier plusieurs statuts à partir d\'un CSV') }}
        </v-tooltip>
      </div>
    </v-row>
    <v-row v-if="patients.length < 1" class="my-auto flex-column align-center">
      <v-col class="flex-grow-0 my-auto h-100">
        <v-row class="flex-column align-center">
          <img :src="noResultImg" alt="">
          <p class="text-subtitle-1 font-demi-bold mb-3 mt-10">
            {{ t('Rechercher un patient à l\'aide de :') }}
          </p>
          <p class="text-body-2 basics-90" v-html="t(noResultText)" />
        </v-row>
      </v-col>
    </v-row>
    <patient-detail v-if="patients.length > 0" :key="patients[index]" :patient-id="patients[index]" :update-info="updateInfo" />
    
    <update-patients-modal :dialog-import.sync="displayModal" :confirm-import="confirmImport"
                           @get-patients="updateInfo = !updateInfo"
                           @close="displayModal = false"
    />
  </v-container>
</template>

<script>
import PatientDetail from "@/views/gddi/patients/PatientDetail";
import PatientSearchBar from "@/components/commons/PatientSearchBar";
import i18n from "@/mixins/i18n";
import UpdatePatientsModal from "@/views/gddi/patients/UpdatePatientsModal";

export default {
  name: 'Patients',
  components: {PatientDetail, PatientSearchBar, UpdatePatientsModal},
  mixins: [i18n],

  data() {
    return {
      patients: [],
      noResultImg: require('@/assets/img/NoResultSearch.svg'),
      noResultText:
          'Son <span class="font-demi-bold">nom</span>, ' +
          'son <span class="font-demi-bold">prénom</span>, ' +
          'son <span class="font-demi-bold">identifiant</span>, ' +
          'sa <span class="font-demi-bold">date de naissance</span>, ' +
          'son <span class="font-demi-bold">mail</span>, ' +
          'son <span class="font-demi-bold">adresse</span>',

      index: 0,
      updateInfo: true,
      displayModal: false,
      confirmImport: false,
    };
  },

  mounted() {
   if (this.$route.params.patient_id) {
      this.patients = [Number(this.$route.params.patient_id)];
    }
  },

  methods: {
    setPatients(patients) {
      if (typeof(patients) !== "object") {
        patients = [patients];
      }
      this.patients = patients;
    },
  }
};
</script>

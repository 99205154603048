export default {
    namespaced: true,
    state: {
        hotData: null,
        colHeaders: [],
    },
    mutations: {
        updateData(state, hotData) {
            state.hotData = hotData;
        },
        setColHeaders(state, colHeaders) {
            state.colHeaders = colHeaders;
        }
    },
    getters: {

    }
};
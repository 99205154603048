const DWH_WS_URL = process.env.VUE_APP_DWH_WS_URL;

export default {
  data() {
    return {
      webSockets: {},
    };
  },

  methods: {
    initializeWebSocket(endpoint, token, onConnection, onMessage) {
      this.webSockets[endpoint] = new WebSocket(
        DWH_WS_URL + "/ws/" + endpoint + "/?access_token=" + token
      );

      this.webSockets[endpoint].onopen = () => {
        this.webSockets[endpoint].send(JSON.stringify(onConnection));
      };
      this.webSockets[endpoint].onmessage = onMessage;
    },

    sendWebSocketMessage(endpoint, type, message) {
      this.webSockets[endpoint].send(
        JSON.stringify({
          type: type,
          ...message,
        })
      );
    },

    closeWebSocketSession(endpoint) {
      this.webSockets[endpoint].close();
    },
  },
};

import i18n from "@/mixins/i18n";
const t = i18n.methods.t;

const passwordFormatError = t('Le mot de passe doit contenir minimum 10 caractères, dont 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.');

let requiredRule = [v => !!v || t('Champs requis')];
let boolRequiredRule = [v => v !== null || t('Champs requis.')];

let requiredRuleArray = [v => (!!v && v.length > 0) || t('Champs requis.')];

let emailRule = [
    v => (!v || /.+@.+\..+/.test(v)) || t('L\'e-mail est invalide.'),
];

let passwordRule = [
    v => (!v || (v.length > 9 && v.toUpperCase() !== v && v.toLowerCase() !== v && /\d/.test(v) && /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(v))) || passwordFormatError,
];

function confirmPasswordRule(password, v) {
    return (!v && !password) || password === v ? true : t('Mots de passe différents.');
}

function sameOldPassword(password, v) {
    return password !== v ? true : "Le nouveau mot de passe doit être différent de l'actuel.";
}

function emailNotInList(emailList, v) {
    return (!!v && emailList.includes(v)) ? t('Cet e-mail est déjà utilisé.') : true;
}

export default {
    data() {
        return {
            boolRequiredRule,
            requiredRule,
            requiredRuleArray,
            emailRule,
            passwordRule,
            sameOldPassword
        };
    },
    methods: {
        confirmPasswordRule,
        emailNotInList,

        /**
         * Put user at form error
         * @returns {boolean}
         */
        async goToFormError() {
            this.$nextTick(() => {
                const el = this.$el.querySelector('.error--text:first-of-type');
                this.$vuetify.goTo(el);
            });
        },

        maxLengthRule(max_length) {
            return [v => (!v || (v && v.length < max_length)) || t(`Assurez-vous que ce champ comporte au plus ${max_length} caractères.`)];
        }
    }
};

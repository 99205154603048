import {ChartTypes} from "@/enums/chart";
import {ExportChart, DocumentsStats} from "@/services/api";
import moment from "moment";

export default {
    namespaced: true,
    state: {
        charts: [],
        fileId: null,
        rangeSelector:
            {buttons: [
                {
                    count: 1,
                    label: '1m',
                    step: 'month',
                    stepmode: 'backward'
                },
                {
                    count: 6,
                    label: '6m',
                    step: 'month',
                    stepmode: 'backward'
                },
                {
                    count: 1,
                    label: 'YTD',
                    step: 'year',
                    stepmode: 'todate'
                },
                {
                    count: 1,
                    label: '1y',
                    step: 'year',
                    stepmode: 'backward'
                },
                {step: 'all'}
            ]}
    },
    mutations: {
        setCharts(state, charts) {
            state.charts = charts;
        },
        setFileId(state, fileId) {
            state.fileId = fileId;
            state.charts = [];
        },
        create(state, chart) {
            state.charts.push(chart);
        },
        update(state, {index, chart}) {
            state.charts[index] = chart;
        },
        destroy(state, index) {
            state.charts.splice(index, 1);
        },
        updateType(state, {index, value}) {
            state.charts[index].type = value;
        },
        updateSeries(state, {index, series}) {
            state.charts[index].data.series = JSON.parse(JSON.stringify(series));
        },
        updateAxis(state, {index, axis}) {
            state.charts[index].data.axis = axis;
        },
        updateLayout(state, {index, layout}) {
            state.charts[index].layout = JSON.parse(JSON.stringify(layout));
        }
    },
    actions: {
        list({state, commit}) {
            return ExportChart.list({export: state.fileId}).then(response => {
                commit('setCharts', response.data.results);
            });
        },
        create({state, commit}) {
            let chart = {
              title: 'Nouveau graphique',  // TODO translation ?
              export: state.fileId,
              type: ChartTypes.LineChart.value,
              data: {
                  axis: '',
                  series: [{data: '', name:''}],
              },
              layout: {
                  margin: {l: 50, r:50, b:70, t:10, pad:4},
                  xaxis: {title: ''},
                  yaxis: {title: ''}
              }
            };
            return ExportChart.create(chart).then(response => {
              commit('create', response.data);
            });
        },
        destroy({state, commit}, index) {
            let id = state.charts[index].id;
            return ExportChart.destroy(id).then(() => {
                commit('destroy', index);
            });
        },
        update({state, commit}, {index, chart}) {
            let id = state.charts[index].id;
            return ExportChart.update(id, chart).then(() => {
                commit('update', {index, chart});
            });
        },
        bulk_update({state, commit}) {
            if (state.charts.length > 0) {
                return ExportChart.bulk_update(state.charts).then(response => {
                    commit('setCharts', response.data);
                });
            }
            return Promise.resolve();
        },

        setOriginByYearData({commit}) {
            let params = {};
            params.end_year = moment().format('YYYY');
            params.start_year = moment().subtract(20, 'years').format('YYYY');
            return DocumentsStats.getNbDocsByOriginByYear(params).then(response => {
                commit('create', {name: 'document-nbByOriginByYear', data: response.data.length > 0 ? response.data : null});
            });
        },
    },
    getters: {
        getChartTypeByIndex: (state) => (index) => {
            return Object.values(ChartTypes).find(el => el.value === state.charts[index].type);
        },
        getChartByName: (state) => (name) => {
            return state.charts.find(obj => obj.name === name);
        },
        getAxisByIndex: (state) => (index) => {
            return state.charts[index].data.axis ? state.charts[index].data.axis : '';
        },
        getSeriesByIndex: (state) => (index) => {
            return state.charts[index].data.series ? state.charts[index].data.series : [''];
        },
        getLayoutByIndex: (state) => (index) => {
            index = !index ? 0 : index;
            return state.charts[index].layout ? state.charts[index].layout : {};
        },
        getRangeSelector: state => state.rangeSelector
    }
};
import { Cohorts } from "@/services/api";

const state = () => ({
  cohorts: {
    list: [],
    count: 0,
  },
});

// getters
const getters = {
  getCohorts: (state) => state.cohorts,
};

// mutations
const mutations = {
  setCohorts(state, cohorts) {
    state.cohorts.list = cohorts.results;
    state.cohorts.count = cohorts.count;
  },

  updateCohort(state, cohort) {
    const list = state.cohorts.list.find((obj) => obj.id === cohort.id);
    Object.assign(list, cohort);
  },

  createCohort(state, cohort) {
    state.cohorts.list.unshift(cohort);
    state.cohorts.count += 1;
  },

  deleteCohort(state, index) {
    state.cohorts.list.splice(index, 1);
    state.cohorts.count -= 1;
  },
};

// actions
const actions = {
  getCohortsList({ commit }, params) {
    return Cohorts.list(params).then((response) => {
      commit("setCohorts", response.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

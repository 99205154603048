import authHeader from "@/services/auth/auth.header";
import {Api, authRequest} from "@/services/service";


export class ExportDetailApi {
    constructor(resource) {
        this.resource = resource;
        this.url_list = `/${this.resource}/`;
    }

    async retrieve(params) {
        return await authRequest.get(this.url_list, {headers: authHeader(), params: params});
    }

    async update(data) {
        return await authRequest.put(this.url_list, data, {headers: authHeader()});
    }

    async destroy(params) {
        return await authRequest.delete(this.url_list, {headers: authHeader(), params: params});
    }

}

export class ExportChartApi extends Api {
    async bulk_update(data) {
        const url = this.url_list + '_bulk/';
        return await authRequest.put(url, data, {headers: authHeader()});
    }
}
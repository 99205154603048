// APPS
export const APPS = {
    CARE: {
        title: 'Consulter les données de mes patients',
        url: process.env.VUE_APP_CARE_REDIRECT_URL,
        description: 'Identifiez-vous pour réaliser des recherches sur les données de votre périmètre de soin ' +
          'et étudier des dossiers patient.',
    },
    EDS: {
        title: 'Réaliser une demande de projet de recherche',
        url: process.env.VUE_APP_EDS_REDIRECT_URL,
        description: 'Identifiez-vous pour réaliser une recherche sur l’ensemble de l’entrepôt ' +
          'et faire une demande d’accès à un jeu de données.',
    },
    GDDI: {
        title: 'Gérer l’information et les oppositions des patients',
        url: process.env.VUE_APP_GDDI_REDIRECT_URL,
        description: 'Identifiez-vous pour accéder à l’administration des données directement identifiantes des patients. ' +
          'Seul des profils avec une habilitation spécifique ont accès à cette fonctionnalité.',
    },
    STANDALONE: {
        title: 'Réaliser une demande de projet de recherche',
        url: process.env.VUE_APP_EDS_REDIRECT_URL,
        description: 'Identifiez-vous pour réaliser vos demandes de projet de recherche.',
    },
    RESEARCH: {
        description: 'Identifiez-vous pour réaliser vos projets de recherche clinique sur un espace de travail sécurisé' +
          ' et construit à partir de votre EDS en respectant la réglementation CNIL.'
    }
};

// APP MODE
export const MODE = {
    CARE: 'CARE',
    GDDI: 'GDDI',
    EDS: 'EDS',
    // ADMIN: 'ADMIN',
    RESEARCH: 'RESEARCH',
};

// CODOC SUITE
export const SUITE = {
    RESEARCH_HUB_TRANSPARENCY: {
        name: 'research_hub_transparency',
        favicon: 'DrWarehouse_favicon.png',
        tab_title: 'codoc Research Hub',
        layout: [
            {
                logo: 'ResearchHub_Solid.svg',
                logoWidth: '180',
                title: '<span class="semi-highlight-1">Accélérez</span> la réutilisation des données de santé',
                apps: [
                    APPS.CARE,
                    APPS.EDS
                ]
            },
            {
                logo: 'Transparency.svg',
                logoWidth: '160',
                title: '<span class="semi-highlight-1">Facilitez</span> la gestion des droits',
                apps: [
                    APPS.GDDI
                ],
                module: true
            },
        ]
    },

    RESEARCH_HUB: {
        name: 'research_hub',
        favicon: 'DrWarehouse_favicon.png',
        tab_title: 'codoc Research Hub',
        layout: [
            {
                logo: 'ResearchHub_Solid.svg',
                logoWidth: '180',
                title: '<span class="semi-highlight-1">Accélérez</span> la réutilisation des données de santé',
                apps: [
                    APPS.CARE,
                    APPS.EDS,
                    APPS.GDDI
                ]
            }
        ]
    },

    TRANSPARENCY_STANDALONE: {
        name: 'transparency_standalone',
        favicon: 'DrWarehouse_favicon.png',
        tab_title: 'codoc Transparency',
        layout: [
            {
                logo: 'Transparency.svg',
                logoWidth: '160',
                title: '<span class="semi-highlight-1">Facilitez</span> la gestion des droits',
                apps: [
                    APPS.GDDI,
                    APPS.STANDALONE
                ],
                module: true
            }
        ]
    },

    CARE_ONLY: {
        name: 'care_only',
        favicon: 'DrCare_favicon.png',
        tab_title: 'codoc Research Hub | care',
        layout: [
            {
                favicon: 'DrCare_favicon.png',
                tab_title: '',
                logo: 'ResearchHub-Care.svg',
                logoWidth: '220',
                title: '<span class="semi-highlight-1">Accélérez</span> la réutilisation des données de santé',
                apps: [
                    APPS.CARE
                ]
            }
        ]
    },
};

import Vue from "vue";
import {SDE, SDECategory, SDEForm, SDESummary} from "@/services/api";
import { CriterionType } from "@/views/commons/search/utils/enums";
import sdeUtils from "@/mixins/sdeUtils";

const state = () => ({
  information: null,
  informationLoading: false,
  form: [
    {
      id: null,
      questions: [
        {
          sub_questions: [],
        },
      ],
    },
  ],
  selectedPatient: null, //"1", // TODO set null
  selectedPatientIndex: "0",
  selectedQuestion: {},
  isQuestion: false,
  selectedCategory: {
    question_count: 0,
    sub_question_count: 0,
    answer_count: 0,
    validated_answer_count: 0,
  },

  queryModel: {
    type: CriterionType.GROUP,
    cid: "A",
    name: null,
    color: "#FFF0BB",
    operator: 2,
    criteria: [],
    constraints: [],
    showPopulationPanel: false,
    showAddCriteriaPanel: true,
    showTimeConstraintPanel: true,
  },

  openedItem: null,
  reload: null,
  saveLoader: false,

  // Global loading
  categoryIsSet: false,
  questionsAnswersIsSet: false,
  caretNavigationIsSet: false,
  summary: [],
  sdeList: {
    loading: false,
    count: 0,
    list: []
  }
});

// getters
const getters = {
  form: (state) => state.form,
  summary: (state) => state.summary,
  reload : (state) => state.reload,

  queryModel: (state) => state.queryModel,
  information: (state) => state.information,
  informationLoading: (state) => state.informationLoading,
  openedItem: (state) => state.openedItem,
  selectedPatientId: (state) => state.selectedPatient?.id.toString(),
  selectedPatient: (state) => state.selectedPatient,
  selectedPatientIndex: (state) => state.selectedPatientIndex,
  selectedQuestion: (state) => state.selectedQuestion,
  isQuestion: (state) => state.isQuestion,
  selectedCategory: (state) => state.selectedCategory,
  questionsAnswersIsSet: (state) => state.questionsAnswersIsSet,
  caretNavigationIsSet:(state) => state.caretNavigationIsSet,
  list: (state) => state.sdeList
};

// mutations
const mutations = {
  setReload(state, id) {
    state.reload = id;
  },

  setOpenedItem(state, items) {
    state.openedItem = items;
  },

  setInformation(state, sde) {
    state.information = sde;
  },

  setInformationLoading(state, loading) {
    state.informationLoading = loading;
  },

  setSelectedPatient(state, patient) {
    state.selectedPatient = patient;
  },

  setSelectedPatientIndex(state, index) {
    state.selectedPatientIndex = index;
  },

  setSelectedQuestion(state, question) {
    state.selectedQuestion = question;
  },

  setIsQuestion(state, isQuestion){
    state.isQuestion = isQuestion;
  },

  setSelectedCategory(state, category) {
    state.selectedCategory = category;
  },

  setSelectedCategoryAnswerCount(state, count) {
    state.selectedCategory.validated_answer_count = count;
  },
  
  setForm(state, form) {
    state.form = form;
  },

  updateCategoryCount(state, category) {
    Vue.set(state.selectedCategory, 'question_count', category.question_count);
    Vue.set(state.selectedCategory, 'validated_answer_count', category.validated_answer_count);
    Vue.set(state.selectedCategory, 'sub_question_count', category.sub_question_count);
  },

  setQuestionsAnswersIsSet(state, bool) {
    state.questionsAnswersIsSet = bool;
  },

  setCaretNavigationIsSet(state, bool) {
    state.caretNavigationIsSet = bool;
  },

  setSummary(state, summary) {
    state.summary = summary;
  },

  setList(state, data) {
    state.sdeList.count = data.count;
    state.sdeList.list = data.list;
  },

  setListLoading(state, loading) {
    state.sdeList.loading = loading;
  }
};

const actions = {
  getForm({ commit }, sde_id) {
    return SDEForm.list(sde_id)
      .then((response) => {
        response.data.results.map((item) => {
          item.questions = item.questions ? item.questions : [];
        });

        response.data.results.forEach((category) =>
          category.questions.forEach((question) => {
            sdeUtils.methods.denormalize(question);
            question.sub_questions = question.sub_questions
              ? question.sub_questions
              : [];
          })
        );
        commit("setForm", response.data.results);
      })
      .catch((error) => {
        commit("snackbar/apiErrorSnackbar", error, { root: true });
      });
  },

  getSDE({ commit }, sde_id) {
    return SDE.retrieve(sde_id).then((response) => {
      commit("setInformation", response.data);
    }).catch((error) => {
      commit("snackbar/apiErrorSnackbar", error, { root: true });
    });
  },

  getSummary({ commit }, params) {
    return SDESummary.list(params).then((response) => {
        if (params.new) {
          response.data.results[response.data.results.length - 1].newCategory = true;
        }
        commit("setSummary", response.data.results);
      })
      .catch((error) => {
        commit("snackbar/apiErrorSnackbar", error, { root: true });
      });
  },

  createCategory({ state, commit }, category) {
    category.order = state.summary.length;
    return SDECategory.create(category).then(response => {
      commit("setOpenedItem", {category: response.data.id});
    }).catch((error) => {
      commit("snackbar/apiErrorSnackbar", error, { root: true });
    });
  },

  getSdeList({ commit }, params) {
    commit("setListLoading", true);
    SDE.list(params).then((response) => {
      response.data.results.forEach((sde) => {
        if (sde.validated_answer_count > 0) {
          sde.completion_percent = Math.ceil(
            (sde.validated_answer_count /
              ((sde.question_count + sde.sub_question_count) *
                sde.patient_count)) *
            100
          );
        }
      });
      commit("setList", {list: response.data.results, count: response.data.count});
    }).catch((error) => {
      commit("snackbar/apiErrorSnackbar", error, { root: true });
    }).finally(() => {
      commit("setListLoading", false);
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { TextValueEnum } from "@/utils/enums";

/**
 * List of default typology for Thesauri and Sources.
 *
 * @type {Object.<string,TextValueEnum>}
 */
export const DefaultParameterType = {
    PRESCRIPTION: new TextValueEnum("Prescription", "Prescription"),
    BIOLOGY: new TextValueEnum("Biologie", "Biologie"),
    DIAGNOSTIC: new TextValueEnum("Diagnostic", "Diagnostic"),
    ACT: new TextValueEnum("Acte", "Acte"),
    PMSI: new TextValueEnum("PMSI", "PMSI"),
};

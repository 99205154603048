import {DepartmentsStatsYears, UnitsStatsYears, StaysStatsYears, CohortAlimRequests} from "@/services/api";

const state = () => ({
  cohortId: null,
  staysYears: [],
  departmentsYears: [],
  unitsYears: [],
  loadingDptYears: false,
  loadingUnitsYears: false,
  patientDataLoading: false,
  alimRequests: [],
  alimRequestsTitles: [],
  currentTabMenu: {}
});

// getters
const getters = {
  cohortId: state => state.cohortId,
  staysYears: state => state.staysYears,
  departmentsYears: state => state.departmentsYears,
  unitsYears: state => state.unitsYears,
  loadingDptYears: state => state.loadingDptYears,
  loadingUnitsYears: state => state.loadingUnitsYears,
  alimRequests: state => state.alimRequests,
  alimRequestsTitles: state => state.alimRequestsTitles,
  currentTabMenu: state => state.currentTabMenu,

  // Global loading for DP
  patientDataLoading: state => state.patientDataLoading
};

// mutations
const mutations = {
  setCohortId(state, id) {
    state.cohortId = id;
  },

  setStaysYears(state, years) {
    state.staysYears = years;
  },

  setDepartmentsYears(state, years) {
    state.departmentsYears = years;
  },

  setUnitsYears(state, years) {
    state.unitsYears = years;
  },

  setLoadingDptYears(state, bool) {
    state.loadingDptYears = bool;
  },

  setLoadingUnitsYears(state, bool) {
    state.loadingUnitsYears = bool;
  },

  setPatientDataLoading(state, bool) {
    state.patientDataLoading = bool;
  },

  setAlimRequests(state, requests) {
    state.alimRequests = requests;
  },

  setAlimRequestsTitles(state, titles) {
    state.alimRequestsTitles = titles;
  },

  setCurrentTabMenu(state, tab) {
    state.currentTabMenu = tab;
  }
};

// actions
const actions = {
  getStaysYears({state, commit}) {
    return StaysStatsYears.list({cohort_id: state.cohortId}).then(response => {
      commit("setStaysYears", response.data);
    });
  },

  async getDepartmentsYears({state, commit}) {
    commit('setLoadingDptYears', true);
    try {
      const response = await DepartmentsStatsYears.list({cohort_id: state.cohortId}); // Appel à l'API ou traitement approprié
      commit('setDepartmentsYears', response.data);
    } finally {
      commit('setLoadingDptYears', false);
    }
  },

  async getUnitsYears({state, commit}) {
    commit('setLoadingUnitsYears', true);
    try {
      const response = await UnitsStatsYears.list({cohort_id: state.cohortId}); // Appel à l'API ou traitement approprié
      commit('setUnitsYears', response.data);
    } finally {
      commit('setLoadingUnitsYears', false);
    }
  },

  getAlimRequests({state, commit}) {
    return CohortAlimRequests.list(state.cohortId).then(response => {
      commit('setAlimRequests', response.data.results);
    }).catch(error => {
      commit("snackbar/apiErrorSnackbar", error, { root: true });
    });
  },

  getAlimRequestsTitles({state, commit}) {
    return CohortAlimRequests.retrieve(state.cohortId, 'titles').then(response => {
      commit('setAlimRequestsTitles', response.data.values);
    }).catch(error => {
      commit("snackbar/apiErrorSnackbar", error, { root: true });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <v-app-bar
    dense
    app
    clipped-left
    elevation="1"
    color="var(--primary-pressed)"
    class="app-bar"
  >
    <v-img
      :src="logo"
      max-height="25"
      max-width="170"
      contain
      class="ml-2 logo"
      :class="{'clickable': !showEmptyNavBar}"
      @click="goToSearch"
    />

    <tab-list
      v-if="!showEmptyNavBar"
      :items="tabs"
      :navbar="true"
      class="ml-5"
      background-color="var(--primary-pressed)"
      prev-icon="mdi-chevron-left"
      dark
      hide-slider
      @update-tab="tab = $event"
    />
    <div
      v-if="loggedIn"
      class="d-inline-flex justify-end w-100"
    >
      <patient-search-bar
        v-if="hasPatientSearchAccess() && showSearchBar && !showEmptyNavBar"
        class="my-auto"
      />
      <v-btn v-if="!showEmptyNavBar" icon class="ml-2 mr-1 h-auto" @click="showNotification">
        <v-badge
          :content="unReadNotifications"
          :value="unReadNotifications"
          color="red"
          overlap
        >
          <icon name="alarm-bell" color="basics-10" size="24" />
        </v-badge>
      </v-btn>
      <action-menu offset-y min-width="200px" max-width="250">
        <template #activator>
          <v-btn depressed fab icon small class="my-auto">
            <icon name="user-circle" color="basics-10" size="24" />
          </v-btn>
        </template>
        <template #prepend>
          <v-list-item>
            <v-list-item-title class="text-body-2">
              <span class="font-demi-bold basics-100">
                {{
                  user.first_name
                    ? user.first_name.charAt(0).toUpperCase() +
                      user.first_name.toLowerCase().slice(1)
                    : ""
                }}
                {{ user.last_name ? user.last_name.toUpperCase() : "" }}
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!showEmptyNavBar" class="no-hover-color" @click="myAccount">
            <v-list-item-title class="text-body-2">
              <icon
                name="user-circle"
                color="basics-90"
                outline
                size="16"
                class="my-auto mr-1"
              />
              <span class="basics-90">{{ t("Mon compte") }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item class="no-hover-color" @click="logout">
            <v-list-item-title class="text-body-2">
              <icon
                name="logout"
                color="basics-90"
                outline
                size="16"
                class="my-auto mr-1"
              />
              <span class="basics-90">{{ t("Déconnexion") }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-divider
            v-if="
              customSettings.cgu_enabled &&
                (customSettings.cgu ||
                  customSettings.export_policy ||
                  customSettings.confidentiality_policy)
            "
            class="mx-2 my-0"
          />

          <v-list-item
            v-if="customSettings.cgu"
            class="no-hover-color"
            @click="openLink(customSettings.cgu + '?t=' + Date.now())"
          >
            <v-list-item-title>
              <span class="basics-90">{{ t("CGU") }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="customSettings.confidentiality_policy"
            class="no-hover-color"
            @click="openLink(customSettings.confidentiality_policy)"
          >
            <v-list-item-title>
              <span class="basics-90">{{ t("Charte de confidentialité") }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="customSettings.export_policy"
            class="no-hover-color"
            @click="openLink(customSettings.export_policy)"
          >
            <v-list-item-title>
              <span class="basics-90">{{ t("Charte d’export") }}</span>
            </v-list-item-title>
          </v-list-item>
        </template>
      </action-menu>
    </div>
  </v-app-bar>
</template>

<script>
import i18n from "@/mixins/i18n";
import PatientSearchBar from "@/components/commons/PatientSearchBar.vue";
import { mapGetters } from "vuex";
import { hasCohortAccess, hasPatientSearchAccess } from "@/utils/permissions";
import { Perm } from "@/enums/permissions";
import {MODE, SUITE} from "@/config";
import { isLoggedIn } from "@/store/modules/auth";

export default {
  name: "NavBar",
  components: { PatientSearchBar },
  mixins: [i18n],

  props: {
    showEmptyNavBar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      logo: null,
      tab: ""
    };
  },

  computed: {
    ...mapGetters({
      user: "user/me",
      notifications: "notifications/getNotifications",
      displayNotifications: "notifications/displayNotifications",
      unReadNotifications: "notifications/unReadNotifications",
      customSettings: "customApp/getCustomSettings",
    }),

    loggedIn() {
      return isLoggedIn();
    },

    showSearchBar() {
      return this.loggedIn && process.env.VUE_APP_MODE !== MODE.GDDI && this.customSettings.login_page !== SUITE.TRANSPARENCY_STANDALONE.name;
    },

    tabs() {
      let tabs = [];
      if (process.env.VUE_APP_MODE === MODE.CARE) {
        tabs.push(
          {
            name: this.t("Recherche"),
            to: { name: "Search" },
            ref: "search_tab",
            isAllowed: this.can(Perm.AppSearch.value),
          },
          {
            name: this.t("Cohortes"),
            to: { name: "Cohorts" },
            isAllowed: hasCohortAccess(),
          },
          {
            name: this.t("Extraction"),
            to: { name: "Sde" },
            isAllowed: this.can(Perm.AppSDE.value),
          },
          {
            name: this.t("Informations"),
            to: { name: "Dashboard" },
            isAllowed: true,
          },
          {
            name: this.t("Admin"),
            to: { name: "Admin" },
            isAllowed: this.can(Perm.IsAdminCare.value),
          }
        );
      } else if (process.env.VUE_APP_MODE === MODE.EDS && this.customSettings.login_page !== SUITE.TRANSPARENCY_STANDALONE.name) {
        tabs.push(
          {
            name: this.t("Projets"),
            to: { name: "Workspaces" },
            isAllowed: this.can(Perm.AppWorkspace.value),
          },
          {
            name: this.t("Recherche"),
            to: { name: "Search" },
            ref: "search_tab",
            isAllowed: this.can(Perm.AppSearch.value),
          },
          {
            name: this.t("Cohortes"),
            to: { name: "Cohorts" },
            isAllowed: hasCohortAccess(),
          },
          {
            name: this.t("Extraction"),
            to: { name: "Sde" },
            isAllowed: this.can(Perm.AppSDE.value),
          },
          {
            name: this.t("Informations"),
            to: { name: "Dashboard" },
            isAllowed: true,
          },
          {
            name: this.t("Admin"),
            to: { name: "Admin" },
            isAllowed: this.can(Perm.IsAdminEDS.value),
          }
        );
      }  else if (process.env.VUE_APP_MODE === MODE.EDS && this.customSettings.login_page === SUITE.TRANSPARENCY_STANDALONE.name) {
        tabs.push(
          {
            name: this.t("Projets"),
            to: { name: "Workspaces" },
            isAllowed: this.can(Perm.AppWorkspace.value),
          },
          {
            name: this.t("Admin"),
            to: { name: "Admin" },
            isAllowed: this.can(Perm.IsAdminEDS.value),
          }
        );
      } else if (process.env.VUE_APP_MODE === MODE.GDDI) {
        tabs.push(
          {
            name: this.t("Patients"),
            to: { name: "Patients" },
            isAllowed: true,
          },
          {
            name: this.t("Projets"),
            to: { name: "Workspaces" },
            isAllowed: true,
          },
          {
            name: this.t("Oppositions"),
            to: { name: "Oppositions" },
            isAllowed: true,
          }
          //{name: this.t('Plateforme patient'), to: {name: 'PlatformPatient'}, isAllowed: true}
        );
      } else if (process.env.VUE_APP_MODE === MODE.RESEARCH) {
        tabs.push(
          {
            name: this.t("Recherche"),
            to: { name: "Search" },
            ref: "search_tab",
            isAllowed: this.can(Perm.AppSearch.value),
          },
          {
            name: this.t("Cohortes"),
            to: { name: "Cohorts" },
            isAllowed: hasCohortAccess(),
          },
          {
            name: this.t("Extraction"),
            to: { name: "Sde" },
            isAllowed: this.can(Perm.AppSDE.value),
          },
          {
            name: this.t("Tableur"),
            to: { name: "SpreadSheet" },
            isAllowed: true,
          },
          {
            name: this.t("Analyse"),
            to: { name: "AnalysisNavigator" },
            isAllowed: true,
          },
          {
            name: this.t("Informations"),
            to: { name: "Dashboard" },
            isAllowed: true,
          },
          {
            name: this.t("Admin"),
            to: { name: "Admin" },
            isAllowed: this.can(Perm.IsAdminEDS.value),
          }
        );
      }
      return tabs;
    },
  },

  watch: {
    notifications() {
      if (this.notifications && this.notifications.length > 0) {
        this.$store.commit("notifications/setUnReadNotifications", 0);
        this.notifications.forEach((notification) => {
          if (!notification.read_date) {
            this.$store.commit(
              "notifications/setUnReadNotifications",
              this.unReadNotifications + 1
            );
          }
        });
      } else if (this.notifications.length === 0) {
        this.$store.commit("notifications/setUnReadNotifications", 0);
      }
    },

    $route() {
      if (this.$route.meta.noNavBarTabs) {
        this.tab = -1;
      }
    },
  },

  created() {
    this.tab = parseInt(sessionStorage.getItem("lastNavTab"));
    this.getLogo();
  },

  methods: {
    hasPatientSearchAccess,

    showNotification() {
      this.$store.commit(
        "notifications/setDisplayNotifications",
        !this.displayNotifications
      );
    },

    goToSearch() {
      if (!this.showEmptyNavBar) {
        if (process.env.VUE_APP_MODE === MODE.GDDI) {
          if (this.$route.name !== "Patients") {
            this.$router.push({ name: "Patients" });
          }
        } else if (process.env.VUE_APP_MODE === MODE.EDS && this.customSettings.login_page === SUITE.TRANSPARENCY_STANDALONE.name) {
          if (this.$route.name !== "Workspaces") {
            this.$router.push({ name: "Workspaces" });
          }
        } else {
          if (this.$route.name !== "Home") {
            this.$router.push({ name: "Home" });
          }
        }
      }
    },

    openLink(link) {
      window.open(link, "_blank");
    },

    logout() {
      this.$store
        .dispatch("auth/logout")
        .finally(() => this.$router.push({ name: "LoginGeneral" }));
    },

    myAccount() {
      this.tab = -1;
      this.$router.push({ name: "Account" });
    },

    getLogo() {
      switch (process.env.VUE_APP_MODE) {
        case MODE.CARE:
          this.logo = require(`@/assets/img/logo/white/ResearchHub-Care.svg`);
          break;
        case MODE.GDDI:
          this.logo = require(`@/assets/img/logo/white/Transparency.svg`);
          break;
        case MODE.EDS:
          this.logo = require(`@/assets/img/logo/white/ResearchHub.svg`);
          break;
        case MODE.RESEARCH:
          this.logo = require(`@/assets/img/logo/white/ResearchHub-Workspace.svg`);
          break;
      }
    },
  },
};
</script>

<style scoped>
.app-bar {
  z-index: 9999 !important;
}

.app-bar,
.app-bar :deep(.v-toolbar__content) {
  height: 48px !important;
}

.logo :deep(.v-image__image.v-image__image--contain) {
  background-position: left center !important;
}
</style>

import {authRequest} from "@/services/service";
import authHeader from "@/services/auth/auth.header";


export const PageChoices = {
    PATIENT: 'patient',
    COHORT: 'cohort',
    RESULT: 'result',
    SDE: 'sde'
};

export const TabChoices = {
    PATIENTS: 'patients',
    COHORTS: 'cohorts',
    RESULTS: 'results',
    PMSI: 'pmsi',
    PHENOTYPES: 'phenotypes',
    BIOLOGY: 'biology',
    EFR: 'efr',
    DOCUMENTS: 'documents',
    TIMELINE: 'timeline',
    MOVEMENTS: 'movements',
    STAYS: 'stays',
    DEMOGRAPHY: 'demography',
    PRESCRIPTIONS: 'prescriptions',
    SDE: 'sde'
};


export class LogsApi {
    constructor() {
        this.base_url = '/logs/app/';
    }

    async create(page, tab, object_id) {
        return await authRequest.post(this.base_url, {page: page, tab: tab, object_id: object_id}, {headers: authHeader()});
    }
}

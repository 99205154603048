<template>
  <v-footer class="bg-transparent py-auto footer mb-0" inset app absolute padless :height="50">
    <div
      class="text-center w-100 d-inline-flex align-items-center justify-content-between pa-1 pb-2 pt-2 text-body-2 basics-80"
    >
      <img
        v-if="customSettings.logo"
        :src="customSettings.logo + '?t=' + Date.now()"
        class="ml-5 mr-1 logo"
        alt="logo"
      >
    </div>
  </v-footer>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'CustomFooter',
  
  data () {
    return {};
  },

  computed: {
    ...mapGetters({
      customSettings: 'customApp/getCustomSettings',
    })
  }
};
</script>

<style scoped>
.logo {
  height: 28px;
  object-fit: cover;
}

.footer{
  z-index: 0 !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showBreadcrumb || _vm.showTabs)?_c('div',{staticClass:"all-breadcrumb d-flex w-100 bg-basics-10 overflow-hidden text-truncate px-2",class:{
    'fix-breadcrumbs':
      _vm.$router.currentRoute.name === 'SdeDetail' ||
      _vm.$router.currentRoute.name === 'Cohort instance' ||
      _vm.$router.currentRoute.name === 'CohortPatientRecord'
  }},[(_vm.showBreadcrumb)?_c('v-breadcrumbs',{attrs:{"items":_vm.breadCrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('icon',{attrs:{"id":"right-arrow-cohort","name":"caret-right","outline":"","color":"basics-100"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('v-breadcrumbs-item',{staticClass:"clickable",class:{
            'last-item': _vm.index(item.text) === _vm.breadCrumbs.length - 1
          },on:{"click":function($event){return _vm.goTo(item)}}},[_c('span',{staticClass:"max-w-17-vw text-truncate",class:{'unclickable': !item.subtitle}},[_vm._v(" "+_vm._s(item.text)+" ")]),(item.subtitle)?_c('span',{staticClass:"max-w-70-vw ml-2 basics-70 text-truncate"},[_vm._v(" "+_vm._s(item.subtitle)+" ")]):_vm._e()])],1)]}}],null,false,3226157694)}):_vm._e(),(_vm.showTabs)?_c('v-divider',{staticClass:"divider-breadcrumbs ml-3",attrs:{"vertical":""}}):_vm._e(),(_vm.showTabs)?_c('tab-list',{attrs:{"default-tab":_vm.tab,"items":_vm.tabs,"icon-size":20},on:{"update-tab":_vm.onTabChange}}):_vm._e(),_c('dialog-box',{attrs:{"dialog":_vm.displayDetails,"icon":"book-closed","width":"700px","is-form-valid":"","type":"info"},on:{"close":function($event){_vm.displayDetails = false; _vm.item = {}},"submit":function($event){_vm.displayDetails = false; _vm.item = {}}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('breadcrumbs-details-dialog',{attrs:{"details":_vm.item}})]},proxy:true}],null,false,1361839002)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
const Dashboard = () => import('@/views/commons/dashboard/Dashboard.vue');
const Thesauri = () => import('@/views/commons/dashboard/thesaurus/Thesauri.vue');
const UsageUsers = () => import('@/views/commons/dashboard/usage/UsageUsers.vue');
const UsageSearch = () => import('@/views/commons/dashboard/usage/UsageSearch.vue');
const UsageCohorts = () => import('@/views/commons/dashboard/usage/UsageCohorts.vue');
const UsagePatientFile = () => import('@/views/commons/dashboard/usage/UsagePatientFile.vue');
const WarehouseDocuments = () => import('@/views/commons/dashboard/warehouse/WarehouseDocuments.vue');

export const dashboardRoutes =     {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    redirect: {name: 'WarehouseDocuments'},
    children: [
        {
            path: 'documents',
            name: 'WarehouseDocuments',
            component: WarehouseDocuments,
            meta: {alertMessagePosition: true, logoPosition: true},
            props: true,
        },
        {
            path: 'users',
            name: 'UsageUsers',
            component: UsageUsers,
            meta: {alertMessagePosition: true, logoPosition: true},
            props: true,
        },
        {
            path: 'search',
            name: 'UsageSearch',
            component: UsageSearch,
            meta: {alertMessagePosition: true, logoPosition: true},
            props: true,
        },
        {
            path: 'cohorts',
            name: 'UsageCohorts',
            component: UsageCohorts,
            meta: {alertMessagePosition: true, logoPosition: true},
            props: true,
        },
        {
            path: 'patients',
            name: 'UsagePatientFile',
            component: UsagePatientFile,
            meta: {alertMessagePosition: true, logoPosition: true},
            props: true,
        },
        {
            path: 'thesauri',
            name: 'Thesauri',
            component: Thesauri,
            meta: {alertMessagePosition: true, logoPosition: true},
        }
    ]
};

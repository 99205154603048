<template>
  <div>
    <div v-if="!verified">
      <div class="text-subtitle-1 font-demi-bold">
        {{ t('Installation') }}
      </div>

      <div class="text-body-2 basics-80 mt-2">
        {{ t('Télécharger une application de double authentification sur votre téléphone.') }} <br>
        {{ t('Vous pouvez utiliser des applications comme') }}
        <span class="primary-main font-demi-bold">
          <a href="https://www.microsoft.com/fr/security/mobile-authenticator-app">Microsoft Authenticator</a>,
          <a href="https://support.1password.com/fr/one-time-passwords/"> 1Password</a>,
          <a href="https://authy.com/features/">Authy</a>
        </span>.
      </div>

      <div class="text-subtitle-1 font-demi-bold mt-6">
        {{ t('Configuration') }}
      </div>

      <div v-if="uri" class="text-body-2 basics-80 mt-2">
        <span class="mt-1">{{ t("Scanner l’image ci-dessous dans l’application d’authentification.") }}</span>
        <qrcode-vue :value="uri" size="200" level="L" class="m-2 ml-0" />
        <div>{{ t("Si vous ne pouvez pas utiliser le QR Code, entrez ce code à la place ") }}: {{ secret }}</div>
      </div>
      <div v-else class="my-3">
        <v-progress-circular indeterminate color="primary" />
      </div>

      <div class="text-subtitle-1 font-demi-bold mt-6">
        {{ t('Vérification') }}
      </div>
      <div class="text-body-2 basics-80 mt-2">
        {{ t("Après avoir scanné le QR Code, insérer le code de l'application ci dessous.") }}
      </div>

      <text-input
        v-model="form.totp"
        class="mt-2 w-30"
        :placeholder="t('Code à 6 chiffres')"
        :loading="loading"
        autofocus
        :error-messages="errorMessage ? errorMessage : null"
        :success-messages="t(verified ? 'Authentification réussie !' : null)"
        @input="verifyTotp"
      />
    </div>

    <div v-if="verified">
      <div class="text-subtitle-1 font-demi-bold mt-6">
        {{ t('Codes de récupération') }}
      </div>
      <div class="text-body-2 basics-80 mt-2">
        {{ t('Ces codes vous permettront de vous connecter si vous perdez votre téléphone.') }} <br>
        {{ t('Enregistrez les et protégez les comme vous le feriez pour des mots de passe.') }}
      </div>

      <badge :text="'&#9888;&#65039;' + ' ' + t('Attention')" type="warning" class="mt-4" />
      <div class="warning-main mt-1 text-body-2 mb-4">
        {{ t("Si vous perdez votre téléphone et n'avez pas les codes de récupération vous perdrez accès à votre compte.") }}
      </div>

      <ul v-if="recovery_codes.length > 0" class="text-body-2">
        <li v-for="(code, index) in recovery_codes" :key="index">
          {{ code }}
        </li>
      </ul>
      <v-progress-circular v-else indeterminate color="primary" />

      <v-btn class="mt-2 primary" @click="goToApp">
        {{ t("J'ai sauvegardé mes codes de récupération") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import QrcodeVue from 'qrcode.vue';
import {Totp, StaticTotp} from "@/services/totp";

export default {
  name: 'OtpSetup',
  components: { QrcodeVue },
  mixins: [i18n],

  data() {
    return {
      uri: '',
      form: {
        totp: ''
      },

      recovery_codes: [],
      verified: false,
      loading: false,
      errorMessage: ''
    };
  },

  computed: {
    secret () {
      return this.uri.match('secret=(.*?)&')[1];
    },
  },

  mounted() {
    Totp.setup().then(
      response => {
        this.uri = response.data;
        if(!this.uri) {
          this.$router.push({name: 'Login'});
        }
      },
      () => {
        this.$router.push({name: 'Login'});
      }
    );
  },

  methods: {
      verifyTotp() {
        if (this.form.totp.length === 6) {
          this.loading = true;
          Totp.verify(this.form.totp).then(
            () => {
              this.verified = true;
              return StaticTotp.setup().then(
                response => {
                  this.recovery_codes = response.data;
                }
              );
            },
            err => {
              if (err.response.status === 401) {
                this.$router.push({name: 'Login'});
              } else {
                this.errorMessage = this.t('Code invalide');
              }
            }
          ).finally(() => this.loading = false);
        } else {
          this.errorMessage = '';
        }
      },

      goToApp() {
        this.$router.push({'name': 'Callback'});
      }
  }
};
</script>

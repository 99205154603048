import Vue from 'vue';
import VueRouter from 'vue-router';
import {Perm} from "@/enums/permissions";
import {careRoutes} from "@/router/router.care";
import {gddiRoutes} from "@/router/router.gddi";
import {edsRoutes} from "@/router/router.eds";
import {researchRoutes} from "@/router/router.research";
import {MODE} from "@/config";

Vue.use(VueRouter);

function getRoutes() {
    switch (process.env.VUE_APP_MODE) {
        case MODE.CARE:
            return careRoutes;
        case MODE.GDDI:
            return gddiRoutes;
        case MODE.EDS:
            return edsRoutes;
        case MODE.RESEARCH:
            return researchRoutes;
        default:
            return [];
    }
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes: getRoutes(),
    permissions: Perm
});

export default router;

import { MovementsType } from "@/services/api";
import { getMovementsText } from "@/utils/movement";

const state = () => ({
  movements: [],
});

// getters
const getters = {
  movements: (state) => state.movements,
};

// mutations
const mutations = {
  setMovements(state, movements) {
    state.movements = movements;
  },

  delMovement(state, index) {
    state.movements.splice(index, 1);
  },
};

// actions
const actions = {
  getMovementsType({ commit }) {
    MovementsType.list().then((response) => {
      const movements = response.data.results.map((t) =>
        getMovementsText(t.name)
      );
      commit("setMovements", movements);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

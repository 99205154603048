export default {
    methods: {
        /**
         * Return error when attempting to import file
         * @returns {string}
         * @param error
         */
        displayFileError(error) {
            switch (error.response.data.code) {
                case 'row_limit_exceeded':
                    this.$store.commit('snackbar/errorSnackbar', {message: this.t(`Le fichier a plus de lignes que la limite autorisée ('${error.response.data.limit}').`)});
                    break;
                case 'file_too_long':
                    this.$store.commit('snackbar/errorSnackbar', {message: this.t(`Le fichier dépasse la taille maximum autorisée ('${error.response.data.limit}').`)});
                    break;
                case 'missing_columns':
                    this.$store.commit('snackbar/errorSnackbar', {message: this.t(`Les colonnes '${error.response.data.columns}' n'ont pas été trouvées dans le fichier.`)});
                    break;
                case 'missing_headers':
                    this.$store.commit('snackbar/errorSnackbar', {message: this.t(`Le fichier ne contient pas d'entête.`)});
                    break;
                case 'invalid_file_content':
                    this.$store.commit('snackbar/errorSnackbar', {message: this.t(`Le fichier n'est pas formaté correctement: '${error.response.data.error}'`)});
                    break;
                case 'invalid_file_format':
                    this.$store.commit('snackbar/errorSnackbar', {message: this.t(`L’extension du fichier n'est pas supporté: '${error.response.data.allowed_format}'`)});
                    break;
                case 'columns_not_found':
                    this.$store.commit('snackbar/errorSnackbar', {message: this.t(`Les colonnes '${error.response.data.columns}' n'ont pas été trouvées dans le fichier.`)});
                    break;
                default:
                    this.$store.commit('snackbar/apiErrorSnackbar', error);
            }
        }
    }
};
import { Tips, Sources } from "@/services/api";
import { DocumentTypes } from "@/enums/document_types";

const state = () => ({
    results: {
        document_matched: [],
        document_count: 0,
        stay_matched: [],
        stay_count: 0,
        movement_matched: [],
        movement_count: 0,
        datas_biologies_matched: {
            row_headers: [],
            col_headers: [],
            values: [],
            all: []
        },
        datas_biologies_count: 0,
        datas_pmsi_matched: [],
        datas_pmsi_count: 0,
        datas_prescriptions_matched: [],
        datas_prescriptions_count: 0,
        datas_efr_matched: [],
        datas_efr_count: 0
    },
    body: {},
    loading: false,
    tip: null, // Tip during loading for user
    sources: []
});

// getters
const getters = {
    getBody: state => state.body,
    getResults: state => state.results,
    getLoading: state => state.loading,
    getTip: state => state.tip,
    sources: state => state.sources
};

// mutations
const mutations = {
    setBody(state, body) {
      state.body = body;
    },

    setBodyProperty(state, property) {
        state.body[property.name] = property.value;
    },

    setResults(state, results) {
        state.results = results;
    },

    setResultsProperty(state, property) {
        state.results[property.name] = property.value;
    },

    setLoading(state, loading) {
        state.loading = loading;
    },

    setDefaultBody(state, defaultBody) {
        Object.assign(state.body, defaultBody);
    },

    setTip(state, tip) {
        state.tip = tip;
    },

    setSources(state, sources) {
        state.sources = sources;
    }
};

const actions = {
    resetBody({commit}, patientId) {
        commit('setDefaultBody', {
            query_string: "",
            filters: {},
            must_not: {},
            patient_id: patientId,
            search_title: true,
            synonym: false
        });
    },

    changeBody({commit}, property) {
        Object.keys(property).forEach(obj => {
            commit('setBodyProperty', {name: obj, value: property[obj]});
        });
    },

    getTipSearch({commit}) {
        Tips.list().then((response) => {
            commit('setTip', response.data);
        });
    },

    getSources({commit}) {
        return Sources.list({
            type__in: Object.values(DocumentTypes)
              .map((item) => item.value)
              .join(","),
        }).then((response) => {
            commit("setSources", response.data.results);
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

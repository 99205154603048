import store from "@/store";

const Cgu = () => import("@/views/Cgu.vue");
const DesignSystem = () =>
  import("@/views/commons/design_system/DesignSystem.vue");
const PageNotFound = () => import("@/views/commons/PageNotFound.vue");
const Persona = () => import("@/views/Persona.vue");

import LoginGeneral from "@/views/auth/LoginGeneral";
import Home from "@/views/commons/Home.vue";
import { twoFactorUrls, simpleLoginUrls } from "@/router/routes/auth";
import { adminRoutes } from "@/router/routes/admin";
import { cohortRoutes } from "@/router/routes/cohorts";
import { searchRoutes } from "@/router/routes/search";
import { patientRoutes } from "@/router/routes/patient";
import { dashboardRoutes } from "@/router/routes/dashboard";
import { sdeRoutes } from "@/router/routes/sde";

function authUrls() {
  if (process.env.VUE_APP_2FA_ENABLED) {
    return twoFactorUrls;
  }
  return simpleLoginUrls;
}

export const careRoutes = authUrls().concat([
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "LoginGeneral",
    component: LoginGeneral,
    meta: { disableNavBar: true, authNotRequired: true, disableFooter: true },
  },
  {
    path: "/cgu/sign",
    name: "Cgu",
    component: Cgu,
    meta: { showEmptyNavBar: true },
    beforeEnter: (to, from, next) => {
      store.dispatch('customApp/getCustomSettings').then(() => {
        const customSettings = store.getters['customApp/getCustomSettings'];
        const hasCgu = !!customSettings.cgu;
        if (hasCgu) {
          next();
        } else {
          next({ path: '/home' });
        }
      }).catch(error => {
        console.error("Failed to fetch custom settings", error);
        next({ path: '/home' });
      });
    }
  },
  {
    path: "/persona",
    name: "Persona",
    component: Persona,
    meta: { showEmptyNavBar: true },
  },
  {
    name: "Design System",
    path: "/design",
    component: DesignSystem,
  },
  adminRoutes,
  cohortRoutes,
  searchRoutes,
  ...patientRoutes,
  dashboardRoutes,
  sdeRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: PageNotFound,
  },
]);

<template>
  <login-template title="Authentification à deux facteurs" :logo="logo" :loading="loading"
                  :error-message="errorMsg" :form="form"
                  :description="description" otp-verification
                  @submit="verifyTotp"
  />
</template>

<script>
import i18n from '@/mixins/i18n';
import { Totp } from '@/services/totp';
import LoginTemplate from '@/views/auth/LoginTemplate';
import { MODE } from '@/config';

export default {
  name: 'OTPVerification',
  components: { LoginTemplate },
  mixins: [i18n],

  data () {
    return {
      loading: false,
      errorMsg: '',
      form: {
        totp: ''
      },

      description: 'Ouvrez l\'application de double authentification (TOTP) sur votre téléphone pour voir votre code.'
    };
  },

  computed: {
    logo () {
      switch (process.env.VUE_APP_MODE) {
        case MODE.CARE:
          return require(`@/assets/img/logo/ResearchHub-Care.svg`);
        case MODE.EDS:
          return require(`@/assets/img/logo/ResearchHub.svg`);
        case MODE.RESEARCH:
          return require(`@/assets/img/logo/ResearchHub-Workspace.svg`);
        default:
          return '';
      }
    }
  },

  methods: {

    verifyTotp () {
      if (this.form.totp.length === 6 && !this.loading) {
        this.loading = true;
        Totp.verify(this.form.totp).then(
          () => {
            this.$router.push({ 'name': 'Callback' });
          },
          err => {
            if (err.response.status === 401) {
              this.$router.push({ name: 'Login' });
            } else {
              this.errorMsg = this.t('Code invalide');
            }
          }
        ).finally(() => this.loading = false);
      } else {
        this.errorMsg = '';
      }
    }
  }
};
</script>

import {differenceInMilliseconds, isAfter, subMinutes} from "date-fns";
import AuthInterface from "@/services/auth/auth";
import Router from "@/router/router.index";
import {parseJWT} from "@/utils/jwt";
import TokenService from "@/services/tokens";

export function isLoggedIn() {
  let exp = TokenService.getExpiryTime();
  let expiryTime = new Date(exp);
  const now = new Date();
  return !isAfter(now, expiryTime);
}

const state = () => ({
  refreshToken: TokenService.getRefreshToken(),
  accessToken: TokenService.getAccessToken(),
  tokensExpiry: TokenService.getExpiryTime(),
  loggedIn: isLoggedIn(),
});

const getters = {
  tokensExpiry: (state) => state.tokensExpiry,
  accessToken: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
  decodedAccessToken: (state) => {
    parseJWT(state.accessToken);
  },
  loggedIn: (state) => state.loggedIn,
};

const actions = {
  login({ commit, dispatch, rootGetters }, params) {
    const loggedUser = rootGetters["user/me"];
    if (loggedUser.id) {
      dispatch("logout");
    }
    localStorage.removeItem("search_body");
    return AuthInterface.login(params).then((response) => {
      commit("setTokens", response.data);
      dispatch("initSession");
      return Promise.resolve(response);
    });
  },

  logout({ commit }) {
    return AuthInterface.logout().finally(() => {
      commit("resetTokens");
      commit("user/resetUser", null, { root: true });
      commit("notifications/setDisplayNotifications", false, { root: true });
    });
  },

  initSession({ state, dispatch }) {
    let tokenExpiryDate = state.tokensExpiry;

    if (!tokenExpiryDate) {
      if (!Router.currentRoute.meta.authNotRequired) {
        return Router.push({ name: "LoginGeneral" });
      }
      return;
    }

    tokenExpiryDate = new Date(tokenExpiryDate);

    let tenMinutesBeforeExpiry = subMinutes(tokenExpiryDate, 5);
    const now = new Date();

    if (isAfter(now, tenMinutesBeforeExpiry)) {
      dispatch("refreshTokens");
      return;
    }
    setTimeout(() => {
      dispatch("refreshTokens");
    }, differenceInMilliseconds(tenMinutesBeforeExpiry, now));
  },

  refreshTokens({ commit, dispatch, state }) {
    AuthInterface.refresh().then(
      (response) => {
        commit("setTokens", response.data);
        const tokenExpiryDate = state.tokensExpiry;
        const tenMinutesBeforeExpiry = subMinutes(tokenExpiryDate, 5);
        const now = new Date();
        setTimeout(() => {
          dispatch("refreshTokens");
        }, differenceInMilliseconds(tenMinutesBeforeExpiry, now));
      },
      () => {
        dispatch("logout");
        if (Router.currentRoute.name !== "LoginGeneral") {
          Router.push({ name: "LoginGeneral" });
        }
      }
    );
  },
};

const mutations = {
  setTokens(state, tokens) {
    TokenService.setAccessToken(tokens.access);
    state.accessToken = tokens.access;

    TokenService.setRefreshToken(tokens.refresh);
    state.refreshToken = tokens.refresh;

    const parsedAccessToken = parseJWT(state.accessToken);
    const tokensExpiry = new Date(parsedAccessToken.exp * 1000);

    TokenService.setExpiryTime(tokensExpiry);
    state.tokensExpiry = tokensExpiry;

    state.loggedIn = true;
    localStorage.setItem("logout", "false");
  },

  resetTokens(state) {
    TokenService.removeAccessToken();
    state.accessToken = null;

    TokenService.removeRefreshToken();
    state.refreshToken = null;

    TokenService.removeExpiryTime();
    state.tokensExpiry = null;

    state.loggedIn = false;
    localStorage.setItem("logout", "true");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="row h-100 m-0">
    <div class="col col-6 d-flex align-items-center h-100 login-container">
      <div class="d-flex align-center primary-main text-body-2 font-demi-bold p-6 col-6 clickable fixed-top"
           @click="back"
      >
        <icon name="arrow-left" outline class="arrow mr-1" />
        {{ t('Ensemble des applications codoc') }}
      </div>
      <div>
        <v-img :src="logo" max-height="32" contain class="logo" />
        <h5 class="text-h5 font-demi-bold mt-4">
          {{ t(title) }}
        </h5>
        <div v-if="!selectApp">
          <div class="text-body-2 basics-80">
            {{ t(description) }}
            <div v-if="!otpStaticVerification && !lockOut && !otpSetup && !isResearchMode" class="mt-6">
              {{ t('Visitez le site pour plus d\'informations : ') }}
              <a class="font-demi-bold primary-main" href="https://codoc.co/" target="_blank">codoc.co</a>
            </div>
          </div>
          <div class="mt-4">
            <login-form v-if="!otpVerification && !otpSetup && !otpStaticVerification && !lockOut"
                        :error-message="errorMessage" :form="form" :loading="loading" @submit="submit"
            />
            <otp-verification v-else-if="otpVerification" :loading="loading" :form="form" :error-message="errorMessage"
                              @submit="submit"
            />
            <otp-setup v-else-if="otpSetup" />
            <otp-static-verification v-else-if="otpStaticVerification" :loading="loading" :form="form"
                                     :error-message="errorMessage" @submit="submit"
            />
            <lock-out v-else-if="lockOut" />
          </div>
        </div>

        <custom-footer />
      </div>
    </div>
    <div class="col col-6 p-0 d-flex justify-content-center h-100 bg-basics-10 align-center flex-column">
      <v-img class="flex-0 mb-8" contain :src="text" />
      <v-img class="flex-0" contain :src="glass" />
    </div>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import CustomFooter from '@/components/footer/CustomFooter';
import { isLoggedIn } from '@/store/modules/auth';
import OtpVerification from '@/components/auth/OtpVerification';
import LoginForm from '@/components/auth/LoginForm';
import OtpSetup from '@/components/auth/OtpSetup';
import OtpStaticVerification from '@/components/auth/OtpStaticVerification';
import LockOut from '@/components/auth/LockOut';
import {MODE} from "@/config";

export default {
  name: 'LoginTemplate',
  components: {
    LockOut,
    OtpStaticVerification,
    OtpSetup,
    LoginForm,
    OtpVerification,
    CustomFooter
  },

  mixins: [i18n],

  props: {
    title: {
      type: String,
      required: true
    },

    logo: {
      type: String,
      required: true
    },

    loading: {
      type: Boolean,
      required: false
    },

    errorMessage: {
      type: String,
      required: false
    },

    form: {
      type: Object,
      required: false
    },

    description: {
      type: String,
      required: false
    },

    lockOut: {
      type: Boolean,
      required: false,
      default: false
    },

    otpVerification: {
      type: Boolean,
      required: false,
      default: false
    },

    otpSetup: {
      type: Boolean,
      required: false,
      default: false
    },

    otpStaticVerification: {
      type: Boolean,
      required: false,
      default: false
    },

    selectApp: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      text: require('@/assets/img/TextLogin.svg'),
      glass: require('@/assets/img/Glass.svg')
    };
  },

  computed: {
    isResearchMode() {
      return process.env.VUE_APP_MODE === MODE.RESEARCH;
    }
  },

  created () {
    if (isLoggedIn()) {
      this.$router.push({ name: 'Home' });
    }
  },

  methods: {
    submit () {
      this.$emit('submit');
    },

    back () {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.logo :deep(.v-image__image.v-image__image--contain) {
  background-position: left center !important;
}

.login {
  max-width: 508px;
  margin: auto;
}
</style>


export default {
    methods: {
        /**
         * Reformat date to API format to be able to use it for search bar
         * @param search
         * @returns search with formatted date
         */
        cleanSearch(search) {
            let searchDate = search.match(/((0[1-9]|[12][0-9]|3[01])[/])?(0[1-9]|1[012])([/]\d{4})?/);
            if (searchDate) {
                 return search.replace(
                    /((0[1-9]|[12][0-9]|3[01])[/])?(0[1-9]|1[012])([/]\d{4})?/,
                    searchDate[0].split("/").reverse().join("-"));
            } else {
                return search;
            }
        }
    }
};

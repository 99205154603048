import { twoFactorUrls } from "@/router/routes/auth";
import { adminRoutes } from "@/router/routes/admin";
import { cohortRoutes } from "@/router/routes/cohorts";
import { searchRoutes } from "@/router/routes/search";
import { patientRoutes } from "@/router/routes/patient";
import { dashboardRoutes } from "@/router/routes/dashboard";
import { sdeRoutes } from "@/router/routes/sde";
import Home from "@/views/commons/Home.vue";
import LoginGeneral from "@/views/auth/LoginGeneral";
import store from '@/store';
const Cgu = () => import("@/views/Cgu.vue");
const DesignSystem = () =>
  import("@/views/commons/design_system/DesignSystem.vue");
const PageNotFound = () => import("@/views/commons/PageNotFound.vue");
const SwitchWorkspaceList = () =>
  import("@/components/workspaces/SwitchWorkspaceList.vue");
const Workspaces = () => import("@/components/workspaces/Workspaces.vue");
const WorkspaceDetail = () =>
  import("@/components/workspaces/WorkspaceDetail.vue");
const Persona = () => import("@/views/Persona.vue");

let routes = twoFactorUrls.concat([
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "LoginGeneral",
    component: LoginGeneral,
    meta: { disableNavBar: true, authNotRequired: true, disableFooter: true },
  },
  {
    path: "/cgu/sign",
    name: "Cgu",
    component: Cgu,
    meta: { showEmptyNavBar: true },
    beforeEnter: (to, from, next) => {
      store.dispatch('customApp/getCustomSettings').then(() => {
        const customSettings = store.getters['customApp/getCustomSettings'];
        const hasCgu = !!customSettings.cgu;
        if (hasCgu) {
          next();
        } else {
          next({ path: '/home' });
        }
      }).catch(error => {
        console.error("Failed to fetch custom settings", error);
        next({ path: '/home' });
      });
    }
  },
  {
    path: "/persona",
    name: "Persona",
    component: Persona,
    meta: { showEmptyNavBar: true },
  },
  {
    path: "/workspaces",
    component: Workspaces,
    children: [
      {
        path: "",
        name: "Workspaces",
        component: SwitchWorkspaceList,
      },
      {
        path: ":workspace_id",
        name: "WorkspaceDetail",
        component: WorkspaceDetail,
        props: true,
      },
    ],
  },
  {
    name: "Design System",
    path: "/design",
    component: DesignSystem,
  },
  adminRoutes,
  ...patientRoutes,
]);

if (!process.env.VUE_APP_TRANSPARENCY_STANDALONE) {
  routes = routes.concat([
    dashboardRoutes,
    cohortRoutes,
    searchRoutes,
    sdeRoutes,
  ]);
}

routes = routes.concat([
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
  }
]);

export const edsRoutes = routes;
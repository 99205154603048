import i18n from "@/mixins/i18n";
import moment from "moment";

export default {
    mixins: [i18n],

    computed: {
        chartType() {
            return this.$store.getters["charts/getChartTypeByIndex"](this.chartIndex);
        },
        axis() {
            return this.$store.getters["charts/getAxisByIndex"](this.chartIndex);
        },
        series() {
            return this.$store.getters["charts/getSeriesByIndex"](this.chartIndex);
        },
        layout() {
            return this.$store.getters["charts/getLayoutByIndex"](this.chartIndex);
        }
    },

    methods: {
        initialiseBiologyData(data, filter) {
            let dateArray = [];
            let tableData = [];
            let lowerBound = [];
            let upperBound = [];
            let chartData = [{
                x: [],
                y: [],
                type: 'scatter',
                mode: 'lines+markers',
                hovertemplate: "%{x} <br /> Points de mesure: %{y}<extra></extra>",
                connectgaps: true,
                name: i18n.methods.t('données'),
                line: {
                    color: '#233C73'
                }
            }];
            data.forEach((data, i) => {
                if (data.lower_bound) {
                    lowerBound.push({val: data.lower_bound, date: data.document_date});
                }
                if (data.upper_bound) {
                    upperBound.push({val: data.upper_bound, date: data.document_date});
                }
                let tableObj = {};
                dateArray.push(moment(data.document_date).format('YYYY-MM-DD'));
                tableObj.date = moment(data.document_date.split(' ')[0]).format('DD/MM/YYYY');
                tableObj.time = data.document_date.split(' ')[1];
                tableObj.value = filter === 'numeric' ? data.val_numeric : data.val_text;
                tableObj.lower = data.lower_bound;
                tableObj.upper = data.upper_bound;
                tableObj.encounter = data.encounter ? data.encounter : "-";
                tableObj.source = data.document_origin_code ? data.document_origin_code : "-";
                tableObj.service = data.department && data.department.name ? data.department.name : "-" ;
                tableObj.unit = data.thesaurus_data && data.thesaurus_data.measuring_unit ? data.thesaurus_data.measuring_unit : "-" ;

                chartData[0].y.push(data.val_numeric);
                chartData[0].x.push(dateArray[i]);
                tableData.push(tableObj);
            });
            if (upperBound.length > 0) {
                chartData.push(this.boundData(data, upperBound, dateArray, 'up'));
            }
            if (lowerBound.length > 0) {
                chartData.push(this.boundData(data, lowerBound, dateArray, 'low'));
            }
            return {
                table_data: tableData,
                chart_data: chartData
            };
        },

        boundData(data, bound, dates, b) {
            let yArray = [];
            let dateRange = [];
            if (data.length === 1) {
                yArray.push(bound[0].val, bound[0].val);
                dateRange.push(moment(dates).subtract(1, 'years').format('YYYY-MM-DD'));
                dateRange.push(moment(dates).add(1, 'years').format('YYYY-MM-DD'));
            } else if (bound.length <= 1) {
                dateRange = [];
            } else if (bound.length < data.length) {
                bound.forEach(b => {
                    yArray.push(b.val);
                    dateRange.push(b.date);
                });
            } else {
                dateRange = dates;
                bound.forEach(b => {
                    yArray.push(b.val);
                });
            }
            return {
                x: dateRange,
                y: yArray,
                type: 'scatter',
                mode: 'lines',
                hoverinfo: "skip",
                name: b === 'low' ? i18n.methods.t('borne inférieure') : i18n.methods.t('borne supérieure'),
                line: {
                    color: b === 'low' ? '#D5D7DE': '#F5C5C4',
                    width: 3
                }
            };
        }
    }
};
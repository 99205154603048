import store from "@/store";

const PageNotFound = () => import('@/views/commons/PageNotFound.vue');
const Oppositions = () => import('@/views/gddi/Oppositions.vue');
const GDDI = () => import('@/views/gddi/GDDI.vue');
const Workspaces = () => import('@/views/gddi/workspaces/Workspaces.vue');
const PlatformPatient = () => import('@/views/gddi/PlatformPatient.vue');
import Patients from "@/views/gddi/patients/Patients";
import {twoFactorUrls} from "@/router/routes/auth";
import LoginGeneral from "@/views/auth/LoginGeneral.vue";
const WorkspacesList = () => import('@/views/gddi/workspaces/WorkspacesList.vue');
const WorkspaceDetail = () => import('@/components/workspaces/WorkspaceDetail.vue');
const Cgu = () => import('@/views/Cgu.vue');
const Persona = () => import("@/views/Persona.vue");

export const gddiRoutes = twoFactorUrls.concat([
    {
        path: "/",
        name: "LoginGeneral",
        component: LoginGeneral,
        meta: { disableNavBar: true, authNotRequired: true, disableFooter: true },
    },
    {
        name: 'Home',
        path: '/home',
        component: GDDI,
        redirect: {name: 'Patients'},
    },
    {
        path: "/persona",
        name: "Persona",
        component: Persona,
        meta: { showEmptyNavBar: true },
    },
    {
        path: '/patients/:patient_id?',
        component: Patients,
        name: 'Patients',
        props: true
    },
    {
        path: '/oppositions',
        name: 'Oppositions',
        component: Oppositions,
    },
    {
        path: '/workspaces',
        component: Workspaces,
        children: [
            {
                path: '',
                name: 'Workspaces',
                component: WorkspacesList,
                props: true
            },
            {
                path: ':workspace_id',
                name: 'WorkspaceDetail',
                component: WorkspaceDetail,
                props: {gddiMode: true}
            },
        ]
    },
    {
        path: '/platform',
        name: 'PlatformPatient',
        component: PlatformPatient,
    },
    {
        path: '/cgu/sign',
        name: 'Cgu',
        component: Cgu,
        meta: {showEmptyNavBar: true},
        beforeEnter: (to, from, next) => {
            store.dispatch('customApp/getCustomSettings').then(() => {
                const customSettings = store.getters['customApp/getCustomSettings'];
                const hasCgu = !!customSettings.cgu;
                if (hasCgu) {
                    next();
                } else {
                    next({ path: '/home' });
                }
        }).catch(error => {
                console.error("Failed to fetch custom settings", error);
                next({ path: '/home' });
            });
        }
    },
    {
        path: "/:pathMatch(.*)*",
        component: PageNotFound,
    },
]);
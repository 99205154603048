<template>
  <div :class="['patient-search-bar', { 'light-text': lightTextColor }]">
    <!-- search bar (vuetify v-autocomplete) -->
    <v-autocomplete
      v-model="selected"
      clearable solo dense
      hide-details
      :loading="loading"
      :search-input.sync="search"
      :items="items"
      item-value="id"
      :placeholder="placeholder"
      :no-data-text="t('Aucun patient trouvé')"
      no-filter
      :hide-no-data="hideNoData"
      :menu-props="{
        contentClass:'menu-container',
        ...hasSearch
      }"
      append-icon=""
    >
      <!-- user icon -->
      <template #prepend-inner>
        <icon name="user" :color="lightTextColor ? 'basics-80' : 'basics-40'" />
      </template>
      <!-- each patient information if not pseudonymized (sex|name|birth date|ipp) -->
      <template v-if="appMode === 'CARE' || appMode === 'GDDI'" #item="{ item }">
        <div v-if="item" class="d-flex flex-column align-items-around mt-2 patient-item"
             :class="index(item.id) === items.length - 1 ? 'last-item' : 'item'"
        >
          <div class="d-inline-flex align-items-center mb-1">
            <icon v-if="item.sex === 'F' || 'M'" :name="item.sex === 'F' ? 'female' : 'male' " color="basics-100"
                  outline size="16" class="mr-2"
            />
            <div class="w-75">
              <span class="text-body-2 font-regular long-name">{{ getName(item) }}</span>
            </div>
          </div>
          <div class="d-inline-flex justify-content-between align-bottom text-body-2 font-regular basics-70 mb-2">
            <div v-if="item.birth_date" class="d-inline-flex">
              <icon :name="getBirthDate(item) ? 'birthday-cake' : ''" color="basics-70" size="16"
                    class="mr-2 icon-cake"
              />
              <span>{{ getBirthDate(item) }}</span>
              <span v-if="item.maiden_name"> - {{ getSex(item) }}{{ item.maiden_name }}</span>
            </div>
            <span>{{ getIPP(item) }}</span>
          </div>
        </div>
      </template>
      <!-- each patient information pseudonymized -->
      <template v-else-if="appMode === 'EDS' || appMode === 'RESEARCH'" #item="{ item }">
        <div v-if="item" class="d-flex flex-column align-items-around mt-2 patient-item"
             :class="index(item.id) === items.length - 1 ? 'last-item' : 'item'"
        >
          <div class="d-inline-flex align-items-center my-auto mb-3">
            <icon v-if="item.sex === 'F' || 'M'" :name="item.sex === 'F' ? 'female' : 'male' " color="basics-100"
                  outline size="16" class="mr-2"
            />
            <span class="text-body-2 font-regular basics-70"> #{{ getPID(item.patient_pid) }}</span>
          </div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import reformatDateForSearch from '@/mixins/reformatDateForSearch';
import { Patients } from '@/services/api';
import axios from 'axios';

export default {
  name: 'PatientSearchBar',
  mixins: [i18n, reformatDateForSearch],

  props: {
    redirect: {
      type: Boolean,
      default: true
    },

    placeholder: {
      type: String,
      default: i18n.methods.t('Rechercher un patient')
    },

    lightTextColor: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loading: false,
      items: [],
      selected: null,
      search: null,
      awaitingSearch: null,
      isFirst: true,
      cancelToken: null,
      source: null
    };
  },

  computed: {
    hideNoData () {
      return this.isFirst;
    },

    /**
     * Get the application mode.
     * @returns {string}
     */
    appMode () {
      return process.env.VUE_APP_MODE;
    },

    hasSearch() {
      return this.search && this.search.length > 1 ? {} : {value: false};
    }
  },

  watch: {
    /**
     * On item click.
     */
    selected () {
      if (this.selected) {
        this.$emit('select', this.selected);
        if (this.redirect) {
          this.$router.push({ name: 'PatientRecord', params: { patientId: this.selected.toString() } });
        }
      }
      this.$nextTick(() => {
        this.selected = null;
        this.search = null;
      });
    },

    /**
     * Search in bar.
     */
    search () {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.getPatients();
      });
      if (this.search && this.search.length > 0) {
        this.loading = true;
      }
    },

    /**
     * Items loaded.
     */
    items () {
      if (this.search && this.search.length > 0 && this.items && this.loading === true) {
        this.loading = false;
      }
    }
  },

  methods: {
    /**
     * Format name.
     * @param item
     * @returns {string}
     */
    getName (item) {
      let name = [];
      if (item.last_name || item.first_name) {
        name.push(item.last_name.toUpperCase());
        name.push(item.first_name);
        return name.join(' ');
      }
    },

    /**
     * Format date of birth.
     * @param item
     * @returns {string}
     */
    getBirthDate (item) {
      let date = '';
        date = this.$options.filters.date(item.birth_date);
        date = date.replaceAll('-', '/');
        return date;
    },

    /**
     * Format Pid.
     * @param pid
     * @returns {string}
     */
    getPID(pid) {
      if (pid.length > 20) {
        return pid.slice(0, 5) + '...' + pid.slice(pid.length - 5, pid.length);
      } else {
        return pid;
      }
    },

    /**
     * Get IPP
     * @param item
     * @returns {string}
     */
    getIPP (item) {
      if (item.master_ipp) {
        return '#' + item.master_ipp;
      } else if (item.ipps && item.ipps.length > 0) {
        return '#' + item.ipps[0].ipp;
      }
    },

    /**
     * Get sex.
     * @param item
     */
    getSex (item) {
      if (item.sex === 'F') {
        return this.t('Née : ');
      } else if (item.sex === 'M') {
        return this.t('Né : ');
      } else {
        return this.t('Né(e) : ');
      }
    },

    /**
     * Fetch patients on search.
     */
    getPatients () {
      if (!this.search) {
        this.items = [];
        this.isFirst = true;
      }
      if (this.search && this.search.length > 0) {
        const cleanDate = this.cleanSearch(this.search);
        if (this.source) {
          this.source.cancel('Request canceled');
        }
        this.cancelToken = axios.CancelToken;
        this.source = this.cancelToken.source();
        Patients.list({ search: cleanDate, limit: 30 }, this.source.token).then(response => {
          this.items = response.data.results;
          this.isFirst = false;
        }).catch(error => {
          if (axios.isCancel(error)) {
            console.log(error);
          } else {
            this.$store.commit('snackbar/apiErrorSnackbar', error);
          }
        });
      }
    },

    /**
     * Get Index.
     * @param id
     * @returns {number}
     */
    index (id) {
      return this.items.map(i => i.id).indexOf(id);
    }
  }
};
</script>

<style>
.patient-search-bar .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active.v-autocomplete__content {
  max-height: calc(51px * 5) !important;
}

.patient-search-bar .v-autocomplete .v-input__slot {
  background-color: rgba(255, 255, 255, 0.24) !important;
}

.patient-search-bar .v-autocomplete .v-input__slot input,
.patient-search-bar .v-autocomplete .v-input__slot input::placeholder,
.patient-search-bar .v-autocomplete .v-input__slot .v-input__icon--clear .v-icon {
  font-size: var(--text-body-2-font-size);
  color: var(--basics-40) !important;
}

.light-text.patient-search-bar .v-autocomplete .v-input__slot input,
.light-text.patient-search-bar .v-autocomplete .v-input__slot input::placeholder,
.light-text.patient-search-bar .v-autocomplete .v-input__slot .v-input__icon--clear .v-icon {
  color: var(--basics-60) !important;
}

.patient-search-bar .v-autocomplete .v-input__slot input {
  color: var(--basics-10) !important;
  padding: 4px 0;
}

.light-text.patient-search-bar .v-autocomplete .v-input__slot input {
  color: var(--basics-100) !important;
}

.patient-search-bar .v-autocomplete .v-input__slot .v-progress-linear__indeterminate {
  background-color: var(--basics-40) !important;
}

.patient-search-bar .v-autocomplete .v-input__control {
  min-height: 32px !important;
}

.item {
  width: 100% !important;
  border-bottom: 1px solid var(--basics-50) !important;
}

.last-item {
  width: 100% !important;
  border: none !important;
}

.v-list-item:hover .patient-item div > svg:not(.icon-cake) path {
  fill: var(--basics-100)  !important;
}

.v-list-item:hover .patient-item div > .icon-cake path {
  fill: var(--basics-70) !important;
}

.long-name {
  max-lines: 2 !important;
  width: 280px !important;
  display: block;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.menu-container .v-list-item--highlighted,
.menu-container .v-list-item:hover:not(.v-list-item--highlighted) {
  background-color: var(--basics-20);
}
</style>

import {CohortExportItems, CohortExport} from "@/services/api";
import cloneDeep from 'lodash/cloneDeep';

function compare(a, b) {
    return a.thesaurus_data.concept_str.localeCompare(b.thesaurus_data.concept_str, 'en', {sensitivity: 'base'});
}

export default {
    namespaced: true,
    state: {
        cohortId: null,
        items: [],
        fields: []
    },
    getters: {
    },
    mutations: {
        setCohortId(state, cohortId) {
            state.cohortId = cohortId;
            state.items = [];
            state.fields = [];
        },
        setItems(state, items) {
            state.items = items;
            state.items = state.items.sort(compare);
        },
        setFields(state, fields) {
            state.fields = fields;
        },
        create(state, item) {
            state.items.unshift(item);
            state.items = state.items.sort(compare);
        },
        update(state, {index, item}) {
            state.items[index] = item;
        },
        destroy(state, index) {
            state.items.splice(index, 1);
        },
        resetItems(state) {
            state.items = [];
        }
    },
    actions: {
        list({state, commit}) {
            return CohortExportItems.list(state.cohortId, {ordering: '-thesaurus_data__concept_str'}).then(response => {
                commit('setItems', response.data.results);
            });
        },
        create({state, commit}, item) {
            return CohortExportItems.create(state.cohortId, item).then(response => {
                commit('create', response.data);
            });
        },
        update({state, commit}, {index, value}) {
            let item = state.items[index];
            let payload = cloneDeep(item);
            payload.multiple = value;
            payload.thesaurus_data_id = item.thesaurus_data.id;
            return CohortExportItems.update(state.cohortId, item.id, payload).then(() => {
                commit('update', {index, item});
            });
        },
        destroy({state, commit}, index) {
            let item = state.items[index];
            return CohortExportItems.destroy(state.cohortId, item.id).then(() => {
                commit('destroy', index);
            });
        },
        bulkDestroy({state, commit}) {
            return CohortExportItems.bulk_destroy(state.cohortId, state.items.map(i => i.id)).then(() => {
                commit('resetItems');
            });
        },
        getFields({state, commit}) {
            return CohortExport.fields(state.cohortId).then(response => {
                commit('setFields', response.data);
            });
        },
    }
};

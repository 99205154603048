const Sde = () => import("@/views/commons/smart_data_extraction/Sde.vue");
const SdeDetail = () => import("@/views/commons/smart_data_extraction/SdeDetail.vue");
const SdeList = () => import("@/views/commons/smart_data_extraction/SdeList.vue");
const SdeImport = () =>  import("@/views/commons/smart_data_extraction/SdeImport.vue");

export const sdeRoutes = {
  path: "/sde",
  component: Sde,
  children: [
    {
      path: "",
      name: "Sde",
      component: SdeList,
    },
    {
      path: ":sde_id",
      name: "SdeDetail",
      component: SdeDetail,
      meta: {logoPosition: true, alertMessagePosition: true},
      props: true,
    },
    {
      path: "/import",
      name: "SdeImport",
      component: SdeImport,
      props: false
    },
  ],
};

<template>
  <div>
    <text-input
      v-model.trim="form.totp" autofocus :loading="loading"
      :placeholder="t('Votre code ici')"
      :input-label="t('Un code n\'est valide qu\'une fois.')" hide-details="auto"
      :error-messages="errorMessage ? errorMessage : null"
      @input="submit"
      @keyup.enter="submit"
    />

    <div class="text-center mt-4 text-body-2">
      <router-link :to="{name: 'LockOut'}" class="font-demi-bold primary-main text-center mt-4 text-body-2">
        {{ t('Pas de code ?') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';

export default {
  name: 'OtpStaticVerification',
  mixins: [i18n],

  props: {
    form: {
      type: Object,
      required: true
    },

    loading: {
      type: Boolean,
      required: true
    },

    errorMessage: {
      type: String,
      required: false
    }
  },

  methods: {
    submit () {
      this.$emit('submit');
    }
  }
};
</script>
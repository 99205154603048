import authHeader from "@/services/auth/auth.header";
import {authRequest} from "@/services/service";

export class OldAppStatsApi {
    constructor() {
        this.base_url = `/kpis/old/`;
    }
    async getSearchByMonth() {
        const url = this.base_url + 'monthly/search/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getPatientsByMonth() {
        const url = this.base_url + 'monthly/patients/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getPatientQueriesByMonth() {
        const url = this.base_url + 'monthly/patients/queries/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getPatientTimelineQueriesByMonth() {
        const url = this.base_url + 'monthly/patients/timeline/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getUsersByMonth() {
        const url = this.base_url + 'monthly/users/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getConnectionsByMonth() {
        const url = this.base_url + 'monthly/connections/';
        return await authRequest.get(url, {headers: authHeader()});
    }
}

import { twoFactorUrls } from "@/router/routes/auth";
import { cohortRoutes } from "@/router/routes/cohorts";
import { searchRoutes } from "@/router/routes/search";
import { patientRoutes } from "@/router/routes/patient";
const PageNotFound = () => import("@/views/commons/PageNotFound.vue");
const Analysis = () => import("@/views/research/analysis/Analysis.vue");
const AnalysisNavigator = () =>
  import("@/views/research/analysis/AnalysisNavigator.vue");
const SpreadSheet = () =>
  import("@/views/research/spreadsheet/Spreadsheet.vue");
import LoginGeneral from "@/views/auth/LoginGeneral";
import CallbackTokens from "@/views/research/auth/CallbackTokens";
import { adminRoutes } from "@/router/routes/admin";
import { dashboardRoutes } from "@/router/routes/dashboard";
import { sdeRoutes } from "@/router/routes/sde";

export const researchRoutes = twoFactorUrls.concat([
  { path: "/", name: "Home", redirect: { name: "Search" } },
  {
    path: "/callback_tokens",
    name: "CallbackTokens",
    component: CallbackTokens,
    meta: { authNotRequired: true, disableNavBar: true },
  },
  {
    path: "/eds",
    name: "LoginGeneral",
    component: LoginGeneral,
    meta: { authNotRequired: true, disableNavBar: true, disableFooter: true },
  },
  {
    path: "/analysis",
    component: Analysis,
    props: (route) => ({ route: route.query.action }),
    children: [
      {
        name: "AnalysisNavigator",
        path: "",
        component: AnalysisNavigator,
        props: (route) => ({ route: route.query.action }),
      },
    ],
  },
  {
    path: "/spreadsheet",
    name: "SpreadSheet",
    component: SpreadSheet,
  },
  adminRoutes,
  cohortRoutes,
  searchRoutes,
  ...patientRoutes,
  dashboardRoutes,
  sdeRoutes,
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
  },
]);

const state = () => ({
  notifications: [],
  displayNotifications: false,
  unReadNotifications: 0,
});

// getters
const getters = {
  getNotifications: (state) => state.notifications,
  displayNotifications: (state) => state.displayNotifications,
  unReadNotifications: (state) => state.unReadNotifications,
};

// mutations
const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },

  setDisplayNotifications(state, display) {
    state.displayNotifications = display;
  },

  setUnReadNotifications(state, number) {
    state.unReadNotifications = number;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};

import Vue from "vue";
import Vuex from "vuex";
import settings from "./modules/settings";
import services from "./modules/services";
import units from "./modules/units";
import sites from "./modules/sites";
import user from "./modules/user";
import auth from "./modules/auth";
import notifications from "./modules/notifications";
import spreadsheet from "./modules/spreadsheet";
import charts from "./modules/charts";
import cohorts from "./modules/cohorts";
import breadcrumbs from "./modules/breadcrumbs";
import movementType from "./modules/movementType";
import snackbar from "./modules/snackbar";
import patient from "./modules/patient";
import structuredExport from "./modules/structuredExport";
import search from "./modules/search";
import nps from "./modules/nps";
import customApp from "./modules/customApp";
import patientSearch from "./modules/patientSearch";
import requests from "./modules/requests";
import workspaceDetail from "./modules/workspaceDetail";
import sde from "./modules/sde/sde";
import events from "./modules/sde/events";
import events_answers from "./modules/sde/events_answers";
import thesauri from "@/store/modules/thesauri";
import sources from "@/store/modules/sources";
import searchResults from "@/store/modules/searchResults";
import cohortData from "@/store/modules/cohortData";
import featureFlag from "@/store/modules/featureFlag";
import logoShifted from "@/store/modules/logoShifted";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    breadcrumbs,
    charts,
    cohorts,
    movementType,
    customApp,
    events,
    events_answers,
    notifications,
    nps,
    patient,
    patientSearch,
    requests,
    sde,
    search,
    searchResults,
    services,
    settings,
    snackbar,
    sources,
    spreadsheet,
    structuredExport,
    thesauri,
    units,
    sites,
    user,
    workspaceDetail,
    cohortData,
    featureFlag,
    logoShifted
  },
  strict: debug,
});

const Account = () => import("@/components/account/Account");
const Infos = () => import("@/components/account/AccountInformation") ;

export const accountUrls = [
  {
    path: "/account",
    name: "Account",
    component: Account,
    redirect: { name: "AccountInformation" },
    children: [
      {
        path: "/infos",
        name: "AccountInformation",
        component: Infos,
        meta: { disableSubNavBar: true },
      },
    ],
  },
];
import Vue from "vue";

let base = {
  getters: {
    objects: (state) => state.objects,
  },
  mutations: {
    setObjects(state, objects) {
      state.objects = objects;
    },
    create(state, item) {
      state.objects.push(item);
    },
    update(state, { index, item }) {
      Vue.set(state.objects, index, item);
    },
    destroy(state, index) {
      state.objects.splice(index, 1);
    },
  },
  actions: {
    list({ state, commit }, params = {}) {
      return state.endpoint
        .list(params)
        .then((response) => {
          commit("setObjects", response.data.results);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("snackbar/errorSnackbar", null, { root: true });
          return Promise.reject(error);
        });
    },
    retrieve({ state, commit }, id) {
      return state.endpoint.retrieve(id).catch((error) => {
        commit("snackbar/errorSnackbar", null, { root: true });
        return Promise.reject(error);
      });
    },
    create({ state, commit }, item) {
      return state.endpoint
        .create(item)
        .then((response) => {
          commit("create", response.data);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("snackbar/errorSnackbar", null, { root: true });
          return Promise.reject(error);
        });
    },
    destroy({ state, commit }, id) {
      let index = state.objects.findIndex((e) => e.id === id);
      return state.endpoint
        .destroy(id)
        .then((response) => {
          commit("destroy", index);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("snackbar/errorSnackbar", null, { root: true });
          return Promise.reject(error);
        });
    },
    update({ state, commit }, item) {
      let index = state.objects.findIndex((e) => e.id === item.id);
      return state.endpoint
        .update(item.id, item)
        .then((response) => {
          commit("update", { index, item });
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("snackbar/errorSnackbar", null, { root: true });
          return Promise.reject(error);
        });
    },
  },
};

export function createCRUDstore(
  endpoint,
  state = {},
  getters = {},
  mutations = {},
  actions = {}
) {
  return {
    namespaced: true,
    state: () => ({
      endpoint: endpoint,
      objects: [],
      ...state,
    }),
    getters: { ...base.getters, ...getters },
    mutations: { ...base.mutations, ...mutations },
    actions: { ...base.actions, ...actions },
  };
}

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
import '@/assets/style.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#233C73',
                secondary: '#F3F5F9',
                accent: '#FFF0BB',
                error: '#F35E5B',
                info: '#315EC2',
                success: '#67DE83',
                warning: '#FF832D',
            }
        }
    }
});

// initial state
import {Departments} from "@/services/api";

const state = () => ({
    all: [],
    loading: false
});

// getters
const getters = {
    departments: state => {
        return state.all;
    },
    loadingServices: state => state.loading
};

// actions
const actions = {
    getServices ({ commit }, params={}) {
        commit('setLoading', true);
        Departments.list({...{ordering: 'name', is_visible: true}, ...params}).then(response => {
            commit('setServices', response.data.results);
        }).finally(() => {
            commit('setLoading', false);
        });
    }
};

// mutations
const mutations = {
    setServices (state, services) {
        state.all = services;
    },

    setLoading (state, loading) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

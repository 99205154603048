import { SDEEvent } from "@/services/api";
import { createCRUDstore } from "@/store/crud";

const state = {};
const getters = {
  events: (state) => state.objects.filter((obj) => obj.type === "event"),
};
const mutations = {};
const actions = {};

export default createCRUDstore(SDEEvent, state, getters, mutations, actions);

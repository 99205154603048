// initial state

const state = () => ({
    logoShifted: 'left', // Par défaut, le logo est positionné à gauche
});

// getters
const getters = {
    logoShifted: state => state.logoShifted,
    };

// actions
const actions = {
    updateLogoShifted({ commit }, shifted) {
        commit('setLogoShifted', shifted);
    }
};

// mutations
const mutations = {
    setLogoShifted(state, shifted) {
        state.logoShifted = shifted;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

import moment from "moment/moment";
import { DocumentsStats, WorkspaceFormQuestionVisibility } from "@/services/api";
import {WorkspaceFormVisibility} from "@/enums/workspaces";

const state = () => ({
  WorkspaceFormVisibility,
  questions: [],
  workspace: {},
  formAction: null,
  formValidation: false,
  loadingSave: false,
  loadingSubmit: false,
  editMode: false,
  tab: 0,
  loadingSources: false,
  charts: [],
  visibility: {},
  visibilityObjectId: null,
});

// getters
const getters = {
  getWorkspace: (state) => state.workspace,
  getFormAction: (state) => state.formAction,
  getLoadingSave: (state) => state.loadingSave,
  getFormValidation: (state) => state.formValidation,
  getVisibilityObjectId: (state) => state.visibilityObjectId,
  getEditMode: (state) => state.editMode,
  getTab: (state) => state.tab,
  getLoadingSources: (state) => state.loadingSources,
  getChartByName: (state) => (name) => {
    return state.charts.find((obj) => obj.name === name);
  },
  getWorkspaceFormVisibility: (state) => state.visibility,
};

// mutations
const mutations = {
  setWorkspace(state, workspace) {
    state.workspace = workspace;
  },

  setFormAction(state, action) {
    state.formAction = action;
  },

  setWorkspaceFormVisibility(state, visibility) {
    state.visibility = visibility;
  },

  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },

  setFormValidation(state, formValidation) {
    state.formValidation = formValidation;
  },

  resetFormActions(state) {
    state.formValidation = false;
    state.formAction = null;
  },

  setEditMode(state, value) {
    state.editMode = value;
  },

  setTab(state, value) {
    state.tab = value;
  },

  setVisibilityObjectId(state, id) {
    state.visibilityObjectId = id;
  },

  setLoadingSources(state, loading) {
    state.loadingSave = loading;
  },

  create(state, chart) {
    state.charts.push(chart);
  },

  update(state, data) {
    state.charts.find((c) => c.name === data.name).data = data.data;
  },

  updateCategoryVisibilityState(state) {
    // Check if at least one question in the category is true to change visibility[category].all_visible switch
    Object.keys(state.visibility).forEach(category => {
      const isVisibleCategory = Object.values(state.visibility[category].questions).some(val => val);
      state.visibility[category].all_visible = isVisibleCategory;
    });
  },

  updateVisibilityState(state, { key, value }) {
    // Update visibility bind after GET action 
    for (const category in state.visibility) {
      if (Object.prototype.hasOwnProperty.call(state.visibility[category].questions, key)) {
        state.visibility[category].questions[key] = value;
        break;
      }
    }
  },
};

// actions
const actions = {
  setOriginByYearData({ commit }, data) {
    let params = {};
    params.end_year = moment().format("YYYY");
    params.start_year = moment().subtract(20, "years").format("YYYY");
    params.cohort_id = data.cohortId;
    params.status = data.selectedStatus;
    if (data.origins) {
      params.origins = data.origins;
    }
    return DocumentsStats.getNbDocsByOriginByYear(params).then((response) => {
      commit(data.update ? "update" : "create", {
        name: "document-nbByOriginByYear",
        data: response.data.length > 0 ? response.data : null,
      });
    });
  },

  getVisibility({ commit, state }) {
    return WorkspaceFormQuestionVisibility.list(state.workspace.id).then(response => {
      const data = response.data.results[0];
      commit('setVisibilityObjectId', data.id);
      for (const key in data) {
        commit('updateVisibilityState', { key, value: data[key] });
      }
      commit('updateCategoryVisibilityState');
    });
  },

  initializeVisibility({ commit }) {
    // initialize visibility to bind object with switches
    let visibility = {};
    WorkspaceFormVisibility.forEach(item => {
      if (!visibility[item.category]) {
        visibility[item.category] = {
          all_visible: false,
          questions: {}
        };
      }
      visibility[item.category].questions[item.value] = false;
    });
    commit('setWorkspaceFormVisibility', visibility);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="d-inline-flex text-body-2 w-100 p-3">
    <div class="bg-warning-surface b-warning-border warning-pressed rounded-lg p-3 font-demi-bold border w-100 d-flex justify-content-between">
      <small
        class="flex-1 text-center"
        v-html="alertMessage()"
      />
      <v-btn class="no-bg position-relative right-0" icon x-small @click="closeAlert">
        <icon name="close" color="warning-pressed" outline />
      </v-btn>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'AlertMessage',
  props: {},

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      customSettings: "customApp/getCustomSettings",
    })
  },

  methods: {
    alertMessage() {
      const linkRegex = /(?:https?:\/\/|www\.)\S+(?=\s|$)/g;
      return this.customSettings.alert.replace(linkRegex, url => {
        return `<a target="_blank" href="${url}" class="warning-pressed"><strong>${url}</strong></a>`;
      });
    },

    closeAlert() {
      this.$store.commit('customApp/setDisplayAlertMessage', false);
      const alertBanner = document.getElementById("banner-alert-message");
      if (alertBanner) {
        alertBanner.remove("sde-banner-alert");
      }
    }
  }
};
</script>
import { TextValueEnum } from "@/utils/enums";
import { MODE } from "@/config";

export const Perm = {
    IsAdminCare: new TextValueEnum('admin_care', 'Administrateur'),
    IsAdminEDS: new TextValueEnum('admin_eds', 'Administrateur'),
    IsAdminGDDI: new TextValueEnum('admin_gddi', 'GDDI'),
    IsAdminSupport: new TextValueEnum('admin_support', 'Support'),
    DataNominative: new TextValueEnum('data_nominative', 'Nominatives'),
    DataPseudonymized: new TextValueEnum('data_pseudonymized', 'Pseudonymisées'),
    DataAggregated: new TextValueEnum('data_aggregated', 'Agrégées'),
    AppSearch: new TextValueEnum('app_search', 'Moteur de recherche'),
    AppCohort: new TextValueEnum('app_cohort', 'Cohortes'),
    AppCohortExport: new TextValueEnum('app_cohort_export', "Module d'export"),
    AppWorkspace: new TextValueEnum('app_workspace', 'Projets'),
    AppPatient: new TextValueEnum('app_patient', 'Dossier Patient'),
    AppPatientSearch: new TextValueEnum('app_patient_search', 'Accès rapide patient'),
    AllAccess: new TextValueEnum('all_access', 'Toutes les données'),
    RestrictedServices: new TextValueEnum('restricted_services', 'Données du service'),
    RestrictedUnits: new TextValueEnum('restricted_units', 'Données de l\'UF'),
    RestrictedInstances: new TextValueEnum('restricted_instances', 'Données du pôle'),
    AllSources: new TextValueEnum('all_sources', 'Toutes'),
    NoTimeRestriction: new TextValueEnum(true, 'Aucune restriction'),
    TimeRestriction: new TextValueEnum(false, 'Restreindre à la date de début de l’utilisateur'),
    AppSDE: new TextValueEnum('app_sde', 'Extraction')
};

export const IsAdminPerm =
  process.env.VUE_APP_MODE === MODE.CARE ? Perm.IsAdminCare : Perm.IsAdminEDS;

<template>
  <div>
    <p class="text-body-1 font-demi-bold mb-0">
      {{ details.text }}
    </p>
    <div class="mt-2">
      <span class="basics-70 font-medium text-body-2">
        {{ details.subtitle }}
      </span>
    </div>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";

export default {
  name: 'BreadCrumbsDetailsDialog',
  mixins: [i18n],

  props: {
    details: {
      type: Object,
      required: true
    },
  },
};
</script>
import authHeader from "@/services/auth/auth.header";
import {authRequest} from "@/services/service";

export class CohortsStatsApi {
    constructor() {
        this.base_url = `/kpis/cohorts/`;
    }
    async getTopUsers() {
        const url = this.base_url + 'top_users/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getLastMonth() {
        const url = this.base_url + 'last_month/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getNewCohortsByMonth() {
        const url = this.base_url + 'monthly/new_cohorts/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getIncludedPatientsByMonth() {
        const url = this.base_url + 'monthly/included_patients/';
        return await authRequest.get(url, {headers: authHeader()});
    }
}

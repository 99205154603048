import Vue from "vue";
import {CustomizationsSettings, Paragraphs, WorkspacesQuestions} from "@/services/api";
import "@/mixins/randomID";

const state = () => ({
    questions: [],
    paragraphs: [],
    customSettings: {},
    displayAlertMessage: true,
    AlertMessagePosition: 'fullScreen',
});

// getters
const getters = {
    getQuestions: state => state.questions,
    getParagraphs: state => state.paragraphs,
    getCustomSettings: state => state.customSettings,
    getDisplayAlertMessage: state => state.displayAlertMessage,
    getAlertMessagePosition: state => state.AlertMessagePosition
};

// mutations
const mutations = {
    setItems(state, obj) {
        obj.value.forEach((item, index) => {
           item.order = index + 1;
        });
        state[obj.type] = obj.value;
    },

    setItem(state, item) {
        Vue.set(state[item.page], item.index, item.value);
    },

    newQuestions(state) {
        state.questions.push({
            new: true,
            label: '',
            is_required: true,
            value: [],
            type: null,
            newKey: this.randomID
        });
    },

    newParagraphs(state) {
        state.paragraphs.push({
            new: true,
            label: '',
            value: '',
            newKey: this.randomID
        });
    },

    updateItem(state, item) {
        Object.keys(item.obj).forEach(key => {
            state[item.page][item.index][key] = item.obj[key];
        });
    },

    removeItem(state, item) {
        state[item.page].splice(item.index, 1);
    },

    duplicateItem(state, item) {
        state[item.page].splice(item.index + 1, 0, item.obj);
    },

    removeUnsaved(state, page) {
        state[page] = state[page].filter(item => !item.new);
    },

    setAnonymousEnable(state, value) {
        state.customSettings.anonymous_enabled = value;
    },

    setCustomSettings(state, value) {
        state.customSettings = value;
    },

    setDisplayAlertMessage(state, bool) {
        state.displayAlertMessage = bool;
    },

    setAlertMessagePosition(state, bool) {
        state.AlertMessagePosition = bool;
    },
};

const actions = {
    fetchQuestions({commit}) {
        return WorkspacesQuestions.list().then(response => {
            commit('setItems', {value: response.data.results, type: 'questions'});
        });
    },

    fetchParagraphs({commit}) {
        return Paragraphs.list().then(response => {
            response.data.results.forEach(item => {
                item.label = item.title;
                item.value = item.content;
                delete item.title; delete item.content;
            });
            commit('setItems', {value: response.data.results, type: 'paragraphs'});
        });
    },
    
    switchAnonymousActive({ commit }, param) {
        return CustomizationsSettings.update('settings', {anonymous_enabled : param.value}).then(() => {
            commit('setAnonymousEnable', param.value);
            commit('snackbar/successSnackbar', {message: param.message}, {root: true});
        });
    },

    getCustomSettings ({ commit }) {
        return CustomizationsSettings.getSettings().then(response => {
            commit('setCustomSettings', response.data);
            commit('setDisplayAlertMessage', response.data.alert_enabled);
        });
    },

    updateCustomSettings ({ commit }, value) {
        return CustomizationsSettings.partial_update('settings', value.settings).then(response => {
            commit('setCustomSettings', response.data);
            commit('snackbar/successSnackbar', {message: value.message}, {root: true});
            if (value.settings && !value.settings.alert_enabled) {
                commit('setDisplayAlertMessage', false);
            } else {
                commit('setDisplayAlertMessage', true);
            }
        });
    },

    updateLogo ({ commit }, value) {
        let formData = new FormData();
        formData.append('file', value.target.files[0]);
        return CustomizationsSettings.generate('settings/logo', formData).then(response => {
            commit('setCustomSettings', response.data);
            commit('snackbar/successSnackbar', {title: "Logo : ", message:"mis à jour avec succès."}, {root: true});
        });
    },

    updateCGU ({ commit }, value) {
        let formData = new FormData();
        formData.append('file', value.target.files[0]);
        return CustomizationsSettings.generate('settings/cgu', formData).then(response => {
            commit('setCustomSettings', response.data);
            commit('snackbar/successSnackbar', {title: "CGUs : ", message:"mis à jour avec succès."}, {root: true});
        });
    },

    updateAlertMessagePosition({ commit }, shifted) {
        commit('setAlertMessagePosition', shifted);
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

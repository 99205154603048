<template>
  <div>
    <div v-click-outside="closeNotifications"
         class="notifications overflow-y-auto overflow-x-hidden br-8 mt-1 bg-basics-10 position-fixed mr-6 border b-basics-50"
    >
      <div class="text-subtitle-1 font-demi-bold py-4 px-6 border-bottom b-basics-50">
        {{ t("Notifications") }}
      </div>

      <div v-if="notifications.length > 0">
        <div v-for="(notification, i) in notifications" :key="notification.id">
          <div :class="{'bg-warning-surface' : !notification.read_date}">
            <notification-card
              :notification="notification"
              :first="i === 0"
              @dismiss="$emit('dismiss', $event)"
            />
          </div>
        </div>
      </div>

      <div v-else class="py-4 px-6">
        <span class="text-body-2 basics-80 ">
          {{ t('Aucune notification') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import NotificationCard from '@/components/notifications/NotificationCard';

export default {
  name: "Notifications",
  components: {NotificationCard},

  computed: {
    ...mapGetters({
      notifications: 'notifications/getNotifications'
    }),
  },

  methods: {
    closeNotifications() {
      this.$store.commit('notifications/setDisplayNotifications', false);
    }
  }
};
</script>

<style>
.notifications {
  right: 0;
  width: 420px;
  max-height: 600px;
  z-index: 10000;
  box-shadow: var(--Z4);
}
</style>

import {Stay, Sources} from "@/services/api";
import {DocumentTypes} from "@/enums/document_types";

const state = () => ({
    patientId: 0,
    tabIndex: 0,
    sdeTabIndex: 0,
    tabs: [],
    documentsSearch: null,
    selectedDocument: null,
    stays: []
});

// getters
const getters = {
    getPatientId: state => state.patientId,
    getPatientTabIndex: state => state.tabIndex,
    getSdePatientTabIndex: state => state.sdeTabIndex,
    getPatientTabs: state => state.tabs,
    getStays: state => state.stays,

    /*For phenotype tab*/
    getDocumentsSearch: state => state.documentsSearch,
    getSelectedDocument: state => state.selectedDocument
};

// mutations
const mutations = {
    setPatientId(state, patientId) {
        state.patientId = patientId;
    },

    setPatientTabIndex(state, index) {
        state.tabIndex = index;
    },

    setSdePatientTabIndex(state, index) {
        state.sdeTabIndex = index;
    },

    setTabs(state, tabs) {
        state.tabs = tabs;
    },

    setPatientDocumentsSearch(state, search) {
        state.documentsSearch = search;
    },

    setPatientSelectedDocument(state, document) {
        state.selectedDocument = document;
    },

    setPatientStays(state, stays) {
        state.stays = stays;
    }
};

export const actions = {
    setTabIndex({ commit }, index) {
        commit("setPatientTabIndex", index);
    },

    setSdeTabIndex({ commit }, index) {
        commit('setSdePatientTabIndex', index);
    },

    setDocumentSearch({ commit }, search) {
        commit("setPatientDocumentsSearch", search);
    },

    setSelectedDocument({ commit }, document) {
        commit("setPatientSelectedDocument", document);
    },

    getStays({commit}, id) {
        Stay.list(id, {limit: 100}).then(response => {
            const encounters = [];
            response.data.results.forEach(stay => {
                if (!encounters.some(encounter => encounter.encounter === stay.encounter)) {
                    encounters.push(stay);
                }
            });
            commit("setPatientStays", encounters);
        });
    },

    getTabs({commit}, {tabs, baseTabs}) {
        Sources.list({type__in: Object.values(DocumentTypes).map(item => item.value).join(",")}).then(response => {
            let sources = response.data.results;
            const filteredTabs = tabs.filter(item => {
                return (
                    baseTabs.includes(item.tab) ||
                    sources.some(
                        source => source.type.name === item.default_param
                    )
                );
            });
            commit('setTabs', filteredTabs);
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

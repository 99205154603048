import authHeader from "@/services/auth/auth.header";
import {authRequest} from "@/services/service";

export class UsersStatsApi {
    constructor() {
        this.base_url = `/kpis/users/`;
    }
    async getTopUsers() {
        const url = this.base_url + 'top_users/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getLastMonth() {
        const url = this.base_url + 'last_month/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getUsersByMonth() {
        const url = this.base_url + 'monthly/users/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getConnectionsByMonth() {
        const url = this.base_url + 'monthly/connections/';
        return await authRequest.get(url, {headers: authHeader()});
    }
}

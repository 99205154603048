/* -*- coding: UTF-8 -*- */

/**
 * Internationalization mixins.
 * @module
 *
 * @return  {string}
 */
export default {
  methods: {
    t: s => s, // TODO: translation
  },
};

import authHeader from "@/services/auth/auth.header";
import {authRequest} from "@/services/service";

export class DocumentStatsApi {
    constructor() {
        this.base_url = `/warehouse/documents/`;
    }
    async getNbDocsByOrigin(params) {
        const url = this.base_url + 'origins/';
        return await authRequest.get(url, {headers: authHeader(), params: params});
    }

    async getNbDocsByOriginByMonth() {
        const url = this.base_url + 'monthly/origins/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getNbDocsByOriginByYear(params) {
        const url = this.base_url + 'yearly/origins/';
        return await authRequest.get(url, {headers: authHeader(), params: params});
    }

    async getNbDocsUpdatesByMonth() {
        const url = this.base_url + 'monthly/updates/';
        return await authRequest.get(url, {headers: authHeader()});
    }

    async getNbDocsByMonth() {
        const url = this.base_url + 'monthly/docs/';
        return await authRequest.get(url, {headers: authHeader()});
    }
}

<template>
  <div
    class="help-btn-footer"
  >
    <v-menu offset-y top left class="mb-4">
      <template #activator="{ on }">
        <v-btn icon class="my-auto py-2 bg-basics-10 border" v-on="on">
          <icon name="info" outline color="basics-90" size="20" />
        </v-btn>
      </template>

      <v-list dense class="p-2">
        <v-list-item target="_self" href="mailto:support@codoc.co">
          <div class="d-flex flex-column">
            <v-list-item-title class="basics-90 text-body-2 my-auto align-self-auto">
              <icon
                class="my-auto mr-1"
                name="mail-closed"
                color="basics-80"
                size="16"
              />
              {{ t('Contact') }}
            </v-list-item-title>
            <v-list-item-subtitle class="basics-70">
              {{ t("support@codoc.co") }}
            </v-list-item-subtitle>
          </div>
        </v-list-item>
        <v-list-item href="https://codoc.notion.site/Cr-er-un-ticket-de-support-80887fec2f1844cfaa68bc5c7eac6f5a"
                     target="_blank"
        >
          <v-list-item-title
            class="basics-90 text-body-2 my-auto"
          >
            <icon
              class="my-auto mr-1"
              name="megaphone"
              color="basics-80"
              size="16"
            />
            {{ t('Signaler un problème') }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="dialog = true">
          <v-list-item-title
            class="basics-90 text-body-2 my-auto"
          >
            <icon
              class="my-auto mr-1"
              name="pencil-edit-line"
              color="basics-80"
              size="16"
            />
            {{ t('Citer Codoc') }}
          </v-list-item-title>
        </v-list-item>

        <v-divider class="my-1" />

        <v-list-item href="https://documentation.codoc.co/" target="_blank">
          <div class="d-flex flex-column">
            <v-list-item-title
              class="basics-90 text-body-2 my-auto"
            >
              <icon
                class="my-auto mr-1"
                name="folder"
                color="basics-80"
                size="16"
              />
              {{ t('Documentation') }}
            </v-list-item-title>
            <v-list-item-subtitle class="basics-70">
              {{ t("Mdp: codoc") }}
            </v-list-item-subtitle>
          </div>
        </v-list-item>

        <v-divider class="mx-4 my-1" />
        <v-list-item>
          <v-list-item-title
            class="d-flex align-items-center basics-70 text-body-2"
          >
            <span>
              app:{{ version }} & api:{{ versionAPI }}
            </span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <dialog-box :dialog="dialog" width="700px" icon="newspaper" is-form-valid type="info" @submit="dialog = false" @close="dialog = false">
      <template #default>
        <mention-modal />
      </template>
    </dialog-box>
  </div>
</template>

<script>
import MentionModal from '@/components/footer/MentionModal.vue';
import {mapGetters} from 'vuex';

export default {
  name: 'HelpButtonFooter',
  components: { MentionModal },

  data () {
    return {
      dialog: false
    };
  },

  computed: {
    ...mapGetters({
      versionAPI: 'settings/versionAPI',
      customSettings: 'customApp/getCustomSettings',
    }),

    version () {
      return process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : 'dev';
    }
  },

  created() {
    this.$store.dispatch('settings/getVersion').catch(error => {
      this.$store.commit('snackbar/apiErrorSnackbar', error);
    });
  }
};
</script>

<style scoped>
.help-btn-footer {
  transition: opacity 700ms ease 0s, color 700ms ease 0s, transform 200ms ease 0s;
  cursor: pointer;
  opacity: 1;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  bottom: calc(16px + env(safe-area-inset-bottom));
  right: 16px;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  z-index: 1111 !important;
  transform: translateX(0px) translateZ(0px);
  fill: var(--basics-10);
  stroke-width: 1px;
  stroke: var(--basics-50);
  box-shadow: 0px 1px 3px 0px rgba(19, 34, 57, 0.08) inset;
  filter: drop-shadow(0px 1px 2px rgba(19, 34, 57, 0.12));
}

.login-position {
  right: calc(50% + 16px) !important;
}

.v-list a {
  text-decoration: none !important;
}

.v-menu__content {
  margin-top: -6px !important;
}

</style>

import axios from 'axios';
import TokenService from "@/services/tokens";
import {verifyHeader} from "@/services/auth/auth.header";

const API_URL = process.env.VUE_APP_DWH_AUTH_URL;


class AuthEDS {
    identification(username, password) {
        return axios
            .post(API_URL + '/identification/', {username: username, password: password}).then(response => {
                TokenService.setIdToken(response.data.identity);
                return response;
            });
    }

    authentication(app_id) {
        return axios.post(API_URL + '/login/', {id: app_id},
            {headers: verifyHeader()}).then(
            response => {
                return response;
            });
    }
}

export default new AuthEDS();

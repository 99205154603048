import {FeedbackNPS} from "@/services/api";
import moment from "moment/moment";

const state = () => ({
    nps: null
});

// getters
const getters = {
    getNps: state => state.nps,
};

// mutations
const mutations = {
    setNps(state, nps) {
        state.nps = nps;
    }
};

// actions
const actions = {
    setPastNps({commit}, userId) {
        return FeedbackNPS.list().then(response => {
            const pastFeedbacks = response.data.results.filter(data => data.sent_by.id === userId);
            if (pastFeedbacks.length > 0) {
                const currentDate = moment();
                pastFeedbacks.forEach(data => {
                    if (currentDate.diff(data.send_date, 'weeks') < 2) {
                        commit('setNps', data.nps_value);
                    }
                });
            }
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
